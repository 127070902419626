export const GEN_TOKEN_START = 'GEN_TOKEN_START';
export const GEN_TOKEN_SUCCESS = 'GEN_TOKEN_SUCCESS';
export const GEN_TOKEN_FAIL = 'GEN_TOKEN_FAIL';

export const FETCH_TOKEN_START = 'FETCH_TOKEN_START';
export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS';
export const FETCH_TOKEN_FAIL = 'FETCH_TOKEN_FAIL';

export const CREATE_SUBSCRIPTION_START = 'CREATE_SUBSCRIPTION_START';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAIL = 'CREATE_SUBSCRIPTION_FAIL';

export const PAUSE_SUBSCRIPTION_START = 'PAUSE_SUBSCRIPTION_START';
export const PAUSE_SUBSCRIPTION_SUCCESS = 'PAUSE_SUBSCRIPTION_SUCCESS';
export const PAUSE_SUBSCRIPTION_FAIL = 'PAUSE_SUBSCRIPTION_FAIL';

export const CHANGE_SUBSCRIPTION_PLAN_START = 'CHANGE_SUBSCRIPTION_PLAN_START';
export const CHANGE_SUBSCRIPTION_PLAN_SUCCESS =
  'CHANGE_SUBSCRIPTION_PLAN_SUCCESS';
export const CHANGE_SUBSCRIPTION_PLAN_FAIL = 'CHANGE_SUBSCRIPTION_PLAN_FAIL';
