/* eslint-disable */
import { connect } from 'react-redux';
import { signUpStart } from 'src/store/auth/auth.actions';

const mapStateToProps = (state) => ({
  error: state.auth.errors
});

const mapDispatchToProps = (dispatch) => ({
  onSignUpStart: (values, history) => dispatch(signUpStart(values, history))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
