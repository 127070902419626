import * as actionTypes from './checkout.types';

const initialState = {
  plan: ''
};

const checkoutReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_PRICING_PLAN:
      return {
        ...state,
        plan: payload
      };
    case actionTypes.CLEAR_PRICING_PLAN:
      return {
        ...state,
        plan: ''
      };

    default:
      return state;
  }
};

export default checkoutReducer;
