/* eslint-disable */

import axios from 'axios';

import { END_POINT } from './constants';

export const signIn = async (input) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        mutation signIn($input: LoginCredentialsInput!){
            signIn(input:$input){
            accessToken
            }
        }
            `,
      variables: {
        input
      }
    }
  });

  return result;
};