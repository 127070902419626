import { connect } from 'react-redux';

import { signOutStart } from 'src/store/auth/auth.actions';

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  onSignOutStart: () => dispatch(signOutStart(true))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
