/* eslint-disable */
import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import _ from 'lodash';

import { getAuthenticatedUser } from 'src/api/getAuthenticatedUser';
import { signUp } from 'src/api/signUp';
import { signIn } from 'src/api/signIn';
import { fetchApiCountByDateRange } from 'src/api/fetchApiCountByDateRange';

import * as actionTypes from './auth.types';
import * as actions from './auth.actions';
import * as alertActions from '../alert/alert.actions';

import setAuthToken from 'src/utils/setAuthToken';
import { updateUser } from 'src/api/updateUser';
import { fetchApiTokenAsync } from '../subscription/subscription.sagas';
import { fetchAPIUsage } from 'src/api/fetchAPiUsage';
import { resetPasswordRequest } from 'src/api/resetPasswordRequest';
import { validateResetToken } from 'src/api/validateResetToken';
import { resetPassword } from 'src/api/resetPassword';
import { updatePassword } from 'src/api/updatePassword';
import { uploadProfilePic } from 'src/api/uploadProfilePic';
import data from 'src/pages/CustomerListView/data';

export function* loadUserAsync() {
  const token = localStorage.getItem('token');
  const expiryDate = localStorage.getItem('expiryDate');

  if (!token || !expiryDate) {
    yield put(actions.loadUserFail({ message: 'Failed to fetch user info' }));
    return;
  }

  if (new Date(expiryDate) <= new Date()) {
    yield put(actions.loadUserFail({ message: 'Failed to fetch user info' }));
    yield put(actions.signOutStart(false));
    return;
  }

  setAuthToken(token);

  try {
    const { data } = yield getAuthenticatedUser();
    console.log(data);

    if (!data.errors && !_.isEmpty(data.data.getAuthenticatedUser)) {
      // yield put(alertActions.openAlert('Successfully fetched user info' ,'success'));
      yield put(actions.loadUserSuccess(data.data.getAuthenticatedUser));
    } else {
      if (data.errors) {
        // yield put(alertActions.openAlert(data.errors[0].message ,'error'));
        yield put(actions.loadUserFail({ message: data.errors[0].message }));
      } else {
        // yield put(alertActions.openAlert('Failed to fetch user info' ,'error'));
        yield put(
          actions.loadUserFail({ message: 'Failed to fetch user info' })
        );
      }
    }
  } catch (err) {
    // yield put(alertActions.openAlert(err.message ,'error'));
    yield put(actions.loadUserFail({ message: err.message }));
  }
}

export function* onSigninAsync({ payload: { formData, history } }) {
  try {
    const { data } = yield call(signIn, formData);
    if (!data.errors && data.data.signIn.accessToken) {
      yield put(alertActions.openAlert('Sign In Successful', 'success'));
      yield put(actions.signInSuccess(data.data.signIn));
      yield put(actions.loadUserStart());
      yield history.push({ pathname: '/' });
    } else {
      if (data.errors) {
        yield put(alertActions.openAlert(data.errors[0].message, 'error'));
        yield put(actions.signInFail({ message: data.errors[0].message }));
      } else {
        yield put(alertActions.openAlert('Sign In Failed', 'error'));
        yield put(actions.signInFail({ message: 'sign In failed' }));
      }
    }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.signInFail({ message: err.message }));
  }
}

export function* onFetchTotalAPICountAsync() {
  try {
    const { data } = yield call(fetchAPIUsage);
    if (!data.errors) {
      yield put(actions.fetchTotalAPICountSuccess(data.data.APIUsage));
    } else {
      if (data.errors) {
        yield put(
          actions.fetchTotalAPICountFail({ message: data.errors[0].message })
        );
      } else {
        yield put(
          actions.fetchTotalAPICountFail({ message: data.errors[0].message })
        );
      }
    }
  } catch (err) {
    yield put(actions.fetchTotalAPICountFail({ message: err.message }));
  }
}

export function* onSignupAsync({ payload: { formData, history } }) {
  try {
    const { data } = yield call(signUp, formData);
    if (!data.errors && !_.isEmpty(data.data.signUp)) {
      yield put(actions.signUpSuccess());
      yield put(alertActions.openAlert('Sign Up Successful', 'success'));
      yield history.push({ pathname: '/login' });
    } else {
      if (data.errors) {
        yield put(alertActions.openAlert(data.errors[0].message, 'error'));
        yield put(actions.signUpFail({ message: data.errors[0].message }));
      } else {
        yield put(alertActions.openAlert('Sign Up Failed', 'error'));
        yield put(actions.signUpFail({ message: 'sign Up failed' }));
      }
    }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.signUpFail({ message: err.message }));
  }
}

export function* signOutAsync({ payload: { show } }) {
  yield put(actions.signOutSuccess());
  if (show) {
    yield put(alertActions.openAlert('Sign Out Successful', 'success'));
  }
}

export function* updateUserAsync({ payload: { formData } }) {
  try {
    const { data } = yield call(updateUser, formData);
    if (!data.errors && !_.isEmpty(data.data.updateUser)) {
      yield put(actions.updateUserSuccess(data.data.updateUser));
      yield put(alertActions.openAlert('Update User Successful', 'success'));
      yield put(actions.loadUserStart());
    } else {
      if (data.errors) {
        yield put(alertActions.openAlert(data.errors[0].message, 'error'));
        yield put(actions.updateUserFail({ message: data.errors[0].message }));
      } else {
        yield put(alertActions.openAlert('Update User Failed', 'error'));
        yield put(actions.updateUserFail({ message: 'Update User Failed' }));
      }
    }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.updateUserFail({ message: err.message }));
  }
}

export function* onFetchAPICountByDateRangeAsync({
  payload: { startDate, endDate }
}) {
  try {
    const { data } = yield call(fetchApiCountByDateRange, {
      startDate,
      endDate
    });
    if (!data.errors) {
      yield put(
        actions.fetchAPICountByDateRangeSuccess(data.data.apiCountByDateRange)
      );
    } else {
      yield put(actions.fetchAPICountByDateRangeFail({ message: data.errors }));
    }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.fetchAPICountByDateRangeFail({ message: err.message }));
  }
}

export function* onRequestPasswordResetAsync({ payload: { email } }) {
  console.log('onRequestPasswordResetAsync>>>', email);
  try {
    const { data } = yield call(resetPasswordRequest, email);
    if (!data.errors) {
      yield put(
        actions.requestPasswordResetSuccess(data.data.resetPasswordRequest)
      );
      yield put(alertActions.openAlert('Email sent successfully!!', 'success'));
    } else {
      yield put(actions.requestPasswordResetFail({ message: data.errors }));
    }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.requestPasswordResetFail({ message: err.message }));
  }
}

export function* onVerifyTokenAsync({ payload: { token } }) {
  console.log('onVerifyTokenAsync>>>', token);
  try {
    const { data } = yield call(validateResetToken, token);
    if (!data.errors) {
      yield put(actions.verifyTokenSuccess(data.data.validateResetToken));
    } else {
      yield put(actions.verifyTokenFail({ message: data.errors }));
    }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.verifyTokenFail({ message: err.message }));
  }
}

export function* onResetPasswordAsync({ payload: { passwordData, history } }) {
  console.log('onResetPasswordAsync>>>', passwordData);
  try {
    const { data } = yield call(resetPassword, passwordData);
    if (!data.errors) {
      yield put(actions.resetPasswordSuccess(data.data.resetPassword));
      yield put(
        alertActions.openAlert('Password changed successfully!!', 'success')
      );
      history.push('/');
    } else {
      yield put(actions.resetPasswordFail({ message: data.errors }));
    }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.resetPasswordFail({ message: err.message }));
  }
}

export function* updatePasswordAsync({ payload: { passwordData, history } }) {
  console.log('onResetPasswordAsync>>>', passwordData);
  try {
    const { data } = yield call(updatePassword, passwordData);
    if (!data.errors) {
      yield put(actions.resetPasswordSuccess(data.data.resetPassword));
      yield put(
        alertActions.openAlert('Password updated successfully!!', 'success')
      );
      history.push('/');
    } else {
      if (data.errors) {
        yield put(alertActions.openAlert(data.errors[0].message, 'error'));
        yield put(
          actions.resetPasswordFail({ message: data.errors[0].message })
        );
      } else {
        yield put(alertActions.openAlert(' password change failed', 'error'));
        yield put(
          actions.resetPasswordFail({ message: ' password change failed' })
        );
      }
    }
    // else {
    //   yield put(actions.resetPasswordFail({ message: data.errors }));
    // }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.resetPasswordFail({ message: err.message }));
  }
}

export function* uploadProfilePicAsync({ payload: { file, history } }) {
  console.log('onUploadProfilePicAsync>>>', file);
  try {
    const { data } = yield call(uploadProfilePic, file);
    if (data.success) {
      yield put(actions.uploadProfilePicSuccess());
      yield put(
        alertActions.openAlert(
          'Profile Picture is updated successfully!!',
          'success'
        )
      );
      yield put(actions.loadUserStart());
    }
  } catch (err) {
    console.log('error>>>', err);
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.uploadProfilePicFail({ message: err.message }));
  }
}

export function* watchLoadUser() {
  yield takeLatest(actionTypes.LOAD_USER_START, loadUserAsync);
}

export function* watchSignIn() {
  yield takeLatest(actionTypes.SIGNIN_START, onSigninAsync);
}

export function* watchSignUp() {
  yield takeLatest(actionTypes.SIGNUP_START, onSignupAsync);
}

export function* watchSignOut() {
  yield takeLatest(actionTypes.SIGNOUT_START, signOutAsync);
}

export function* watchUpdateUser() {
  yield takeLatest(actionTypes.UPDATE_USER_START, updateUserAsync);
}

export function* watchUploadProfilePic() {
  yield takeLatest(actionTypes.UPLOAD_PROFILE_PIC_START, uploadProfilePicAsync);
}

export function* watchFetchTotalAPICount() {
  yield takeLatest(
    actionTypes.FETCH_TOTAL_API_COUNT_START,
    onFetchTotalAPICountAsync
  );
}

export function* watchFetchApiCountByDateRange() {
  yield takeLatest(
    actionTypes.FETCH_API_COUNT_BY_DATE_RANGE_START,
    onFetchAPICountByDateRangeAsync
  );
}

export function* watchVerifyToken() {
  yield takeLatest(actionTypes.VERIFY_TOKEN_START, onVerifyTokenAsync);
}

export function* watchRequestPasswordReset() {
  yield takeLatest(
    actionTypes.REQUEST_PASSWORD_RESET_START,
    onRequestPasswordResetAsync
  );
}

export function* watchResetPasswordReset() {
  yield takeLatest(actionTypes.RESET_PASSWORD_START, onResetPasswordAsync);
}

export function* watchUpdatePassword() {
  yield takeLatest(actionTypes.UPDATE_PASSWORD_START, updatePasswordAsync);
}

export function* authSagas() {
  yield all([
    call(watchLoadUser),
    call(watchSignIn),
    call(watchSignOut),
    call(watchSignUp),
    call(watchUpdateUser),
    call(watchFetchTotalAPICount),
    call(watchFetchApiCountByDateRange),
    call(watchRequestPasswordReset),
    call(watchVerifyToken),
    call(watchResetPasswordReset),
    call(watchUpdatePassword),
    call(watchUploadProfilePic)
  ]);
}
