import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CodeCard from '../codeCard/codeCard';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  }
}));

const Podcast = () => {
  const classes = useStyles();

  function createData2(name, calories) {
    return { name, calories };
  }

  const rows2 = [createData2('id - Int!', 'Collection id of the podcast')];

  return (
    <div className={classes.root}>
      <Grid item container id="podcast">
        <Grid
          item
          container
          md={7}
          sm={12}
          xs={12}
          style={{ padding: '1rem' }}
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h3" className="doc-title">
              podcast
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" gutterBottom>
              Fetch detailed metadata and episodes for a specific podcast
            </Typography>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography
                variant="body1"
                className="operation-name"
                color="textSecondary"
              >
                Response
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" className="operation-name">
                Returns a{' '}
                <Link href="#podcastType" color="inherit">
                  [PodcastType]!{' '}
                </Link>
              </Typography>
            </Grid>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom color="textSecondary">
                Arguments
              </Typography>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          {/* <h2 className="operation-name">
            <code>podcast</code>
          </h2>

          <section className="swagger-operation-description operation-description">
            <Typography variant="body1" className="operation-name">
              Description
            </Typography>
            <p>Fetch detailed metadata and episodes for a specific podcast</p>
          </section> */}

          {/* <Typography variant="body1" className="operation-name">
            Response
          </Typography>
          <section className="swagger-responses">
            Returns a
            <Link to="/documentation#definition-PodcastType">
              <code>PodcastType!</code>
            </Link>
          </section>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Name </th>
                <th>Description</th>
              </tr>
              <tr>
                <td>
                  <code>id</code> -
                  <Link to="/documentation#definition-Int">
                    <code>Int!</code>
                  </Link>
                </td>
                <td> Collection id of the podcast </td>
              </tr>
            </tbody>
          </table> */}
        </Grid>
        <Grid
          item
          container
          md={5}
          sm={12}
          xs={12}
          style={{
            padding: '1rem'
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            <CodeCard header="End Point">
              ``` query podcast($id: Int!) &#123;/n podcast(id: $id) &#123;/n
              episodes (pagination: $pagination, sort: $sort)/n &#123;/n
              ...EpisodeFragment/n &#125;/n &#125;/n &#125;/n ```
            </CodeCard>
          </Grid>

          <Grid item xs={12}>
            <CodeCard header="Response">
              ``` &#123;/n&quot;data&quot;:&#123;/n &quot;podcast&quot;:/n
              &#123;/n &quot;episodes&quot;:[Episode]/n &#125;/n &#125;/n
              &#125;/n ```
            </CodeCard>
          </Grid>
          {/* <div className="doc-examples">
            <section>
              <h4>Example</h4>
              <h5>Query</h5>
              <pre>
                <code className="hljs language-gql">
                  <span className="hljs-symbol">
                    <span className="hljs-keyword">query</span> podcast
                    <span className="hljs-tag">
                      (<span className="hljs-code">$id</span>:
                      <span className="hljs-type"> Int!</span>)
                    </span>{' '}
                    <span className="hljs-tag">
                      {'{'}
                      {'\n'}
                      {'  '}
                      <span className="hljs-symbol">
                        podcast
                        <span className="hljs-tag">
                          (id: <span className="hljs-code">$id</span>)
                        </span>{' '}
                        <span className="hljs-tag">
                          {'{'}
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">artist_name</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">artist_view_url</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">artwork_url_30</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">artwork_url_60</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">artwork_url_100</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">artwork_url_512</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">artwork_url_600</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">collection_id</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">collection_name</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">
                            collection_view_url
                          </span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">country</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">description</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">episode_count</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">
                            episodes
                            <span className="hljs-tag">
                              (pagination:{' '}
                              <span className="hljs-code">$pagination</span>,
                              sort: <span className="hljs-code">$sort</span>)
                            </span>{' '}
                            <span className="hljs-tag">
                              {'{'}
                              {'\n'}
                              {'      '}
                              <span className="hljs-type">
                                ...EpisodeFragment
                              </span>
                              {'\n'}
                              {'    '}
                              {'}'}
                            </span>
                          </span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">feed_url</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">id</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">long_description</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">release_date</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">short_description</span>
                          {'\n'}
                          {'    '}
                          <span className="hljs-symbol">track_count</span>
                          {'\n'}
                          {'  '}
                          {'}'}
                        </span>
                      </span>
                      {'\n'}
                      {'}'}
                    </span>
                  </span>
                  {'\n'}
                </code>
              </pre>
              <h5>Variables</h5>
              <pre>
                <code className="hljs language-json">
                  {'{'}
                  <span className="hljs-attr">&quot;id&quot;</span>:{' '}
                  <span className="hljs-number">1000016089</span>
                  {'}'}
                  {'\n'}
                </code>
              </pre>
            </section>
          </div> */}
          {/* <div className="doc-row">
            <div className="doc-copy"> </div>
            <div className="doc-examples">
              <section>
                <h5>Response </h5>
                <pre>
                  <code className="hljs language-json">
                    {'{'}
                    {'\n'}
                    {'  '}
                    <span className="hljs-attr">&quot;data&quot;</span>: {'{'}
                    {'\n'}
                    {'    '}
                    <span className="hljs-attr">&quot;podcast&quot;</span>:{' '}
                    {'{'}
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;artist_name&quot;
                    </span>:{' '}
                    <span className="hljs-string">&quot;abc123&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;artist_view_url&quot;
                    </span>
                    : <span className="hljs-string">&quot;xyz789&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;artwork_url_30&quot;
                    </span>
                    : <span className="hljs-string">&quot;xyz789&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;artwork_url_60&quot;
                    </span>
                    : <span className="hljs-string">&quot;abc123&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;artwork_url_100&quot;
                    </span>
                    : <span className="hljs-string">&quot;abc123&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;artwork_url_512&quot;
                    </span>
                    : <span className="hljs-string">&quot;xyz789&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;artwork_url_600&quot;
                    </span>
                    : <span className="hljs-string">&quot;xyz789&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">&quot;collection_id&quot;</span>
                    : <span className="hljs-number">123</span>,{'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;collection_name&quot;
                    </span>
                    : <span className="hljs-string">&quot;abc123&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;collection_view_url&quot;
                    </span>
                    : <span className="hljs-string">&quot;xyz789&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">&quot;country&quot;</span>:{' '}
                    <span className="hljs-string">&quot;abc123&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;description&quot;
                    </span>:{' '}
                    <span className="hljs-string">&quot;xyz789&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">&quot;episode_count&quot;</span>
                    : <span className="hljs-number">987</span>,{'\n'}
                    {'      '}
                    <span className="hljs-attr">&quot;episodes&quot;</span>:
                    [Episode],
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;feed_url&quot;
                    </span>:{' '}
                    <span className="hljs-string">&quot;xyz789&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">&quot;id&quot;</span>:{' '}
                    <span className="hljs-number">987</span>,{'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;long_description&quot;
                    </span>
                    : <span className="hljs-string">&quot;xyz789&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;release_date&quot;
                    </span>:{' '}
                    <span className="hljs-string">&quot;xyz789&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;short_description&quot;
                    </span>
                    : <span className="hljs-string">&quot;abc123&quot;</span>,
                    {'\n'}
                    {'      '}
                    <span className="hljs-attr">
                      &quot;track_count&quot;
                    </span>: <span className="hljs-number">987</span>
                    {'\n'}
                    {'    '}
                    {'}'}
                    {'\n'}
                    {'  '}
                    {'}'}
                    {'\n'}
                    {'}'}
                    {'\n'}
                  </code>
                </pre>
              </section>
            </div>
          </div> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Podcast;
