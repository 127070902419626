/* eslint-disable object-curly-newline */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import PodcastmoreLogo from 'src/assets/img/podcastmoreLogo.png';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  title: {
    fontSize: 24
  }
}));

const TopBar = ({ className, onMobileNavOpen, onSignOutStart, ...rest }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <Link to="/" style={{ color: '#333333' }}>
          <img src={PodcastmoreLogo} alt="PodcastmoreLogo" />
        </Link>
        {/* <Typography
          variant="h6"
          underline="none"
          color="inherit"
          className={classes.title}
        >
          <Link to="/" style={{ color: 'inherit' }}>
            Podcast
          </Link>
        </Typography> */}
        <Box flexGrow={1} />
        <Hidden mdDown>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab className={classes.tab} label="Home" component={Link} to="/" />
          </Tabs>
          <IconButton color="inherit" onClick={onSignOutStart}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  onSignOutStart: PropTypes.func
};

export default TopBar;
