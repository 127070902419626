/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import axios from 'axios';
import { END_POINT, MASTER_API_KEY } from './constants';

export const fetchPodcastByCollectionId = async ({
  query,
  limit,
  offset,
  sortArgs
}) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
      query podcastByCollectionId($id: Int!,$pagination: PaginationArgs!, $sort: EpisodeSortArgs){
        podcast(id: $id){
          id
          artist_name
          artwork_url_100
          artwork_url_600
          collection_id
          collection_name
          description
          release_date
          episode_count
      
          episodes(pagination:$pagination,sort: $sort){
            id  
            guid
            title
            description
            pub_date
            duration
            duration_string
            link
            collection_id
            
          }
        }
      }
      `,
      variables: {
        id: Number(query),
        pagination: {
          limit,
          offset
        },
        sort: sortArgs
      }
    }
  });

  return result;
};
