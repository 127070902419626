/*eslint-disable*/
import React from 'react';
import { connect } from 'react-redux';
import { fetchPodcastCategoriesStart } from 'src/store/search/search.actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const CategoriesSearchBox = (props) => {
  const {
    onChange,
    search: { podcastCategories, loading },
    onFetchPodcastCategoriesStart
  } = props;

  React.useEffect(() => {
    onFetchPodcastCategoriesStart();
  }, []);

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={podcastCategories}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option['genres']
      }
      loading={loading}
      filterSelectedOptions
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Select Categories"
          placeholder="Select"
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  search: state.search
});
const mapDispatchToProps = (dispatch) => ({
  onFetchPodcastCategoriesStart: () => dispatch(fetchPodcastCategoriesStart())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesSearchBox);
