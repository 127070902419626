/* eslint-disable  */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TabContent from './components/TabContent';
import DetailsCard from './components/DetailsCard';
import ShowMore from 'src/modules/components/ShowMore';
import EpisodeCard from 'src/modules/views/EpisodeDataCard';

import container from './PodcastDetails.container';
import Typography from 'src/modules/components/Typography';
import doubleDownArrow from 'src/assets/img/icons/doubleDownArrow.svg';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    }
  },
  root: {
    minHeight: 375
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  },
  breadcrumb: {
    marginTop: theme.spacing(5)
  }
}));

const mapSortEpisodeToValue = ['DESC', 'ASC'];

function PodcastDetails(props) {
  const classes = useStyles();
  const {
    podcast,
    isLoading,
    onSetPlayerCurrentTrack,
    onFetchDataStart,
    match,
    onSetQueryForEpisodes,
    onResetQueryForEpisodes,
    searchQuery,
    episodesQuery,
    onSetPlayerState,
    player: { currentTrack, miscState },
    ...rest
  } = props;

  console.log(rest);

  const [sortEpisode, setSortEpisode] = useState(0);
  const [query, setQuery] = useState('');
  const [more, setMore] = useState(5);

  console.log('params id >>>', match.params.id);

  useEffect(() => {
    onFetchDataStart(match.params.id, 1, 200, {
      pub_date: mapSortEpisodeToValue[sortEpisode]
    });
    return () => {
      onResetQueryForEpisodes();
    };
  }, [match.params.id, sortEpisode]);

  const seeMoreHandler = () => {
    setMore(more + 5);
  };

  const handleChange = (event) => {
    setSortEpisode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // onSetQueryForEpisodes(query)
  };

  const handleQueryChange = async (event) => {
    event.preventDefault();
    setQuery(event.target.value);
    onSetQueryForEpisodes(event.target.value);
  };

  let episodes = [];
  if (searchQuery) {
    episodes = episodesQuery;
  } else {
    episodes = podcast ? podcast.episodes : [];
  }

  let emptyContents;
  if (isLoading && episodes && episodes.length === 0) {
    emptyContents = (
      <Grid container justify="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  } else if (!isLoading && episodes && episodes.length === 0) {
    emptyContents = (
      <Grid container justify="center">
        <Grid item xs={5}>
          <Alert variant="outlined" severity="error">
            No Result Found
          </Alert>
        </Grid>
      </Grid>
    );
  }

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  const trendingToggle = () => {
    console.log('trendingToggle');
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const myRef = useRef(null);

  return (
    <>
      <div style={{ minHeight: '100vh' }}>
        {/* <TabContent /> */}
        <Container
          maxWidth="lg"
          component="main"
          className={classes.breadcrumb}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: '#5B5B5B' }} to="/">
              Home
            </Link>
            <Link style={{ color: '#000000' }} aria-current="page">
              Detail
            </Link>
          </Breadcrumbs>
        </Container>
        {isLoading || !podcast ? (
          <Grid container justify="center" alignContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <Container>
            <Grid container>
              <Grid item md={8} sm={12} xs={12}>
                <DetailsCard data={podcast} key={podcast.id} />
                <Typography
                  variant="h4"
                  align="center"
                  style={{ padding: '4rem 0 2rem', cursor: 'pointer' }}
                  onClick={trendingToggle}
                >
                  All Episodes
                </Typography>

                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.paper}
                >
                  <Grid>
                    <Select
                      labelId="sort episode"
                      id="sort-episode"
                      value={sortEpisode}
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>Published (Newest)</MenuItem>
                      <MenuItem value={1}>Published (Oldest)</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justify="center"
                  style={{ marginBottom: '3.5rem' }}
                >
                  <img
                    src={doubleDownArrow}
                    alt="doubleArrow"
                    style={{ cursor: 'pointer' }}
                    onClick={trendingToggle}
                  />
                </Grid>
                <div ref={myRef}>
                  {episodes && episodes.length
                    ? episodes
                        .slice(0, more)
                        .map((episode, i) => (
                          <EpisodeCard
                            data={episode}
                            key={episode.guid}
                            setPlayerEpisode={onSetPlayerCurrentTrack}
                            onSetPlayerState={onSetPlayerState}
                            episodePlaying={Boolean(
                              episode.id === currentTrack.id
                            )}
                            miscState={miscState}
                            {...rest}
                          />
                        ))
                    : emptyContents}
                </div>
                {!isLoading && episodes && episodes.length > more && (
                  <ShowMore nextList={seeMoreHandler} />
                )}
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </>
  );
}

PodcastDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.any
};

export default container(PodcastDetails);
