/* eslint-disable */

import axios from 'axios';
import { END_POINT } from './constants';

export const updateUser = async ({ id, input }) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        mutation updateUser($id: Int!, $input:UpdateUserInput!){
          updateUser(id:$id, input:$input){
            id
            firstName
            lastName
            email
            username
            tenant{
              key
            }
            subscriptions{
              id
            } 
          }
        }
        `,
      variables: {
        id: Number(id),
        input
      }
    }
  });
  return result;
};
