/* eslint-disable  */
/* globals Paddle */

import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import currencySymbol from 'src/utils/currencySymbol';

const paddleProducts = [
  {
    id: 756128,
    name: 'FREE'
  },
  {
    id: 756129,
    name: 'BASIC'
  },
  {
    id: 756130,
    name: 'PRO'
  }
];

const Paywall = ({ user, billing, handleClose }) => {
  const paddleEmbedRef = useRef();
  const [checkoutLoaded, setCheckoutLoaded] = useState(true);

  const [checkoutInfo, setCheckoutInfo] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [allPricingInfo, setAllPricingInfo] = useState(null);

  const getAllPricingInfo = async () => {
    // Fetch pricing info via our endpoiunt
    const response = await axios.get(
      `https://checkout.paddle.com/api/2.0/prices?product_ids=${paddleProducts[billing].id}`
    );
    if (response.data.response.products) {
      setAllPricingInfo(response.data.response.products);
    }
  };

  const handleCheckoutInfo = useCallback(
    (data) => {
      console.log({ data });
      setCheckoutLoaded(true);
      setSelectedProduct(data.product.id);
      setCheckoutInfo(parseCheckoutInfo(data));
    },
    [setCheckoutLoaded, setSelectedProduct, setCheckoutInfo]
  );

  useEffect(() => {
    // Paddle.Environment.set('sandbox');
    Paddle.Setup({
      vendor: 131839,
      debug: true,
      eventCallback: (d) => {
        if (d.event === 'Checkout.Loaded') {
          console.log('Checkout.Loaded');
          handleCheckoutInfo(d.eventData);
          setTimeout(() => {
            handleCheckoutInfo(d.eventData);
          }, 2000);
        }
      }
    });
    getAllPricingInfo();
  }, []);

  useEffect(() => {
    const productID = paddleProducts[billing].id;
    setupCheckout(productID);
  }, [billing]);

  function checkoutComplete(data) {
    console.log('checkoutComplete', data);
    handleClose();
  }

  function setupCheckout(id) {
    console.log({ id });
    if (typeof Paddle !== 'undefined') {
      Paddle.Checkout.open({
        method: 'inline',
        product: id,
        email: user.email,
        allowQuantity: 'false',
        disableLogout: false,
        frameTarget: 'checkout-container',
        frameInitialHeight: 375,
        frameStyle: 'width:100%; border: none; background: transparent;',
        successCallback: (data) => {
          checkoutComplete(data, checkoutInfo);
        }
        // closeCallback: "checkoutClosed"
      });
    }
  }

  function parseCheckoutInfo(data) {
    const checkoutData = data.checkout;

    // debugger;
    if (checkoutData.recurring_prices) {
      return {
        id: checkoutData.id,
        type: 'recurring',
        value: checkoutData.recurring_prices.customer.total,
        currency: currencySymbol(
          checkoutData.recurring_prices.customer.currency
        ),
        interval: checkoutData.recurring_prices.interval.type
      };
    }
    return {
      id: checkoutData.id,
      type: 'one_time',
      value: checkoutData.prices.customer.total,
      currency: currencySymbol(checkoutData.prices.customer.currency),
      interval: null
    };
  }

  return <div ref={paddleEmbedRef} className="checkout-container" />;
};

Paywall.propTypes = {
  user: PropTypes.object,
  billing: PropTypes.number,
  handleClose: PropTypes.func
};

export default Paywall;
