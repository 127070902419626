/* eslint-disable */
import { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  Paper,
  CircularProgress
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import container from './ResetPassword.container';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ForgotPassword = ({
  auth: { loading },
  onVerifyTokenStart,
  onResetPasswordStart
}) => {
  const classes = useStyles();

  const code = new URLSearchParams(location.search).get('token');

  console.log('value of code>>>', code);
  console.log('value of loading>>>', loading);

  const history = useHistory();

  useEffect(() => {
    if (code) {
      onVerifyTokenStart(code);
    }
  }, [code]);

  const handleSubmit = (values) => {
    console.log('values', values);

    onResetPasswordStart({ token: code, password: values.password }, history);
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Paper elevation={20}>
            <Formik
              initialValues={{
                password: '',
                password2: ''
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .matches(
                    /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                    'password too weak must contain atleast one capital letter and a number'
                  )
                  .required('password is required')
                  .min(6, 'must be minimum 6 character')
                  .max(32, 'must not exceed 32 character'),
                password2: Yup.string()
                  .matches(
                    /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                    'password too weak must contain atleast one capital letter and a number'
                  )
                  .required('re-enter password is required')
                  .oneOf([Yup.ref('password'), null], 'password must match')
              })}
              onSubmit={handleSubmit}
            >
              {({ values, touched, handleBlur, handleChange, errors }) => (
                <Form style={{ padding: '93px 91px' }}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                      align="center"
                      style={{ fontWeight: 400 }}
                    >
                      Reset Password
                    </Typography>
                  </Box>

                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Enter new password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(touched.password2 && errors.password2)}
                    fullWidth
                    helperText={touched.password2 && errors.password2}
                    label="Re-enter the password"
                    margin="normal"
                    name="password2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    variant="outlined"
                  />

                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={loading}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {loading ? <CircularProgress /> : 'Submit'}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Container>
      </Box>
    </Page>
  );
};

export default container(ForgotPassword);
