/* eslint-disable object-curly-newline */
import { takeLatest, call, put, all } from 'redux-saga/effects';

import { autocompletePodcast } from 'src/api/autocompletePodcast';
import { fetchPodcastCategories } from 'src/api/fetchPodcastCategories';
import transformAutoCompleteResults from 'src/utils/transformAutocompleteResults';

import * as actionTypes from './search.types';
import * as actions from './search.actions';

export function* fetchAutocompletePodcastAsync({ payload: { search = '' } }) {
  const params = { limit: 5, offset: 0, search };
  try {
    const { data } = yield call(autocompletePodcast, params);
    console.log({ data });
    if (!data.errors && data.data.searchPodcasts.results.length) {
      const transformedResults = yield call(
        transformAutoCompleteResults,
        data.data.searchPodcasts.results
      );
      yield put(
        actions.fetchAutocompleteSuggestionsPodcastSuccess(transformedResults)
      );
    } else {
      yield put(
        actions.fetchAutocompleteSuggestionsPodcastFail({
          message: 'Podcasts Not Found'
        })
      );
    }
  } catch (err) {
    yield put(
      actions.fetchAutocompleteSuggestionsPodcastFail({
        message: err.message
      })
    );
  }
}

export function* fetchPodcastCategoriesAsync() {
  try {
    const { data } = yield call(fetchPodcastCategories);
    if (!data.errors && data.data.allGenres.results.length) {
      yield put(
        actions.fetchPodcastCategoriesSuccess(data.data.allGenres.results)
      );
    } else {
      yield put(
        actions.fetchPodcastCategoriesFail({
          message: 'No categories available'
        })
      );
    }
  } catch (err) {
    yield put(
      actions.fetchPodcastCategoriesFail({
        message: err.message
      })
    );
  }
}

export function* watchfetchAutocompletePodcast() {
  yield takeLatest(
    actionTypes.GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_START,
    fetchAutocompletePodcastAsync
  );
}

export function* watchfetchPodcastCategories() {
  yield takeLatest(
    actionTypes.GET_PODCAST_CATEGORIES_START,
    fetchPodcastCategoriesAsync
  );
}

export function* searchSagas() {
  yield all([
    call(watchfetchAutocompletePodcast),
    call(watchfetchPodcastCategories)
  ]);
}
