/*eslint-disable*/
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';

import { ApiToken } from './components';
import container from './Settings.container';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = (props) => {
  const classes = useStyles();

  const {
    subscription,
    onFetchApiTokenStart,
    onGenTokenStart,
    hasSubscription,
    totalAPICount,
    onFetchTotalAPICountStart
  } = props;

  useEffect(() => {
    onFetchApiTokenStart();
  }, []);

  useEffect(() => {
    onFetchTotalAPICountStart();
  }, []);

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <ApiToken
          subscription={subscription}
          onGenTokenStart={onGenTokenStart}
          hasSubscription={hasSubscription}
        />

        {/* <Typography variant="h5" gutterBottom>
          API COUNT :{totalAPICount}
        </Typography> */}
        {/* <Box mt={3}>
          <Notifications />
        </Box>
        <Box mt={3}>
          <Password />
        </Box> */}
      </Container>
    </Page>
  );
};

SettingsView.propTypes = {
  subscription: PropTypes.object.isRequired,
  onFetchApiTokenStart: PropTypes.func,
  onGenTokenStart: PropTypes.func,
  hasSubscription: PropTypes.bool,
  totalAPICount: PropTypes.number,
  onFetchApiTokenStart: PropTypes.func
};

export default container(SettingsView);
