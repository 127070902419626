import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    margin: '2rem 0 '
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  }
}));

const OrderBy = () => {
  const classes = useStyles();

  function createData2(name, calories) {
    return { name, calories };
  }

  const rows2 = [
    createData2('ASC', 'Sort by ascending order'),
    createData2('DESC', 'Sort by descending Order')
  ];

  return (
    <div className={classes.root}>
      <Grid item container id="orderBy">
        <Grid item md={7} sm={12} xs={12} style={{ padding: '1rem' }}>
          <Grid item>
            <Typography variant="h3" className="panel-title" component="p">
              ORDERBY
            </Typography>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom color="textSecondary">
                Arguments
              </Typography>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Enum Value</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* <div
            id="definition-ORDERBY"
            className="definition panel"
            data-traverse-target="definition-ORDERBY"
          >
            <div className="definition-tags">
              <a className="label" href="#tag-types">
                Types
              </a>
            </div>
            <h2 className="panel-title">
              <p>
                <code>ORDERBY</code>
              </p>
            </h2>
            <div className="doc-row">
              <div className="doc-copy">
                <section className="json-schema-properties-blank">
                  <table>
                    <tbody>
                      <tr>
                        <th>Enum Value</th>
                        <th>Description</th>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <code>ASC</code>
                          </p>
                        </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <code>DESC</code>
                          </p>
                        </td>
                        <td> </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </div> */}
        </Grid>
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          style={{
            padding: '1rem'
          }}
        />
      </Grid>
    </div>
  );
};

export default OrderBy;
