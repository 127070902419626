/* eslint-disable */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import _ from 'lodash';

import {
  generateToken,
  fetchApiToken,
  createSubscription,
  pauseSubscription,
  changeSubscriptionPlan
} from 'src/api';
import * as alertActions from '../alert/alert.actions';
import * as actionTypes from './subscription.types';
import * as actions from './subscription.actions';
import { loadUserStart } from '../auth/auth.actions';

export function* generateTokenAsync() {
  try {
    const { data } = yield generateToken();
    console.log(data);

    if (!data.errors && !_.isEmpty(data.data.createTenantKey)) {
      // yield put(alertActions.openAlert('Successfully fetched user info' ,'success'));
      yield put(actions.genTokenSuccess(data.data.createTenantKey));
    } else {
      if (data.errors) {
        // yield put(alertActions.openAlert(data.errors[0].message ,'error'));
        yield put(actions.genTokenFail({ message: data.errors[0].message }));
      } else {
        // yield put(alertActions.openAlert('Failed to fetch user info' ,'error'));
        yield put(
          actions.genTokenFail({ message: 'Failed to generate token' })
        );
      }
    }
  } catch (err) {
    // yield put(alertActions.openAlert(err.message ,'error'));
    yield put(actions.genTokenFail({ message: err.message }));
  }
}

export function* createSubscriptionAsync({
  payload: { history, handleClose }
}) {
  try {
    const { data } = yield createSubscription();
    console.log(data);

    if (!data.errors && !_.isEmpty(data.data.createSubscription)) {
      // yield put(alertActions.openAlert('Successfully fetched user info' ,'success'));
      yield put(
        actions.createSubscriptionSuccess(data.data.createSubscription)
      );
      yield put(loadUserStart());
      yield handleClose();
      history.push('/app/settings');
    } else {
      if (data.errors) {
        // yield put(alertActions.openAlert(data.errors[0].message ,'error'));
        yield put(
          actions.createSubscriptionFail({ message: data.errors[0].message })
        );
        yield handleClose();
      } else {
        // yield put(alertActions.openAlert('Failed to fetch user info' ,'error'));
        yield put(
          actions.createSubscriptionFail({
            message: 'Failed to create subscription'
          })
        );
        yield handleClose();
      }
    }
  } catch (err) {
    // yield put(alertActions.openAlert(err.message ,'error'));
    yield put(actions.createSubscriptionFail({ message: err.message }));
    yield handleClose();
  }
}

export function* pauseSubscriptionAsync({
  payload: { subscriptionId, pause }
}) {
  try {
    const { data } = yield call(pauseSubscription, { subscriptionId, pause });
    console.log(data.data.pauseSubscription.success);
    if (!data.errors && data.data?.pauseSubscription?.success) {
      yield put(
        alertActions.openAlert(
          `Successfully ${pause ? 'paused' : 'restarted'} the subscription`
        )
      );
      yield put(actions.pauseSubscriptionSuccess());
      yield put(loadUserStart());
    } else {
      if (data.errors) {
        yield put(alertActions.openAlert(data.errors[0].message, 'error'));
        yield put(
          actions.pauseSubscriptionFail({ message: data.errors[0].message })
        );
      } else {
        yield put(
          alertActions.openAlert(
            `Failed to ${pause ? 'pause' : 'restart'} subscription`,
            'error'
          )
        );
        yield put(
          actions.pauseSubscriptionFail({
            message: `Failed to ${pause ? 'pause' : 'restart'} subscription`
          })
        );
      }
    }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.pauseSubscriptionFail({ message: err.message }));
  }
}

export function* changeSubscriptionPlanAsync({
  payload: { subscriptionId, subscriptionPlanId }
}) {
  try {
    const { data } = yield call(changeSubscriptionPlan, {
      subscriptionId,
      subscriptionPlanId
    });
    if (!data.errors && data.data?.updateSubscription?.success) {
      yield put(
        alertActions.openAlert(`Successfully changed the subscription plan`)
      );
      yield put(actions.changeSubscriptionPlanSuccess());
      yield put(loadUserStart());
    } else {
      if (data.errors) {
        yield put(alertActions.openAlert(data.errors[0].message, 'error'));
        yield put(
          actions.changeSubscriptionPlanFail({
            message: data.errors[0].message
          })
        );
      } else {
        yield put(
          alertActions.openAlert(`Failed to change subscription plan`, 'error')
        );
        yield put(
          actions.changeSubscriptionPlanFail({
            message: `Failed to change Subscription Plan`
          })
        );
      }
    }
  } catch (err) {
    yield put(alertActions.openAlert(err.message, 'error'));
    yield put(actions.changeSubscriptionPlanFail({ message: err.message }));
  }
}

export function* fetchApiTokenAsync() {
  try {
    const { data } = yield fetchApiToken();
    console.log(data);

    if (!data.errors && !_.isEmpty(data.data.getTenantKey)) {
      // yield put(alertActions.openAlert('Successfully fetched user info' ,'success'));
      yield put(actions.fetchApiTokenSuccess(data.data.getTenantKey));
    } else {
      if (data.errors) {
        // yield put(alertActions.openAlert(data.errors[0].message ,'error'));
        yield put(
          actions.fetchApiTokenFail({ message: data.errors[0].message })
        );
      } else {
        // yield put(alertActions.openAlert('Failed to fetch user info' ,'error'));
        yield put(
          actions.fetchApiTokenFail({ message: 'Failed to fetch api token' })
        );
      }
    }
  } catch (err) {
    // yield put(alertActions.openAlert(err.message ,'error'));
    yield put(actions.fetchApiTokenFail({ message: err.message }));
  }
}

export function* watchGenerateToken() {
  yield takeLatest(actionTypes.GEN_TOKEN_START, generateTokenAsync);
}

export function* watchFetchApiToken() {
  yield takeLatest(actionTypes.FETCH_TOKEN_START, fetchApiTokenAsync);
}

export function* watchCreateSubscription() {
  yield takeLatest(
    actionTypes.CREATE_SUBSCRIPTION_START,
    createSubscriptionAsync
  );
}

export function* watchPauseSubscription() {
  yield takeLatest(
    actionTypes.PAUSE_SUBSCRIPTION_START,
    pauseSubscriptionAsync
  );
}

export function* watchChangeSubscriptionPlan() {
  yield takeLatest(
    actionTypes.CHANGE_SUBSCRIPTION_PLAN_START,
    changeSubscriptionPlanAsync
  );
}

export function* subscriptionSagas() {
  yield all([
    call(watchGenerateToken),
    call(watchFetchApiToken),
    call(watchCreateSubscription),
    call(watchPauseSubscription),
    call(watchChangeSubscriptionPlan)
  ]);
}
