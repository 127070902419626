/* eslint-disable */
import axios from 'axios';
import _ from 'lodash';

import { END_POINT, MASTER_API_KEY } from './constants';

export const searchPodcast = async ({
  limit,
  offset,
  search,
  sort,
  filter
}) => {
  filter = !_.isEmpty(filter) ? filter : undefined;
  sort = !_.isEmpty(sort) ? sort : undefined;
  const searchResponse = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
      query searchPodcasts($pagination: PaginationArgs!, $search:String, $sort: PodcastSortArgs, $filter:PodcastFilterArgs){
        searchPodcasts(
          pagination: $pagination,
          search:$search,
          sort:$sort,
          filter:$filter
          ){
            results{
              id
              artist_name
              artwork_url_100
              collection_id
              collection_name
              description
              genre
              release_date
            }
            total
          }
        }
      `,
      variables: {
        pagination: {
          limit,
          offset
        },
        search,
        sort,
        filter
      }
    }
  });
  return searchResponse;
};
