/* eslint-disable */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cardRoot: { border: '1px solid #CCCCCC', borderRadius: '20px' },
  button: {
    borderRadius: '20px',
    textTransform: 'capitalize'
  },
  inputText: {
    borderRadius: '20px'
  }
}));

const ProfileDetails = ({
  className,
  user,
  history,
  onUpdateUserStart,
  ...rest
}) => {
  const classes = useStyles();

  const handleSubmit = (values) => {
    console.log('handle submit pressed');
    onUpdateUserStart(
      {
        id: user.id,
        input: {
          firstName: values.firstName ? values.firstName : '',
          lastName: values.lastName ? values.lastName : ''
        }
      },
      history
    );
    // event.preventDefault();
    // onUpdateUserStart(
    //   {
    //     id: user.id,
    //     input: {
    //       firstName: values.firstName ? values.firstName : '',
    //       lastName: values.lastName ? values.lastName : ''
    //     }
    //   },
    //   history
    // );
  };

  return (
    <Formik
      initialValues={{
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        email: user?.email ?? '',
        username: user?.username ?? ''
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().trim().max(255),
        lastName: Yup.string().trim().max(255)
      })}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, touched, values }) => (
        <Form>
          <Card className={classes.cardRoot}>
            <CardHeader
              // subheader="The information can be edited"
              title="Profile"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    fullWidth
                    placeholder="First name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.inputText
                      }
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    placeholder="Last name"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.inputText
                      }
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Email Address"
                    name="email"
                    disabled
                    value={values.email}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.inputText
                      }
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    placeholder="User name"
                    name="username"
                    disabled
                    value={values.username}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.inputText
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.button}
                disabled={
                  values?.firstName === user?.firstName &&
                  values?.lastName === user?.lastName
                }
              >
                Save details
              </Button>
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
