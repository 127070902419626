import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CodeCard from '../codeCard/codeCard';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  }
}));

const SearchPodcasts = () => {
  const classes = useStyles();

  function createData2(name, calories) {
    return { name, calories };
  }

  const rows2 = [
    createData2(
      <>
        filter -{' '}
        <Link href="#podcastFilterArgs" color="inherit">
          PodcastFilterArgs
        </Link>
      </>,
      'The arguments for filtering the podcasts by categories and collection id'
    ),
    createData2(
      <>
        pagination -
        <Link href="#paginationArgs" color="inherit">
          PaginationArgs{' '}
        </Link>
      </>,
      'The arguments for limit and offset based pagination'
    ),
    createData2('search - String', 'Search podcasts by query string'),
    createData2(
      <>
        sort -{' '}
        <Link href="#podcastSortArgs" color="inherit">
          PodcastSortArgs
        </Link>
      </>,
      'Sort the podcasts '
    )
  ];

  return (
    <div className={classes.root}>
      <Grid item container id="searchPodcasts">
        <Grid
          item
          container
          md={7}
          sm={12}
          xs={12}
          style={{ padding: '1rem' }}
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h3" className="doc-title">
              searchPodcasts
            </Typography>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom>
                search the podcasts
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                className="operation-name"
                color="textSecondary"
              >
                Response
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" className="operation-name">
                Returns a{' '}
                <Link href="#podcastSearchResponse" color="inherit">
                  PodcastSearchResponse!
                </Link>
              </Typography>
            </Grid>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom color="textSecondary">
                Arguments
              </Typography>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* <h2 className="operation-name">
            <code>podcast</code>
          </h2>

          <section className="swagger-operation-description operation-description">
            <Typography variant="body1" className="operation-name">
              Description
            </Typography>
            <p>Fetch detailed metadata and episodes for a specific podcast</p>
          </section> */}

          {/* <Typography variant="body1" className="operation-name">
            Response
          </Typography>
          <section className="swagger-responses">
            Returns a
            <Link to="/documentation#definition-PodcastType">
              <code>PodcastType!</code>
            </Link>
          </section>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Name </th>
                <th>Description</th>
              </tr>
              <tr>
                <td>
                  <code>id</code> -
                  <Link to="/documentation#definition-Int">
                    <code>Int!</code>
                  </Link>
                </td>
                <td> Collection id of the podcast </td>
              </tr>
            </tbody>
          </table> */}
        </Grid>
        <Grid
          item
          container
          md={5}
          sm={12}
          xs={12}
          style={{
            padding: '1rem'
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            <CodeCard header="End Point">
              ``` query searchPodcasts($filter: PodcastFilterArgs, $pagination:
              PaginationArgs!, $search: STRING,/n $sort: PodcastSortArgs)
              &#123;/n searchPodcasts(filter: $filter, pagination:
              $pagination,/n search: $search, sort: $sort) &#123;/n &#123;/n
              results &#123;/n ...PodcastTypeFragment/n &#125;/n &#125;/n &#125;
              ```
            </CodeCard>
          </Grid>

          <Grid item xs={12}>
            <CodeCard header="Response">
              ``` &#123;/n &quot;data &quot;:&#123;/n &quot;searchPodcasts
              &quot;:/n &#123;/n &quot;results &quot;:[PodcastType]/n &#125;/n
              &#125;/n &#125; ```
            </CodeCard>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchPodcasts;
