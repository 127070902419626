/* eslint-disable */
import { Container, Grid, makeStyles } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const General = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user, onUpdateUserStart, onUploadProfilePicStart } = props;

  return (
    <Grid item container spacing={3}>
      <Grid item lg={4} md={6} xs={12}>
        <Profile
          user={user}
          onUploadProfilePicStart={onUploadProfilePicStart}
          history={history}
        />
      </Grid>
      <Grid item lg={8} md={6} xs={12}>
        <ProfileDetails
          user={user}
          onUpdateUserStart={onUpdateUserStart}
          history={history}
        />
      </Grid>
    </Grid>
  );
};

export default General;
