/* eslint-disable */
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  Paper,
  CircularProgress
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import container from './ForgotPassword.container';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ForgotPassword = ({ auth: { loading }, onRequestPasswordResetStart }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = (values) => {
    console.log('values', values);

    onRequestPasswordResetStart(values);
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Paper elevation={20}>
            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Must be a valid email')
                  .max(255)
                  .required('Email is required')
              })}
              onSubmit={handleSubmit}
            >
              {({ values, touched, handleBlur, handleChange, errors }) => (
                <Form style={{ padding: '93px 91px' }}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                      align="center"
                      style={{ fontWeight: 400 }}
                    >
                      Forgot Password?
                    </Typography>
                  </Box>

                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />

                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={loading}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {loading ? <CircularProgress /> : 'Submit'}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Container>
      </Box>
    </Page>
  );
};

export default container(ForgotPassword);
