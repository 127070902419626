import axios from 'axios';

import { END_POINT, MASTER_API_KEY } from './constants';

const setPlayerCount = async ({ collectionId }) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
        mutation setPlayerCount($collectionId: Float!){
          setPlayerCount(collectionId: $collectionId){
            success
          }
        }
      `,
      variables: {
        collectionId
      }
    }
  });
  return result;
};

export default setPlayerCount;
