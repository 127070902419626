import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

import Typography from 'src/modules/components/Typography';
import facebook from 'src/assets/img/icons/facebook.svg';
import instagram from 'src/assets/img/icons/instagram.svg';
import twitter from 'src/assets/img/icons/twitter.svg';
import wifi from 'src/assets/img/icons/wifi.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#232323',
    marginTop: '2rem'
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex'
  },
  iconsWrapper: {
    height: 120
  },
  icons: {
    display: 'flex'
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150
  },
  privacyPolicy: {
    color: '#ffffff'
  },
  grow: {
    flexGrow: 1
  }
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container justify="center" spacing={2}>
          <Grid item container xs={12} justify="center">
            <Typography variant="h3" style={{ color: '#CCCCCC' }}>
              Podcast
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.privacy}
          >
            <Grid item container xs={4} spacing={2}>
              <Grid
                item
                component="a"
                href="#"
                rel="noopener noreferral"
                target="_blank"
              >
                <img src={wifi} alt="wifi logo" />
              </Grid>
              <Grid
                item
                component="a"
                href="#"
                rel="noopener noreferral"
                target="_blank"
              >
                <img src={facebook} alt="facebook logo" />
              </Grid>
              <Grid
                item
                component="a"
                href="#"
                rel="noopener noreferral"
                target="_blank"
              >
                <img src={twitter} alt="twitter logo" />
              </Grid>
              <Grid
                item
                component="a"
                href="#"
                rel="noopener noreferral"
                target="_blank"
              >
                <img src={instagram} alt="instagram logo" />
              </Grid>
            </Grid>

            <div className={classes.grow} />

            <Grid item xs={4} justify="flex-end" container spacing={2}>
              <Grid item>
                <Typography variant="body1" className={classes.privacyPolicy}>
                  Privacy policy
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.privacyPolicy}>
                  Disclaimer
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.privacyPolicy}>
                  Terms of use
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ backgroundColor: '#CCCCCC' }} />
          </Grid>
          <Grid item container xs={12} justify="center">
            <Typography variant="body1" style={{ color: '#CCCCCC' }}>
              © Podcast.com,
              {new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
