/* eslint-disable */
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  Paper
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import container from './SignIn.container';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const InitialValues = {
  email: '',
  password: ''
};

const signInValidation = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  password: Yup.string().max(255).required('password is required')
});

const LoginView = ({ onSignInStart, error }) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Paper elevation={20}>
            <Formik
              initialValues={InitialValues}
              validationSchema={signInValidation}
              onSubmit={async (values, { resetForm }) => {
                onSignInStart(
                  {
                    email: values.email.trim().toLowerCase(),
                    password: values.password
                  },
                  history
                );
                // resetForm();
                // history.push('/');
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit} style={{ padding: '93px 91px' }}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                      align="center"
                      style={{ fontWeight: 400 }}
                    >
                      Sign In
                    </Typography>
                  </Box>

                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <Box my={4} style={{ margin: '1.5rem 0' }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ textTransform: 'capitalize' }}
                    >
                      Sign in
                    </Button>
                  </Box>
                  <Grid container>
                    <Grid item style={{ flexGrow: 1 }}>
                      <Typography variant="body1">
                        <Link
                          component={RouterLink}
                          to="/forgotPassword"
                          variant="h6"
                          style={{ color: '#263238' }}
                        >
                          Forgot Password?
                        </Link>
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="body1">
                        Don&apos;t have an account?
                        <Link
                          component={RouterLink}
                          to="/register"
                          variant="h6"
                          style={{ color: '#263238' }}
                        >
                          Sign Up
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Container>
      </Box>
    </Page>
  );
};

export default container(LoginView);
