/* eslint-disable */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  Grid,
  IconButton,
  Tooltip
} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import InputAdornment from '@material-ui/core/InputAdornment';

import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const Notifications = ({
  className,
  subscription: { apiToken },
  onGenTokenStart,
  hasSubscription,
  ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    password: '',
    key: ''
  });
  const [clipboard, setClipboard] = useState('Copy To Clipboard');
  const history = useHistory();

  useEffect(() => {
    if (apiToken) {
      setValues({ ...values, key: apiToken.key });
    } else {
      setValues({ ...values, key: '' });
    }
  }, [apiToken]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const copyToClipboard = () => {
    setClipboard('copied');
    navigator.clipboard.writeText(values.key);
  };

  useEffect(() => {
    window.setInterval(() => {
      setClipboard('Copy To Clipboard');
    }, 5000);
  }, []);

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title="API-Token"
          action={
            hasSubscription ? null : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push('/pricing')}
              >
                Subscribe
              </Button>
            )
          }
        />
        <Divider />
        {/* <Grid container justify="flex-end" style={{ padding: '0.3rem 1rem' }}>
          <IconButton
            aria-label="copy-text"
            onClick={() => {
              copyToClipboard();
            }}
          >
            <Tooltip title={clipboard} aria-label="add">
              <FileCopyIcon />
            </Tooltip>
          </IconButton>
        </Grid> */}
        <CardContent>
          {/* <TextField
            fullWidth
            label="App Name"
            margin="normal"
            name="name"
            onChange={handleChange}
            value={values.password}
            variant="outlined"
          /> */}
          <OutlinedInput
            fullWidth
            label="API Token"
            margin="normal"
            name="key"
            onChange={handleChange}
            value={values.key}
            variant="outlined"
            endAdornment={
              <InputAdornment position="end">
                <Tooltip title={clipboard} aria-label="add">
                  <IconButton
                    aria-label="copy-text"
                    onClick={() => {
                      copyToClipboard();
                    }}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        </CardContent>
      </Card>
    </form>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
