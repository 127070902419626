/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux';

import { createSubscriptionStart } from 'src/store/subscription/subscription.actions';

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  onCreateSubscriptionStart: (history, handleClose) =>
    dispatch(createSubscriptionStart(history, handleClose))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
