import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import { Grid, Slider } from '@material-ui/core/';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles(() => ({
  volumeIcon: {
    color: '#ffffff'
  }
}));

const VolumeController = ({ player }) => {
  const classes = useStyles();
  const [volume, setVolume] = useState(100);

  const volumeChange = (e, newVal) => {
    setVolume(newVal);
    player.volume = newVal / 100;
    //
  };

  return (
    <Grid container spacing={1} style={{ maxWidth: '200px' }}>
      <Grid item>
        <VolumeUpIcon color="primary" className={classes.volumeIcon} />
      </Grid>
      <Grid item xs={3}>
        <Slider
          value={volume}
          onChange={volumeChange}
          style={{ color: '#ffffff' }}
        />
      </Grid>
    </Grid>
  );
};

VolumeController.propTypes = {
  player: PropTypes.object
};

export default VolumeController;
