import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Hidden, useMediaQuery } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

import VolumeController from './VolumeController';
import PreviousButton from './PreviousButton';
import PlayPauseButton from './PlayPauseButton';
import NextButton from './NextButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    width: '90px',
    height: '90px'
  },
  playerIcons: {
    maxWidth: 310,
    height: 80,
    marginTop: '0.5rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '1.1rem'
    }
  },
  volIcons: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '2.1rem'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '1.1rem'
    }
  }
}));

const MiniMuiscArt = ({
  currentEpisode: { title },
  podcast: { artwork_url_100: artworkUrl100 },
  player,
  playNext,
  playPrevious,
  audioState,
  onClearPlayerState,
  openPlaylistModal
}) => {
  const theme = useTheme();

  const classes = useStyles();

  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container>
      <Grid container>
        <Grid item container alignContent="center" lg md sm>
          <Grid
            container
            justify={matches ? 'center' : undefined}
            alignItems={matches ? 'center' : undefined}
          >
            <Grid item md={5} xs={12} style={{ marginTop: '1.1rem' }}>
              <Typography variant="h5" align={matches ? 'center' : undefined}>
                {title}
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item md={7} xs={12}>
                <img
                  className={classes.img}
                  alt="name"
                  src={artworkUrl100 ? artworkUrl100.toString() : null}
                />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid item container lg md sm justify={matches ? 'center' : undefined}>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            className={classes.playerIcons}
          >
            <Grid item xs={3}>
              <PreviousButton playPrevious={playPrevious} />
            </Grid>
            <Grid item xs={3}>
              <PlayPauseButton player={player} audioState={audioState} />
            </Grid>

            <Grid item xs={3}>
              <NextButton onPlayNext={playNext} />
            </Grid>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid item container xs={3} justify={matches ? 'center' : undefined}>
            <IconButton onClick={openPlaylistModal}>
              <PlaylistPlayIcon
                style={{ width: 32, height: 32, color: '#ffffff' }}
              />
            </IconButton>
          </Grid>
        </Hidden>

        <Grid
          item
          container
          alignItems="center"
          lg
          md
          sm={3}
          className={classes.volIcons}
        >
          <Hidden smUp>
            <Grid item xs={3}>
              <IconButton onClick={openPlaylistModal}>
                <PlaylistPlayIcon
                  style={{ width: 32, height: 32, color: '#ffffff' }}
                />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item md sm xs>
            <VolumeController player={player} />
          </Grid>
          <Grid item md={2} sm={3} xs={3}>
            <IconButton onClick={onClearPlayerState}>
              <CloseIcon style={{ width: 22, height: 22, color: '#ffffff' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

MiniMuiscArt.propTypes = {
  currentEpisode: PropTypes.object,
  podcast: PropTypes.object,
  audioState: PropTypes.string,
  player: PropTypes.object,
  playNext: PropTypes.func,
  onClearPlayerState: PropTypes.func,
  playPrevious: PropTypes.func,
  openPlaylistModal: PropTypes.func
};

export default MiniMuiscArt;
