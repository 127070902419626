/* eslint-disable */
import axios from 'axios';

import { END_POINT, MASTER_API_KEY } from './constants';

export const searchEpisode = async ({
  limit,
  offset,
  search,
  sort,
  filter
}) => {
  filter = !_.isEmpty(filter) ? filter : undefined;
  sort = !_.isEmpty(sort) ? sort : undefined;
  const searchResponse = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
      query searchEpisodes($pagination: PaginationArgs!, $search:String, $filter: EpisodeFilterArgs, $sort: EpisodeSortArgs){
        searchEpisodes(
          pagination: $pagination,
          search:$search,
          filter: $filter,
          sort:$sort
        ){
          results{
            id  
            guid
            title
            description
            pub_date
            duration
            duration_string
            link
            title
            collection_name
            collection_id
            artwork_url_100
          }
          total
        }
      }
      `,
      variables: {
        pagination: {
          limit,
          offset
        },
        search,
        sort,
        filter
      }
    }
  });
  return searchResponse;
};
