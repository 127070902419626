/* eslint-disable */
import * as actionType from './auth.types';

export const loadUserStart = () => ({
  type: actionType.LOAD_USER_START
});

export const loadUserSuccess = (currentUser) => ({
  type: actionType.LOAD_USER_SUCCESS,
  payload: currentUser
});

export const loadUserFail = (error) => ({
  type: actionType.LOAD_USER_FAIL,
  payload: error
});

export const signUpStart = (formData, history) => ({
  type: actionType.SIGNUP_START,
  payload: { formData, history }
});

export const signUpSuccess = () => ({
  type: actionType.SIGNUP_SUCCESS
});

export const signUpFail = (error) => ({
  type: actionType.SIGNUP_FAIL,
  payload: error
});

export const signInStart = (formData, history) => ({
  type: actionType.SIGNIN_START,
  payload: { formData, history }
});

export const signInSuccess = (data) => ({
  type: actionType.SIGNIN_SUCCESS,
  payload: data
});

export const signInFail = (error) => ({
  type: actionType.SIGNIN_FAIL,
  payload: error
});

export const signOutStart = (show) => ({
  type: actionType.SIGNOUT_START,
  payload: { show }
});

export const signOutSuccess = () => ({
  type: actionType.SIGNOUT_SUCCESS
});

export const signOutFail = (error) => ({
  type: actionType.SIGNOUT_FAIL,
  payload: error
});

export const updateUserStart = (formData, history) => ({
  type: actionType.UPDATE_USER_START,
  payload: { formData, history }
});

export const updateUserSuccess = (data) => ({
  type: actionType.UPDATE_USER_SUCCESS,
  payload: data
});

export const updateUserFail = (error) => ({
  type: actionType.UPDATE_USER_FAIL,
  payload: error
});

export const fetchTotalAPICountStart = () => ({
  type: actionType.FETCH_TOTAL_API_COUNT_START
});

export const fetchTotalAPICountSuccess = (data) => ({
  type: actionType.FETCH_TOTAL_API_COUNT_SUCCESS,
  payload: data
});

export const fetchTotalAPICountFail = (error) => ({
  type: actionType.FETCH_TOTAL_API_COUNT_FAIL,
  payload: error
});

export const fetchAPICountByDateRangeStart = (startDate, endDate) => ({
  type: actionType.FETCH_API_COUNT_BY_DATE_RANGE_START,
  payload: { startDate, endDate }
});

export const fetchAPICountByDateRangeSuccess = (data) => ({
  type: actionType.FETCH_API_COUNT_BY_DATE_RANGE_SUCCESS,
  payload: data
});

export const fetchAPICountByDateRangeFail = (error) => ({
  type: actionType.FETCH_API_COUNT_BY_DATE_RANGE_FAIL,
  payload: error
});

export const requestPasswordResetStart = (email) => ({
  type: actionType.REQUEST_PASSWORD_RESET_START,
  payload: email
});

export const requestPasswordResetSuccess = (data) => ({
  type: actionType.REQUEST_PASSWORD_RESET_SUCCESS,
  payload: data
});

export const requestPasswordResetFail = (error) => ({
  type: actionType.REQUEST_PASSWORD_RESET_FAIL,
  payload: error
});

export const verifyTokenStart = (token) => ({
  type: actionType.VERIFY_TOKEN_START,
  payload: { token }
});

export const verifyTokenSuccess = (data) => ({
  type: actionType.VERIFY_TOKEN_SUCCESS,
  payload: data
});

export const verifyTokenFail = (error) => ({
  type: actionType.VERIFY_TOKEN_FAIL,
  payload: error
});

export const resetPasswordStart = (passwordData, history) => ({
  type: actionType.RESET_PASSWORD_START,
  payload: { passwordData, history }
});

export const resetPasswordSuccess = (data) => ({
  type: actionType.RESET_PASSWORD_SUCCESS,
  payload: data
});

export const resetPasswordFail = (error) => ({
  type: actionType.RESET_PASSWORD_FAIL,
  payload: error
});

export const updatePasswordStart = (passwordData, history) => ({
  type: actionType.UPDATE_PASSWORD_START,
  payload: { passwordData, history }
});

export const updatePasswordSuccess = (data) => ({
  type: actionType.UPDATE_PASSWORD_SUCCESS,
  payload: data
});

export const updatePasswordFail = (error) => ({
  type: actionType.UPDATE_PASSWORD_FAIL,
  payload: error
});

export const uploadProfilePicStart = (file, history) => ({
  type: actionType.UPLOAD_PROFILE_PIC_START,
  payload: { file, history }
});

export const uploadProfilePicSuccess = () => ({
  type: actionType.UPLOAD_PROFILE_PIC_SUCCESS
});

export const uploadProfilePicFail = (error) => ({
  type: actionType.UPLOAD_PROFILE_PIC_FAIL,
  payload: error
});
