import { Switch } from 'react-router-dom';
// import Documentation from 'src/pages/Documentation/documentation.component';
import Docs from 'src/pages/Docs/docs.component';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Dashboard as DashboardLayout,
  Landing as LandingLayout,
  SearchMain
} from '../layouts';

import { RouteWithLayout, PrivateRoute, PublicRoute } from './components';

// import Home from '../pages/Home';
import AccountView from '../pages/AccountView';
import SettingsView from '../pages/SettingsView/Settings.component';
import DashboardView from '../pages/DashboardView';
import ProductListView from '../pages/ProductListView';
import UserListView from '../pages/CustomerListView';
import SignInView from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import SignUpView from '../pages/SignUp';
import NotFoundView from '../pages/NotFound';
import Search from '../pages/Search';
import Podcast from '../pages/Podcast';
// import Pricing from '../pages/Pricing';
import LandingPage from '../pages/LandingPage';
import NewPricing from '../pages/NewPricing';
import PodcastDetails from '../pages/PodcastDetails';
import SearchResult from '../pages/SearchResult';
import ChangePassword from '../pages/ChangePassword';

const Routes = (props) => (
  <Switch>
    <RouteWithLayout
      exact
      path="/"
      component={LandingPage}
      layout={LandingLayout}
      {...props}
    />
    <RouteWithLayout
      exact
      path="/podcast/:id"
      component={Podcast}
      layout={MainLayout}
      {...props}
    />
    <RouteWithLayout
      exact
      path="/search"
      component={Search}
      layout={MainLayout}
      {...props}
    />
    <RouteWithLayout
      exact
      path="/pricing"
      component={NewPricing}
      layout={LandingLayout}
      {...props}
    />
    {/* <RouteWithLayout
      exact
      path="/documentation"
      component={Documentation}
      layout={MinimalLayout}
    /> */}
    <RouteWithLayout
      exact
      path="/documentation"
      component={Docs}
      layout={MinimalLayout}
    />
    <PrivateRoute
      component={DashboardView}
      exact
      layout={DashboardLayout}
      path="/app/dashboard"
    />
    <PrivateRoute
      component={UserListView}
      exact
      layout={DashboardLayout}
      path="/app/customers"
    />
    <PrivateRoute
      component={ProductListView}
      exact
      layout={DashboardLayout}
      path="/app/products"
    />
    <PrivateRoute
      component={AccountView}
      exact
      layout={DashboardLayout}
      path="/app/account"
    />
    <PrivateRoute
      component={ChangePassword}
      exact
      layout={DashboardLayout}
      path="/changePassword"
    />
    <PrivateRoute
      component={SettingsView}
      exact
      layout={DashboardLayout}
      path="/app/settings"
    />
    <PrivateRoute
      component={DashboardView}
      exact
      layout={DashboardLayout}
      path="/app/dashboard"
    />
    <PublicRoute
      component={SignUpView}
      exact
      layout={LandingLayout}
      hideOptions
      path="/register"
    />
    <PublicRoute
      component={SignInView}
      exact
      layout={LandingLayout}
      hideOptions
      path="/login"
    />
    <PublicRoute
      component={ForgotPassword}
      exact
      layout={LandingLayout}
      hideOptions
      path="/forgotPassword"
    />
    <PublicRoute
      component={ResetPassword}
      exact
      layout={LandingLayout}
      hideOptions
      path="/resetPassword"
    />
    <PublicRoute
      component={LandingPage}
      exact
      layout={LandingLayout}
      path="/landing"
    />
    <PublicRoute
      component={NewPricing}
      exact
      layout={LandingLayout}
      path="/newPricing"
    />
    <RouteWithLayout
      component={PodcastDetails}
      exact
      layout={SearchMain}
      path="/details/:id"
      {...props}
    />
    <RouteWithLayout
      component={SearchResult}
      exact
      layout={SearchMain}
      path="/result"
      {...props}
    />
    <RouteWithLayout
      component={NotFoundView}
      exact
      layout={MinimalLayout}
      path="/404"
    />
    <RouteWithLayout component={NotFoundView} layout={MinimalLayout} />
  </Switch>
);

export default Routes;
