import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    border: '1px solid #CCCCCC',
    borderRadius: '20px'
  },
  cardContent: {
    padding: '37px 0 38px 28px',
    height: '146px',
    width: '256px'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const TodaysRequestUsage = ({ todayAPICount, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Today&apos;s Request Usage
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {todayAPICount ?? todayAPICount}
            </Typography>
          </Grid>
          {/* <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon />
            </Avatar>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

TodaysRequestUsage.propTypes = {
  className: PropTypes.string,
  todayAPICount: PropTypes.any
};

export default TodaysRequestUsage;
