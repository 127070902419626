/* eslint-disable */
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import dashboardUser from 'src/assets/img/dashboardUser.svg';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #CCCCCC',
    borderRadius: '20px'
  },
  button: {
    textTransform: 'capitalize'
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({
  history,
  className,
  user,
  onUploadProfilePicStart,
  ...rest
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onUploadProfilePicStart(e.target.files[0], history);
  };

  const userInfo = {
    avatar: '/static/images/avatars/empty_dp.png'
  };

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Avatar
              className={classes.avatar}
              src={user?.profilePicUrl ? user?.profilePicUrl : dashboardUser}
            />
            <Typography color="textPrimary" gutterBottom variant="h3">
              {user?.firstName && user?.lastName
                ? `${user.firstName ?? user.firstName} ${
                    user.lastName ?? user.lastName
                  }`
                : user?.username}
            </Typography>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            component="label"
            className={classes.button}
          >
            Upload picture
            <input
              accept="image/*"
              type="file"
              onChange={handleChange}
              hidden
            />
          </Button>
        </CardActions>
      </Card>
      {/* <Box alignItems="center" display="flex" flexDirection="column" mt={5}>
        <Button
          variant="contained"
          onClick={() => history.push('/changePassword')}
        >
          Change Password
        </Button>
      </Box> */}
    </>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
