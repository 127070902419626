/*eslint-disable*/
import getUniqueItemsFromList from './getUniqueItemsFromList';

const transformAutoCompleteResults = (payload) => {
  let genres = [];
  payload.forEach((obj) => {
    genres.push(...obj.genre);
    delete obj.genre;
  });
  // genres = getUniqueItemsFromList(genres).map(
  //   (item) => new Object({ genre: item, type: 'genre' })
  // );
  payload.forEach((obj) => (obj.type = 'podcast'));

  return [...payload];
};

export default transformAutoCompleteResults;
