/* eslint-disable */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import doubleDownArrow from 'src/assets/img/icons/doubleDownArrow.svg';
import ShowMore from 'src/modules/components/ShowMore';

import container from './Landing.container';
import CustomizedInputBase from './components/SearchBar';
import DataCard from 'src/modules/views/DataCard';

const qs = require('qs');

const useStyles = makeStyles((theme) => ({
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)'
    },
    '40%': {
      transform: ' translateY(-30px)'
    },
    '60%': {
      transform: 'translateY(-15px)'
    }
  },
  bounceAnimation: {
    animation: '$bounce 2s infinite',
    cursor: 'pointer'
  },
  checkTrending: {
    padding: '4rem 0 2rem',
    fontWeight: 400,
    fontSize: '30px',
    color: '#333333',
    cursor: 'pointer'
  },
  trendingContainer: {
    paddingTop: '15rem',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '6rem'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '10rem'
    }
  }
}));

const mapSortPodcastToValue = ['DESC', 'ASC'];
function Index(props) {
  const classes = useStyles();
  const {
    onFetchTrendingPodcastsStart,
    isLoading,
    podcastIDs,
    podcasts,
    onSetCurrentPodcast,
    history
  } = props;

  const searchQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true
  }).query;

  const [query, setQuery] = useState('');

  // const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  // const [sortPodcast, setSortPodcast] = useState(0);

  useEffect(() => {
    onFetchTrendingPodcastsStart(limit, 0);
  }, [limit]);

  const items = podcastIDs.map((id) => podcasts[id]);

  let emptyContents;
  if (isLoading && items.length === 0) {
    emptyContents = <CircularProgress />;
  } else if (!isLoading && items.length === 0) {
    emptyContents = (
      <Alert variant="outlined" severity="error">
        No Result Found
      </Alert>
    );
  }

  const handleSubmit = () => {
    console.log('on handle submit search>>>', query);
    history.push(`/result?query=${query}`);
  };

  const handleQueryChange = (value) => {
    setQuery(value);
    // onSetQuery(event.target.value);
  };

  const handleChange = (event) => {
    setSortPodcast(event.target.value);
  };

  const seeMoreHandler = () => {
    setLimit(limit + 15);
  };

  const trendingToggle = () => {
    console.log('trendingToggle');
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const myRef = useRef(null);

  // const executeScroll = () => myRef.current.scrollIntoView();
  // run t

  return (
    <>
      <Container maxWidth="md" style={{ minHeight: 'calc(100vh - 140px)' }}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              align="center"
              style={{ padding: '4rem 0 2rem' }}
            >
              PODCAST
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomizedInputBase
              history={history}
              placeholder="Trending: Rahdo Talks Through"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.trendingContainer}
        >
          <Grid item>
            <Typography
              variant="h4"
              align="center"
              className={classes.checkTrending}
              onClick={trendingToggle}
            >
              CHECK TRENDING
            </Typography>
          </Grid>

          <Grid item>
            <img
              src={doubleDownArrow}
              alt="doubleArrow"
              className={classes.bounceAnimation}
              onClick={trendingToggle}
            />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="sm">
        <Grid container justify="center" spacing={4} ref={myRef}>
          {items && items.length
            ? items.map((podcast, i) => (
                <Grid item>
                  <DataCard
                    key={`podcast-${i + 1}-${podcast.id}`}
                    data={podcast}
                    setCurrentPodcast={onSetCurrentPodcast}
                    history={history}
                  />
                </Grid>
              ))
            : emptyContents}
          {!isLoading && items && items.length < 100 && (
            <ShowMore nextList={seeMoreHandler} />
          )}
        </Grid>
      </Container>
    </>
  );
}

Index.propTypes = {
  podcastIDs: PropTypes.array,
  podcasts: PropTypes.object,
  isLoading: PropTypes.bool,
  // onFetchPodcastStart: PropTypes.func,
  onSetCurrentPodcast: PropTypes.func,
  history: PropTypes.any
};

export default container(Index);
