/* eslint-disable */
import { connect } from 'react-redux';

import {
  getTrendingPodcastsStart,
  setCurrentPodcast
} from 'src/store/podcast/podcast.actions';
// import { selectPodcastsForSearch } from 'src/store/podcast/podcast.selectors';

const mapStateToProps = (state) => ({
  items: state.podcast.items,
  isLoading: state.podcast.loading,
  podcastIDs: state.podcast.podcastIDs,
  podcasts: state.podcast.podcasts,
  totalPodcasts: state.podcast.totalPodcastSearchResults
});

const mapDispatchToProps = (dispatch) => ({
  onFetchTrendingPodcastsStart: (limit, offset) =>
    dispatch(getTrendingPodcastsStart(limit, offset)),
  onSetCurrentPodcast: (page, size) => dispatch(setCurrentPodcast(page, size))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
