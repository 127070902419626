/* eslint-disable */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';

import PodcastCard from 'src/modules/views/PodcastCard';
import EpisodeCard from 'src/modules/views/EpisodeCard';

import container from './Podcast.container';
import ShowMore from 'src/modules/components/ShowMore';
import CustomizedInputBase from './components/SearchBar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500
  }
}));

const mapSortEpisodeToValue = ['DESC', 'ASC'];

const Podcast = (props) => {
  const classes = useStyles();
  const {
    podcast,
    isLoading,
    onSetPlayerCurrentTrack,
    onFetchDataStart,
    match,
    onSetQueryForEpisodes,
    onResetQueryForEpisodes,
    searchQuery,
    episodesQuery,
    ...rest
  } = props;
  const [sortEpisode, setSortEpisode] = useState(0);
  const [query, setQuery] = useState('');
  const [more, setMore] = useState(5);

  useEffect(() => {
    onFetchDataStart(match.params.id, 1, 200, {
      pub_date: mapSortEpisodeToValue[sortEpisode]
    });
    return () => {
      onResetQueryForEpisodes();
    };
  }, [match.params.id, sortEpisode]);

  const seeMoreHandler = () => {
    setMore(more + 5);
  };

  const handleChange = (event) => {
    setSortEpisode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // onSetQueryForEpisodes(query)
  };

  const handleQueryChange = async (event) => {
    event.preventDefault();
    setQuery(event.target.value);
    onSetQueryForEpisodes(event.target.value);
  };

  let episodes = [];
  if (searchQuery) {
    episodes = episodesQuery;
  } else {
    episodes = podcast ? podcast.episodes : [];
  }

  let emptyContents;
  if (isLoading) {
    emptyContents = (
      <Grid container justify="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  } else if (!isLoading && episodes && episodes.length === 0) {
    emptyContents = (
      <Grid container justify="center">
        <Grid item xs={5}>
          <Alert variant="outlined" severity="error">
            No Result Found
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={5}>
          <CustomizedInputBase
            query={query}
            setQuery={setQuery}
            onChange={handleQueryChange}
            onSubmit={handleSubmit}
            placeholder={`Search episodes inside ${
              podcast ? podcast.collection_name : ''
            }`}
          />
        </Grid>
      </Grid>
      {podcast ? (
        <PodcastCard data={podcast} key={podcast.id} viewDisable />
      ) : (
        emptyContents
      )}
      <Typography variant="h4" align="center">
        Episodes
      </Typography>

      <Grid
        container
        direction="row"
        justify="flex-end"
        className={classes.paper}
      >
        <Grid>
          <Select
            labelId="sort episode"
            id="sort-episode"
            value={sortEpisode}
            onChange={handleChange}
          >
            <MenuItem value={0}>Published (Newest)</MenuItem>
            <MenuItem value={1}>Published (Oldest)</MenuItem>
          </Select>
        </Grid>
      </Grid>

      {episodes && episodes.length
        ? episodes
            .slice(0, more)
            .map((episode, i) => (
              <EpisodeCard
                data={episode}
                key={episode.guid}
                setPlayerEpisode={onSetPlayerCurrentTrack}
                {...rest}
              />
            ))
        : emptyContents}
      {!isLoading && episodes && episodes.length > more && (
        <ShowMore nextList={seeMoreHandler} />
      )}
    </>
  );
};

Podcast.propTypes = {
  podcast: PropTypes.object,
  isLoading: PropTypes.bool,
  onSetPlayerCurrentTrack: PropTypes.func,
  onFetchDataStart: PropTypes.func,
  match: PropTypes.any
};

export default container(Podcast);
