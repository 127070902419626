import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { PauseCircleFilled, PlayCircleFilled } from '@material-ui/icons';

const PlayPauseButton = ({ player, miscState, episodePlaying, onClick }) => {
  console.log({ player });

  const togglePlayPause = (e) => {
    e.stopPropagation();
    if (miscState === 'playing') {
      player.pause();
    } else {
      player.play();
    }
    onClick();
  };

  const showPlayPause = () => {
    if (episodePlaying && miscState === 'playing') {
      return <PauseCircleFilled style={{ fontSize: '3em' }} />;
    }
    return <PlayCircleFilled style={{ fontSize: '3em' }} />;
  };

  return (
    <IconButton
      size="large"
      color="primary"
      onClick={togglePlayPause}
      disableFocusRipple
      disableRipple
    >
      {showPlayPause()}
    </IconButton>
  );
};

PlayPauseButton.propTypes = {
  player: PropTypes.any,
  miscState: PropTypes.string,
  episodePlaying: PropTypes.any,
  onClick: PropTypes.func
};

export default PlayPauseButton;
