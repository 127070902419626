/* eslint-disable */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Grid,
  Typography,
  Divider,
  Avatar
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  Users as UsersIcon,
  Home as HomeIcon
} from 'react-feather';
import dashboardUser from 'src/assets/img/dashboardUser.svg';
import NavItem from './NavItem';

const items = [
  // {
  //   href: '/app/dashboard',
  //   icon: BarChartIcon,
  //   title: 'Dashboard'
  // },
  // {
  //   href: '/app/customers',
  //   icon: UsersIcon,
  //   title: 'Customers'
  // },
  // {
  //   href: '/app/products',
  //   icon: ShoppingBagIcon,
  //   title: 'Products'
  // },
  {
    href: '/app/dashboard',
    icon: HomeIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  avatar: {
    width: 60,
    height: 60
  }
}));

const NavBar = ({ user, onMobileClose, openMobile, ...props }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  console.log('value of user in dashboard sidebar>>', user);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <Box
            p={3}
            m={3}
            style={{
              background: ' #EEEEEE',
              borderRadius: '10px'
            }}
          >
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={6}>
                <Avatar
                  className={classes.avatar}
                  alt="complex"
                  src={
                    user?.profilePicUrl ? user?.profilePicUrl : dashboardUser
                  }
                >
                  <img
                    src={
                      user?.profilePicUrl ? user?.profilePicUrl : dashboardUser
                    }
                    alt="dashboardUser"
                  />
                </Avatar>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textPrimary" gutterBottom variant="h5">
                  {user?.firstName && user?.lastName
                    ? `${user.firstName ?? user.firstName} ${
                        user.lastName ?? user.lastName
                      }`
                    : user?.username}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider style={{ margin: '0 25px' }} />
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
