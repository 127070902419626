/* eslint-disable */
import { connect } from 'react-redux';
import { fetchTotalAPICountStart } from 'src/store/auth/auth.actions';
import { hasSubscription } from 'src/store/auth/auth.selector';
import {
  fetchApiTokenStart,
  genTokenStart
} from 'src/store/subscription/subscription.actions';

const mapStateToProps = (state) => ({
  subscription: state.subscription,
  hasSubscription: hasSubscription(state),
  totalAPICount: state.auth.totalAPICount
});

const mapDispatchToProps = (dispatch) => ({
  onFetchApiTokenStart: () => dispatch(fetchApiTokenStart()),
  onGenTokenStart: () => dispatch(genTokenStart()),
  onFetchTotalAPICountStart: () => dispatch(fetchTotalAPICountStart())
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
