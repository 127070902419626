import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CodeCard from '../codeCard/codeCard';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  }
}));

const PaginationArgs = () => {
  const classes = useStyles();

  function createData2(name, calories) {
    return { name, calories };
  }

  const rows2 = [
    createData2(
      <>
        release_date -
        <Link href="#orderBy" color="inherit">
          ORDERBY!{' '}
        </Link>
      </>,
      'Sort podcast by release_date either in ascending order or descending order'
    )
  ];

  return (
    <div className={classes.root}>
      <Grid item container id="podcastSortArgs">
        <Grid
          item
          container
          spacing={2}
          direction="column"
          md={7}
          sm={12}
          xs={12}
          style={{ padding: '1rem' }}
        >
          <Grid item>
            <Typography variant="h3" className="panel-title" component="p">
              PodcastSortArgs
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" gutterBottom>
              The arguments for sorting the podcasts
            </Typography>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom color="textSecondary">
                Arguments
              </Typography>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Field Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          {/* <div className="definition-tags">
            <a className="label" href="#tag-types">
              Types
            </a>
          </div>
          <h2 className="panel-title">
            <p>
              <code>PodcastSortArgs</code>
            </p>
          </h2>
          <section className="json-schema-description">
            <p>The arguments for sorting the podcasts</p>
          </section>
          <section className="json-schema-properties-blank">
            <table>
              <tbody>
                <tr>
                  <th>Input Field</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>
                    <code>release_date</code> -
                    <Link to="/documentation#definition-ORDERBY">
                      <code>ORDERBY!</code>
                    </Link>
                  </td>
                  <td> </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section>
            <h5>Example</h5>
            <pre>
              <code className="hljs language-json">
                {'{'}
                <span className="hljs-attr">&quot;release_date&quot;</span>:
                ORDERBY
                {'}'}
                {'\n'}
              </code>
            </pre>
          </section> */}
        </Grid>
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          style={{
            padding: '1rem'
          }}
        >
          <CodeCard header="Example">
            ``` &#123;/n &quot;release_date&quot;: ORDERBY/n&#125; ```
          </CodeCard>

          {/* <section>
            <h5>Example</h5>
            <pre>
              <code className="hljs language-json">
                {'{'}
                <span className="hljs-attr">&quot;release_date&quot;</span>:
                ORDERBY
                {'}'}
                {'\n'}
              </code>
            </pre>
          </section> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default PaginationArgs;
