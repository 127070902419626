/* eslint-disable */

import axios from 'axios';
import { END_POINT } from './constants';

export const getAuthenticatedUser = async () => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        query getAuthenticatedUser{
            getAuthenticatedUser{
              id
              firstName
              lastName
              email
              username
              profilePicUrl
              tenant{
                key
              }
              subscriptions{
                  id
                  status
                  isEffective
                  subscriptionId
                  price
                  subscriptionPlanId
                  unitPrice
                  updateUrl
                  nextBillDate
                  cancelUrl
                  cancellationEffectiveDate
                  paused
              }
          }
        }
        `
    }
  });

  return result;
};
