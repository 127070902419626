/* eslint-disable implicit-arrow-linebreak */
import { connect } from 'react-redux';

import {
  fetchPodcastByIdStart,
  setQueryForEpisodes,
  resetQueryForEpisode
} from 'src/store/podcast/podcast.actions';

import { setPlayerCurrentTrack } from 'src/store/player/player.actions';
import {
  selectEpisodesForSearch,
  selectPodcastForPreview,
  episodeQuery
} from 'src/store/podcast/podcast.selectors';

const mapStateToProps = (state, props) => ({
  isLoading: state.podcast.loading,
  items: state.items,
  podcast: selectPodcastForPreview(props.match.params.id)(state),
  searchQuery: episodeQuery(state),
  episodesQuery: selectEpisodesForSearch(props.match.params.id)(state)
});

const mapDispatchToProps = (dispatch) => ({
  onFetchDataStart: (query, page, size, sortArgs, history) =>
    dispatch(fetchPodcastByIdStart(query, page, size, sortArgs, history)),
  onSetPlayerCurrentTrack: (episode) =>
    dispatch(setPlayerCurrentTrack(episode)),
  onSetQueryForEpisodes: (data) => dispatch(setQueryForEpisodes(data)),
  onResetQueryForEpisodes: () => dispatch(resetQueryForEpisode())
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
