import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Grid,
  CssBaseline,
  Toolbar,
  Typography,
  Link,
  Hidden
} from '@material-ui/core';
import Welcome from './components/operations/Welcome';
import AllGenres from './components/operations/AllGenres';
import SearchEpisodes from './components/operations/searchEpisodes';
import SearchPodcasts from './components/operations/searchPodcasts';
// import AllEpisodesByPodcast from './components/operations/AllEpisodesByPodcast';
// import AllPodcasts from './components/operations/AllPodcasts';
import GetTrendingPodcasts from './components/operations/getTrendingPodcasts';
import Episode from './components/operations/Episode';
import Podcast from './components/operations/Podcast';
// import Types from './components/types/Types';
import TypesEpisode from './components/types/Episode';
import EpisodeFilterArgs from './components/types/EpisodeFilterArgs';
import EpisodeSearchResponse from './components/types/EpisodeSearchResponse';
import GenreType from './components/types/GenreType';
import ListGenreResponse from './components/types/ListGenreResponse';
import PodcastFilterArgs from './components/types/PodcastFilterArgs';
import EpisodeSortArgs from './components/types/EpisodeSortArgs';
// import Id from './components/types/Id';
// import Int from './components/types/Int';
import PodcastSearchResponse from './components/types/PodcastSearchResponse';
import OrderBy from './components/types/OrderBy';
import PaginationArgs from './components/types/PaginationArgs';
import PodcastSortArgs from './components/types/PodcastSortArgs';
import PodcastType from './components/types/PodcastType';
// import String from './components/types/String';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem',
    color: '#777777'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1,
    background: '#FFFCFC',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#E5E5E5',
    overflow: 'hidden'
  }
}));

export default function ClippedDrawer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden smDown>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <Grid container style={{ marginTop: '1rem' }}>
              <Typography variant="h5" className={classes.sideLink}>
                <Link to="#welcome" color="inherit">
                  Welcome
                </Link>
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              direction="column"
              style={{ marginTop: '1rem' }}
            >
              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link to="#allGenres">Operations</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#allGenres" color="inherit">
                    allGenres
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#episode" color="inherit">
                    episode
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#getTrendingPodcasts" color="inherit">
                    getTrendingPodcasts
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#podcast" color="inherit">
                    podcast
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#searchEpisodes" color="inherit">
                    searchEpisodes
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#searchPodcasts" color="inherit">
                    searchPodcasts
                  </Link>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              direction="column"
              style={{ marginTop: '1rem' }}
            >
              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#dateTime">Types</Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#episodeType" color="inherit">
                    Episode
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#episodeFilterArgs" color="inherit">
                    EpisodeFilterArgs
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#episodeSearchResponse" color="inherit">
                    EpisodeSearchResponse
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#episodeSortArgs" color="inherit">
                    EpisodeSortArgs
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#genreType" color="inherit">
                    GenreType
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#listGenreResponse" color="inherit">
                    ListGenreResponse
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#orderBy" color="inherit">
                    ORDERBY
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#paginationArgs" color="inherit">
                    PaginationArgs
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#podcastFilterArgs" color="inherit">
                    PodcastFilterArgs
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#podcastSearchResponse" color="inherit">
                    PodcastSearchResponse
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#podcastSortArgs" color="inherit">
                    PodcastSortArgs
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" className={classes.sideLink}>
                  <Link href="#podcastType" color="inherit">
                    PodcastType
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <Welcome />
        <AllGenres />
        {/* <AllEpisodesByPodcast /> */}
        {/* <AllPodcasts /> */}
        <Episode />
        <GetTrendingPodcasts />
        <Podcast />
        <SearchEpisodes />
        <SearchPodcasts />
        {/* <Types /> */}
        <TypesEpisode />
        <EpisodeFilterArgs />
        <EpisodeSearchResponse />
        <EpisodeSortArgs />
        <GenreType />
        <ListGenreResponse />
        <OrderBy />
        <PaginationArgs />
        <PodcastFilterArgs />
        <PodcastSearchResponse />
        {/* <Id />
        <Int /> */}
        <PodcastSortArgs />
        <PodcastType />
        {/* <String /> */}
      </main>
    </div>
  );
}
