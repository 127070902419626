/* eslint-disable */

import axios from 'axios';

import { END_POINT, MASTER_API_KEY } from './constants';

export const fetchApiCountByDateRange = async ({ startDate, endDate }) => {
  console.log('api received dates>>', startDate, endDate);
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
      query apiCountByDateRange($endDate: Date!, $startDate:Date!){
        apiCountByDateRange(
          endDate: $endDate,
          startDate: $startDate
          )
          {
            count
            date
          }
        }
      `,
      variables: {
        startDate,
        endDate
      }
    }
  });
  return result;
};
