/* eslint-disable */
import { createSelector } from 'reselect';

const authSelector = (state) => state.auth;

export const accessToken = createSelector(
  [authSelector],
  (state) => state.accessToken
);

export const hasSubscription = createSelector([authSelector], (state) => {
  return state.user?.subscriptions.length > 0;
});
