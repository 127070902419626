/* eslint-disable */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Grid,
  Typography,
  Paper,
  Box,
  ButtonBase,
  Button,
  useMediaQuery
} from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import play from 'src/assets/img/icons/play.svg';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import PlayPauseButton from 'src/modules/components/PlayPauseButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 199,
    height: 201,
    margin: '19px 41px 21px 27px',
    borderRadius: '6px'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  textStyle: {
    marginTop: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    width: 820,
    '&:hover': {
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  image: {
    margin: '71px 98px 70px 45px'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  buttonBase: {
    width: '64px',
    height: '64px',
    '&:hover': {
      color: '#4394c1'
    }
  },
  episodeTitle: {
    marginTop: '3px',
    '&:hover': {
      color: '#929292'
    }
  }
}));

const EpisodeCard = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const {
    data,
    history,
    setPlayerEpisode,
    episodePlaying,
    miscState,
    onSetPlayerState,
    audioPlayer
  } = props;

  console.log({ audioPlayer });

  const {
    title,
    description,
    pub_date: pubDate,
    duration_string: duration
  } = data;

  const playerRef = audioPlayer?.current;

  const truncate = (str) => {
    return str.length > 150 ? str.substr(0, 150 - 1) + `...[More]` : str;
  };

  const handleClickView = (collectionId) => {
    history.push(`/details/${collectionId}`);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const playPauseButton = () => {
    if (episodePlaying) {
      if (miscState === 'playing') {
        return (
          <PauseCircleFilledIcon
            color="primary"
            className={classes.buttonBase}
          />
        );
      } else {
        <PlayCircleFilledIcon color="primary" className={classes.buttonBase} />;
      }
    }
    setExpanded(!expanded);
  };
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box mt={2}>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          justify={matches ? 'center' : undefined}
          alignItems={matches ? 'center' : undefined}
        >
          <Grid item>
            <PlayPauseButton
              miscState={miscState}
              player={playerRef}
              episodePlaying={episodePlaying}
              onClick={() => setPlayerEpisode(data)}
            />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid
              item
              xs
              container
              direction="column"
              spacing={2}
              justify={matches ? 'center' : undefined}
              alignItems={matches ? 'center' : undefined}
            >
              <Grid item xs>
                {/* <Button variant="contained" color="primary">
               New
             </Button> */}
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.episodeTitle}
                  align={matches ? 'center' : undefined}
                >
                  {title}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                  className={classes.textStyle}
                  align={matches ? 'center' : undefined}
                >
                  {moment(pubDate).format('MMMM DD, YYYY')}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                  className={classes.textStyle}
                  align={matches ? 'center' : undefined}
                >
                  Duration:
                  {!isNaN(duration)
                    ? new Date(duration * 1000).toISOString().substr(11, 8)
                    : duration}
                </Typography>
              </Grid>

              <Grid item xs>
                <Typography variant="subtitle2" gutterBottom>
                  {expanded ? (
                    <Button
                      onClick={handleExpandClick}
                      style={{ textTransform: 'capitalize' }}
                    >
                      See Less
                    </Button>
                  ) : (
                    <Button
                      onClick={handleExpandClick}
                      style={{ textTransform: 'capitalize' }}
                    >
                      See More
                    </Button>
                  )}{' '}
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Typography>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Typography
                    variant="body2"
                    className={classes.textStyle}
                    dangerouslySetInnerHTML={{
                      __html: description
                    }}
                    align={matches ? 'center' : undefined}
                  />
                </Collapse>
              </Grid>

              {/* <Typography
                  variant="body2"
                  className={classes.textStyle}
                  align={matches ? 'center' : undefined}
                >
                  {truncate(description)}
                </Typography> */}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default EpisodeCard;
