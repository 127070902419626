/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import PlaylistModal from 'src/modules/components/PlaylistModal';

import TimelineController from './components/TimelineController';
import MiniMusicArt from './components/MiniMusicArt';

import container from './Player.container';

const useStyles = makeStyles((theme) => ({
  appBar: {
    bottom: 0,
    display: 'inline-block',
    transition: 'all 0.3s ease',
    [theme.breakpoints.down('xs')]: {
      bottom: '6.7em'
    }
  },
  playerMinimized: {
    transform: 'translateY(calc(100% - 100px))',
    zIndex: 1301,
    backgroundColor: '#076BA3'
  },
  playerMaximized: {
    top: 0,
    bottom: 0,
    zIndex: 1401,
    backgroundColor: '#FFF'
  }
}));

const MainPlayer = ({
  currentEpisode,
  podcast,
  onClearPlayerState,
  // onSetPlayerNextTrack,
  // onSetPlayerPreviousTrack,
  // currentPodcast,
  audioPlayer,
  onSetPlayerState,
  player: { miscState }
}) => {
  const classes = useStyles();

  // there will be 4 states
  // loading, loaded, playing, paused
  const [audioState, setAudioState] = useState(null);
  const [openPlaylist, setOpenPlaylist] = useState(false);

  const openPlaylistModal = () => {
    setOpenPlaylist(true);
  };

  const handleClosePlaylist = () => {
    setOpenPlaylist(false);
  };

  // there will be 3 states
  // maximized, minimized, playlist
  const [playerState, setPlayerState] = useState('minimized');

  const body = document.querySelector('body');

  const player = audioPlayer.current;

  const playAudio = () => {
    audioPlayer.current
      .play()
      .then(() => {
        // Automatic playback started!
        // Show playing UI.
        console.log('audio played auto');
      })
      .catch((error) => {
        // Auto-play was prevented
        // Show paused UI.
        console.error('playback prevented', error);
        setAudioState('paused');
      });
  };

  useEffect(() => {
    if (currentEpisode.link) {
      // console.log("yes its downloaded we will play from local file");
      // maximize the player every time id changes

      setPlayerState('minimized');
      setAudioState('loading');
      audioPlayer.current.src = currentEpisode.link;
      playAudio();
    }
  }, [currentEpisode]);

  if (playerState === 'minimized') {
    // calculate the top height and we are subtracting 148px becz
    // 48 is the value of menu bar and 100px is minimized height
    // make body overflow scroll 😝
    body.style.overflow = 'auto';
  }

  // this will be fired when song is ended
  const songEnded = () => {
    // if repeat is false we will play next else just set the time to 0
    console.log('audio ended!!!');
  };

  const playNext = () => {
    console.log('clicked on playnext button>>>');
    // const obj = currentPodcast.episodes.find(
    //   (o) => o.id === currentEpisode.id + 1
    // )
    //   ? currentPodcast.episodes.find((o) => o.id === currentEpisode.id + 1)
    //   : null;
    // console.log('value of obj playnext', obj);

    // if (obj === null) {
    //   return;
    // }

    // onSetPlayerNextTrack(obj);
    player.currentTime += 30.0;
  };

  const playPrevious = () => {
    // console.log('clicked on previous button>>>');

    // const obj = currentPodcast.episodes.find(
    //   (o) => o.id === currentEpisode.id - 1
    // )
    //   ? currentPodcast.episodes.find((o) => o.id === currentEpisode.id - 1)
    //   : null;
    // if (obj === null) {
    //   return;
    // }
    // onSetPlayerPreviousTrack(obj);
    player.currentTime -= 10.0;
  };

  return (
    <>
      {currentEpisode?.id && (
        <AppBar
          className={clsx(classes.appBar, {
            [classes.playerMinimized]: playerState === 'minimized'
          })}
        >
          <Toolbar disableGutters>
            <Grid container direction="column">
              <TimelineController
                audioState={audioState}
                player={player}
                minimized
              />
              <Grid item container>
                <MiniMusicArt
                  podcast={podcast}
                  currentEpisode={currentEpisode}
                  player={player}
                  onClearPlayerState={onClearPlayerState}
                  audioState={audioState}
                  playPrevious={playPrevious}
                  playNext={playNext}
                  openPlaylistModal={openPlaylistModal}
                  miscState={miscState}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}

      {openPlaylist && (
        <PlaylistModal open={openPlaylist} handleClose={handleClosePlaylist} />
      )}

      <audio
        // onTimeUpdate={timeUpdate}
        onLoadStart={() => {
          setAudioState('loading');
        }}
        id="audio-element"
        // crossOrigin="anonymous"
        onPlay={() => {
          setAudioState('playing');
          onSetPlayerState('playing');
        }}
        onPlaying={() => {
          setAudioState('playing');
          onSetPlayerState('playing');
        }}
        onPause={() => {
          setAudioState('paused');
          onSetPlayerState('paused');
        }}
        onEnded={songEnded}
        autoPlay
        ref={audioPlayer}
        // type="audio/mp4"
      />
    </>
  );
};

MainPlayer.propTypes = {
  currentEpisode: PropTypes.object,
  // currentPodcast: PropTypes.object,
  player: PropTypes.object,
  podcast: PropTypes.object,
  onClearPlayerState: PropTypes.func,
  // onSetPlayerNextTrack: PropTypes.func,
  // onSetPlayerPreviousTrack: PropTypes.func,
  onSetPlayerState: PropTypes.func,
  audioPlayer: PropTypes.any
};

export default container(MainPlayer);
