/* eslint-disable */
import { connect } from 'react-redux';

import { requestPasswordResetStart } from 'src/store/auth/auth.actions';

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  onRequestPasswordResetStart: (email) =>
    dispatch(requestPasswordResetStart(email))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
