import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PublicRoute = ({
  layout: Layout,
  hideOptions,
  component: Component,
  auth: { isAuthenticated, loading },
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (isAuthenticated && !loading) {
          return <Redirect to="/" />;
        }

        return (
          <Layout hideOptions={hideOptions}>
            <Component {...matchProps} />
          </Layout>
        );
      }}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  hideOptions: PropTypes.any,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  tenant: state.tenant
});

export default connect(mapStateToProps)(PublicRoute);
