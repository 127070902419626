/* eslint-disable */

import axios from 'axios';

import { END_POINT } from './constants';

export const fetchAPIUsage = async () => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
          query{
            APIUsage{
              todaysUsage
              remainingAPIUsage
              last7DaysUsage
              totalAPIUsage
            }
          }
            `
    }
  });

  return result;
};
