/* eslint-disable */
import { connect } from 'react-redux';
import {
  fetchAPICountByDateRangeStart,
  fetchTotalAPICountStart
} from 'src/store/auth/auth.actions';

const mapStateToProps = (state) => ({
  error: state.auth.errors,
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  onFetchAPICountByDateRangeStart: (startDate, endDate) =>
    dispatch(fetchAPICountByDateRangeStart(startDate, endDate)),
  onFetchTotalAPICountStart: () => dispatch(fetchTotalAPICountStart())
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
