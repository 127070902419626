/* eslint-disable */
import { createSelector } from 'reselect';
import _ from 'lodash';

const podcastSelector = (state) => state.podcast;

export const podcastQuery = createSelector(
  [podcastSelector],
  (state) => state.query
);

export const episodeQuery = createSelector(
  [podcastSelector],
  (state) => state.episodequery
);

export const podcastCollections = createSelector(
  [podcastSelector],
  (state) => state.podcasts
);

export const selectPodcastForPreview = (podcastID) =>
  createSelector([podcastCollections], (podcast) => {
    return !_.isEmpty(podcast) ? podcast[podcastID] : {};
  });

export const selectEpisodesForSearch = (podcastID) =>
  createSelector(
    [podcastCollections, episodeQuery],
    (podcast, episodeQuery) => {
      const pod = !_.isEmpty(podcast) ? podcast[podcastID] : {};
      return pod && pod.episodes
        ? pod.episodes.filter((episode) =>
            episode.title.toLowerCase().includes(episodeQuery.toLowerCase())
          )
        : {};
    }
  );

export const selectPodcastsForSearch = createSelector(
  [podcastSelector, podcastQuery],
  (podcast, query) =>
    podcast.podcastIDs.filter(
      (id) =>
        podcast.podcasts[id].collection_name
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        podcast.podcasts[id].artist_name
          .toLowerCase()
          .includes(query.toLowerCase())
    )
);
