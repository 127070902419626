import PropTypes from 'prop-types';
// import CustomAlert from 'src/modules/components/alert/CustomAlert';
import CustomAlert from 'src/modules/components/CustomAlert';
import TopBar from './components/TopBar';
import Footer from './components/Footer';

import container from './Landing.container';

const Main = ({ children, ...props }) => {
  return (
    <>
      <TopBar {...props} />
      <CustomAlert />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default container(Main);
