/* eslint-disable  */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import Chip from '@material-ui/core/Chip';

import Paywall from 'src/modules/components/PayWall';
import priceViewer from 'src/assets/img/priceViewer2.svg';
import PriceCard from './components/PriceCard';

import container from './Pricing.container';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    }
  },
  root: {
    minHeight: 375
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    [theme.breakpoints.down('md')]: {
      padding: '1rem'
    }
  },
  goBackContent: {
    padding: theme.spacing(0, 0, 0),
    [theme.breakpoints.down('md')]: {
      padding: '1rem'
    }
  },
  priceCardContent: {
    padding: theme.spacing(8, 0, 0),
    [theme.breakpoints.down('md')]: {
      padding: '1rem'
    }
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  },
  chip: {
    height: '1.188rem',
    backgroundColor: '#0083CC',
    marginBottom: '1.7rem',
    marginLeft: '0.7rem'
  },
  bgHolder: {
    position: 'absolute',
    width: '100%',
    minHeight: '100%',
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    overflow: 'hidden',
    backfaceVisibility: 'hidden',
    backgroundRepeat: 'no-repeat',
    zIndex: -1
  }
}));

const calculatePrice = (plan, requests = 0) => {
  if (plan === 'FREE') {
    return 0;
  }
  if (plan === 'ENTERPRISE') {
    return 3000;
  }

  if (plan === 'PRO') {
    console.log(requests);
    if (requests <= 25000) {
      return 120;
    }
    if (requests < 1000000) {
      return 120 + Math.ceil((requests - 25000) / 1000) * 1.5;
    }
    if (requests < 5000000) {
      return 1095 + Math.ceil((requests - 1000000) / 1000) * 1.2;
    }
    return 5095 + Math.ceil((requests - 5000000) / 1000) * 1;
  }
};

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

function Pricing(props) {
  const classes = useStyles();

  const {
    auth: { isAuthenticated, user },
    history
  } = props;

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [request, setRequest] = useState(25000);

  const [paywallOpened, setPaywallOpened] = useState(false);
  const [billing, setBilling] = useState(0);

  function handleClose() {
    setPaywallOpened(false);
  }

  const handleClickBtn = (index) => {
    console.log('handleClickBtn>>>', index);
    if (isAuthenticated) {
      setBilling(index);
      setPaywallOpened(true);
      return;
    }
    history.push('/login');
  };

  const goBackClickHandler = () => {
    history.push('/');
  };

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Container
        maxWidth="lg"
        component="main"
        className={classes.goBackContent}
      >
        <Grid container>
          <Button onClick={goBackClickHandler}>
            <ArrowBackIosIcon />
            <Typography style={{ textTransform: 'capitalize' }}>
              Go Back
            </Typography>
          </Button>
        </Grid>
      </Container>
      <Container maxWidth="lg" component="main" className={classes.heroContent}>
        <Grid container>
          <Grid item container md direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5">
                Start today. Boost up your services!
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Join 3,000+ developers & designers using Devias to power modern
                web projects.
              </Typography>
            </Grid>
            <Grid item container direction="row" spacing={1}>
              <Grid item style={{ marginTop: '0.8rem' }}>
                <AntSwitch
                  checked={state.checkedC}
                  onChange={handleChange}
                  name="checkedC"
                />
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: '16px' }} color="textSecondary">
                  Yearly Payment
                  <Chip
                    label="25% OFF"
                    clickable
                    className={classes.chip}
                    color="primary"
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify={matches ? 'flex-end' : undefined}
            md={5}
          >
            {/* <Box
              className={classes.bgHolder}
              display={{ xs: 'none', xl: 'block' }}
              style={{
                backgroundImage: `url(${priceViewer})`,
                backgroundPosition: 'right 150px',
                backgroundSize: '50% auto'
              }}
            />
            <Box
              className={classes.bgHolder}
              display={{
                xs: 'none',
                md: 'block',
                lg: 'block',
                xl: 'none'
              }}
              style={{
                backgroundImage: `url(${priceViewer})`,
                backgroundPosition: 'right 100px',
                backgroundSize: '60% auto'
              }}
            />
            <Box
              className={classes.bgHolder}
              display={{ xs: 'none', sm: 'block', md: 'none' }}
              style={{
                backgroundImage: `url(${priceViewer})`,
                backgroundPosition: 'right 150px',
                backgroundSize: '80% auto'
              }}
            />
            <Box
              className={classes.bgHolder}
              display={{ xs: 'block', sm: 'none' }}
              style={{
                backgroundImage: `url(${priceViewer})`,
                backgroundPosition: 'right 100px',
                backgroundSize: '98% auto'
              }}
            /> */}
            <img
              src={priceViewer}
              alt="price viewer"
              style={{ width: '100%', height: '100%' }}
            />
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth="md"
        component="main"
        className={classes.priceCardContent}
      >
        <Grid container spacing={5} alignItems="flex-end">
          <Grid item xs={12} sm={6} md={4}>
            <PriceCard
              title="STARTUP"
              subtitle="For People who are starting out in the business"
              price={0}
              request={2500}
              btnIndex={0}
              planButtonHandler={handleClickBtn}
              user={user}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PriceCard
              title="BASIC"
              subtitle="For experienced who'd like to push
              their limits"
              price={100}
              request={1000000}
              btnIndex={1}
              planButtonHandler={handleClickBtn}
              user={user}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PriceCard
              title="PRO"
              subtitle="For experienced who'd like to push
              their limits"
              price={1000}
              request={5000000}
              btnIndex={2}
              planButtonHandler={handleClickBtn}
              user={user}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <PriceCard
              title="BUSINESS"
              subtitle="For all the professionals who'd like to 
              educate others,too"
              price={calculatePrice('ENTERPRISE')}
            />
          </Grid> */}
        </Grid>
      </Container>
      {isAuthenticated && paywallOpened && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={paywallOpened}
          onClose={handleClose}
        >
          <Paywall billing={billing} user={user} handleClose={handleClose} />
        </Dialog>
      )}
    </>
  );
}

Pricing.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.any
};

export default container(Pricing);
