import * as playerActionTypes from './player.types';

export const setPlayerCurrentTrack = (id) => ({
  type: playerActionTypes.SET_PLAYER_CURRENT_TRACK,
  payload: id
});

export const setPlayerNextTrack = (id) => ({
  type: playerActionTypes.SET_PLAYER_NEXT_TRACK,
  payload: id
});

export const setPlayerPreviousTrack = (id) => ({
  type: playerActionTypes.SET_PLAYER_PREVIOUS_TRACK,
  payload: id
});

export const togglePlayPause = (value) => ({
  type: playerActionTypes.TOGGLE_PLAY_PAUSE,
  payload: { value }
});

export const setPlayerState = (value) => ({
  type: playerActionTypes.SET_PLAYER_STATE,
  payload: value
});

export const clearPlayerState = () => ({
  type: playerActionTypes.CLEAR_PLAYER_STATE
});

export const setPlayerCount = (collectionId) => ({
  type: playerActionTypes.SET_PLAYER_COUNT,
  payload: { collectionId }
});
