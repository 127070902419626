/*eslint-disable*/
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import DetailsCard from 'src/modules/views/ModalDetailsCard';
import ShowMore from 'src/modules/components/ShowMore';
import EpisodeCard from 'src/modules/views/EpisodeDataCard';
import doubleDownArrow from 'src/assets/img/icons/doubleDownArrow.svg';
import { setPlayerCurrentTrack } from 'src/store/player/player.actions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PlaylistModal = (props) => {
  const {
    open,
    handleClose,
    podcast,
    isLoading,
    onSetPlayerCurrentTrack,
    searchQuery,
    episodesQuery
  } = props;
  const classes = useStyles();
  const [more, setMore] = useState(5);

  let episodes = [];
  if (searchQuery) {
    episodes = episodesQuery;
  } else {
    episodes = podcast ? podcast.episodes : [];
  }

  const seeMoreHandler = () => {
    setMore(more + 5);
  };

  let emptyContents;
  if (isLoading && episodes && episodes.length === 0) {
    emptyContents = (
      <Grid container justify="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  } else if (!isLoading && episodes && episodes.length === 0) {
    emptyContents = (
      <Grid container justify="center">
        <Grid item xs={5}>
          <Alert variant="outlined" severity="error">
            No Result Found
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div style={{ minHeight: '100vh' }}>
        <Grid
          container
          style={{ position: 'fixed', padding: '1rem' }}
          justify="flex-end"
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        {/* <TabContent /> */}
        {isLoading || !podcast ? (
          <Grid container justify="center" alignContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <Container maxWidth="md" style={{ marginTop: '3rem' }}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <DetailsCard data={podcast} key={podcast.id} />
                <Typography
                  variant="h4"
                  align="center"
                  style={{ padding: '4rem 0 2rem' }}
                >
                  All Episodes
                </Typography>
                <Grid
                  container
                  justify="center"
                  style={{ marginBottom: '3.5rem' }}
                >
                  <img src={doubleDownArrow} alt="doubleArrow" />
                </Grid>

                {episodes && episodes.length
                  ? episodes
                      .slice(0, more)
                      .map((episode) => (
                        <EpisodeCard
                          data={episode}
                          key={episode.guid}
                          setPlayerEpisode={onSetPlayerCurrentTrack}
                        />
                      ))
                  : emptyContents}
                {!isLoading && episodes && episodes.length > more && (
                  <ShowMore nextList={seeMoreHandler} />
                )}
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </Dialog>
  );
};

PlaylistModal.propTypes = {
  open: PropTypes.any,
  podcast: PropTypes.object,
  isLoading: PropTypes.any,
  onSetPlayerCurrentTrack: PropTypes.func,
  searchQuery: PropTypes.any,
  episodesQuery: PropTypes.any,
  handleClose: PropTypes.func
};

const mapStateToProps = (state) => ({
  isLoading: state.podcast.loading,
  items: state.items,
  podcast: state.podcast.currentPodcast
});

const mapDispatchToProps = (dispatch) => ({
  onSetPlayerCurrentTrack: (episode) => dispatch(setPlayerCurrentTrack(episode))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistModal);
