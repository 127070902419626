/* eslint-disable */
import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  CardActions,
  Divider
} from '@material-ui/core';

import checkMark from 'src/assets/img/icons/checkMark.svg';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  },
  buttonStyle: {
    borderRadius: 999,
    textTransform: 'capitalize'
  },
  dividerStyle: {
    backgroundColor: '#0083CC',
    margin: '0 86px'
  },
  imgStyle: {
    paddingRight: '14px'
  },
  priceOption: {
    display: 'flex',
    alignItems: 'baseline',
    paddingLeft: '30px',
    marginBottom: theme.spacing(2)
  },
  subtitleText: {
    margin: '0 15px'
  },
  cardActionStyle: {
    paddingBottom: '2.9rem'
  },
  cardMain: {
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25);'
  },
  inputBase: {
    width: '40%',
    border: '1px solid #D3D3D3',
    backgroundColor: '#F0F0F0',
    borderRadius: '6px',
    padding: '0 7px'
  }
}));

const PriceCard = ({
  title,
  subtitle,
  price,
  planButtonHandler,
  btnIndex,
  request,
  setRequest,
  user
}) => {
  const classes = useStyles();

  const requestHandler = () => {
    if (request) {
      return (
        <Typography component="div" variant="subtitle1">
          {request} Requests allowed
        </Typography>
      );
    } else {
      return (
        <Typography component="div" variant="subtitle1">
          Unlimited Request Free
        </Typography>
      );
    }
  };

  return (
    <Card className={classes.cardMain}>
      <CardHeader
        title={title}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        style={{ paddingTop: '1.87rem' }}
      />
      <Divider className={classes.dividerStyle} />
      <CardContent>
        <div className={classes.cardPricing}>
          <Typography
            variant="h6"
            align="center"
            className={classes.subtitleText}
          >
            {subtitle}
          </Typography>
        </div>
        <div className={classes.cardPricing}>
          <Typography component="p" variant="body1">
            <sup>$</sup>
          </Typography>
          <Typography component="h2" variant="h3" style={{ color: '#333333' }}>
            {price}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            /month
          </Typography>
        </div>
        <div className={classes.priceOption} style={{ marginTop: '3.2rem' }}>
          <img src={checkMark} alt="check mark" className={classes.imgStyle} />

          <Typography component="div" variant="subtitle1">
            Free API
          </Typography>
        </div>
        <div className={classes.priceOption}>
          <img src={checkMark} alt="check mark" className={classes.imgStyle} />
          {requestHandler(title)}
        </div>
        <div className={classes.priceOption}>
          <img src={checkMark} alt="check mark" className={classes.imgStyle} />

          <Typography component="div" variant="subtitle1">
            Free access to forums
          </Typography>
        </div>
      </CardContent>
      <CardActions style={{ paddingBottom: '2.9rem' }}>
        <Button
          fullWidth
          variant="outlined"
          className={classes.buttonStyle}
          onClick={() => planButtonHandler(btnIndex)}
          disabled={!_.isEmpty(user?.subscriptions)}
        >
          Choose Plan
        </Button>
      </CardActions>
    </Card>
  );
};

export default PriceCard;
