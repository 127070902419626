import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CodeCard from '../codeCard/codeCard';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  }
}));

const PodcastSearchResponse = () => {
  const classes = useStyles();

  function createData2(name, calories) {
    return { name, calories };
  }

  const rows2 = [
    createData2(
      <>
        results -
        <Link href="#podcastType" color="inherit">
          [PodcastType]!{' '}
        </Link>
      </>,
      'The list of podcasts'
    ),
    createData2('total -Int!', 'The total number of podcasts returned')
  ];

  return (
    <div className={classes.root}>
      <Grid item container id="podcastSearchResponse">
        <Grid
          item
          container
          direction="column"
          spacing={2}
          md={7}
          sm={12}
          xs={12}
          style={{ padding: '1rem' }}
        >
          <Grid item>
            <Typography variant="h3" className="panel-title" component="p">
              PodcastSearchResponse
            </Typography>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom>
                Returns the list of podcasts and total number of episodes
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" gutterBottom color="textSecondary">
                Arguments
              </Typography>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Field Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* <div className="definition-tags">
            <a className="label" href="#tag-types">
              Types
            </a>
          </div>
          <h2 className="panel-title">
            <p>
              <code>PaginationArgs</code>
            </p>
          </h2>

          <section className="json-schema-description">
            <p>The arguments for pagination</p>
          </section>
          <section className="json-schema-properties-blank">
            <table>
              <tbody>
                <tr>
                  <th>Input Field</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>
                    <code>limit</code> -
                    <Link to="/documentation#definition-Int">
                      <code>Int</code>
                    </Link>{' '}
                    default = <code>10</code>{' '}
                  </td>
                  <td>
                    <p>Specifies the page size in a query</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>offset</code> -
                    <Link to="/documentation#definition-Int">
                      <code>Int</code>
                    </Link>{' '}
                    default = <code>0</code>{' '}
                  </td>
                  <td>
                    <p>specifies where to start the page</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </section> */}
        </Grid>
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          style={{
            padding: '1rem'
          }}
        >
          <CodeCard header="Example">
            ``` &#123;/n &quot;results &quot;:[PodcastType]/n &quot;total&quot;:
            15 /n&#125; ```
          </CodeCard>

          {/* <section>
            <h5>Example</h5>
            <pre>
              <code className="hljs language-json">
                {'{'}
                <span className="hljs-attr">&quot;limit&quot;</span>:{' '}
                <span className="hljs-number">10</span>,{' '}
                <span className="hljs-attr">&quot;offset&quot;</span>:{' '}
                <span className="hljs-number">0</span>
                {'}'}
                {'\n'}
              </code>
            </pre>
          </section> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default PodcastSearchResponse;
