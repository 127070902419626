/* eslint-disable */
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  nextBtn: {
    color: '#ffffff',
    width: 300,
    fontWeight: 400,
    fontFamily: 'Arial',
    textTransform: 'none',
    marginTop: 50,
    paddingTop: 10,
    marginBottom: 10,
    '&:hover': {
      backgroundColor: 'rgb(2 79 142);'
    }
  }
}));

const ShowMore = (props) => {
  const classes = useStyles();
  const { nextList } = props;

  return (
    <Grid item container justify="center">
      <Button
        variant="contained"
        disableElevation
        className={classes.nextBtn}
        color="primary"
        onClick={nextList}
      >
        Show More
      </Button>
    </Grid>
  );
};

export default ShowMore;
