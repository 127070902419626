import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  IconButton,
  Collapse
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import CodeCard from '../codeCard/codeCard';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  }
}));

function createData(name, calories) {
  return { name, calories };
}

const rows = [
  createData('artist_name -String!', 'The name of the artist'),
  createData(
    'artist_view_url -String',
    'The url for the content associated with the artist'
  ),
  createData(
    'artwork_url_30 -String',
    'A URL for the artwork associated with the returned media type, sized to 30×30 pixels.'
  ),
  createData(
    'artwork_url_60 -String',
    'A URL for the artwork associated with the returned media type, sized to 60×60 pixels.'
  ),
  createData(
    'artwork_url_100 -String',
    'A URL for the artwork associated with the returned media type, sized to 100×100 pixels.'
  ),
  createData(
    'artwork_url_512 -String',
    'A URL for the artwork associated with the returned media type, sized to 512×512 pixels.'
  ),
  createData(
    'artwork_url_600 -String',
    'A URL for the artwork associated with the returned media type, sized to 600×600 pixels.'
  ),
  createData('collection_id -Int!', 'Id of the podcast collection'),
  createData(
    'collection_view_url -String',
    'The url for the content associated with the collection'
  ),
  createData('country -String!', 'The name of the country. Eg: ‘USA'),
  createData('description -String', 'The description of the podcasts'),
  createData('episode_count -Int', 'The count of the total number of episodes'),
  createData('feed_url -String', 'The url of the RSS feed'),
  createData('id -Int!', 'long_description -String'),
  createData('release_date -String', 'The release date of the podcast'),
  createData(
    'short_description -String',
    'The short description of the episodes'
  ),
  createData('track_count -Int!', 'The count of the total number of tracks'),
  createData(
    <>
      episodes - [
      <Link href="#episodeType" color="inherit">
        Episode
      </Link>
      ]!
    </>,
    'The list of the episodes (by default 10) sorted by latest date of a specific podcast'
  )
];

function createData2(name, calories, fields) {
  return { name, calories, fields };
}

const rows2 = [
  createData2(
    <>
      episodes - [
      <Link href="#episodeType" color="inherit">
        Episode
      </Link>
      ]!
    </>,
    'The list of the episodes (by default 10) sorted by latest date of a specific podcast',
    [
      createData(
        <>
          sort -{' '}
          <Link href="#episodeSortArgs" color="inherit">
            EpisodeSortArgs
          </Link>
        </>,
        'Sort episodes by published date either ascending or descending order'
      ),
      createData(
        <>
          pagination -
          <Link href="#paginationArgs" color="inherit">
            PaginationArgs{' '}
          </Link>
        </>,
        'The arguments for limit and offset based pagination'
      )
    ]
  )
];

function CollapsibleRow(props) {
  const classes = useStyles();
  const { collapsibleRow } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </>
          {collapsibleRow.name}
        </TableCell>
        <TableCell align="left">{collapsibleRow.calories}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0.5, paddingTop: 0.5 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid item>
              <Typography variant="body1" gutterBottom>
                Fields
              </Typography>
            </Grid>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {collapsibleRow.fields.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.calories}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

CollapsibleRow.propTypes = {
  collapsibleRow: PropTypes.shape({
    name: PropTypes.string.isRequired,
    calories: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        calories: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};
const PodcastType = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item container id="podcastType">
        <Grid
          item
          container
          spacing={2}
          direction="column"
          md={7}
          sm={12}
          xs={12}
          style={{ padding: '1rem' }}
        >
          <Grid item>
            <Typography variant="h3" className="panel-title" component="p">
              PodcastType
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" gutterBottom>
              The metadata related to the podcast
            </Typography>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Field Name</TableCell>
                    <TableCell align="left">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.calories}</TableCell>
                    </TableRow>
                  ))}
                  {rows2.map((row) => (
                    <CollapsibleRow key={row.name} collapsibleRow={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* <Grid item>
            <Typography variant="body1" gutterBottom>
              Fields
            </Typography>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {rows2.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.calories}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid> */}
          {/* <section className="json-schema-properties-blank">
            <table>
              <tbody>
                <tr>
                  <th>Field Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td data-property-name="artist_name">
                    {' '}
                    <code>artist_name</code> -
                    <Link to="/documentation#definition-String">
                      <code>String!</code>
                    </Link>
                  </td>
                  <td>
                    <p>The name of the artist</p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="artist_view_url">
                    {' '}
                    <code>artist_view_url</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td>
                    <p>The url for the content associated with the artist</p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="artwork_url_30">
                    {' '}
                    <code>artwork_url_30</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td>
                    <p>
                      A URL for the artwork associated with the returned media
                      type, sized to 30×30 pixels.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="artwork_url_60">
                    {' '}
                    <code>artwork_url_60</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td>
                    <p>
                      A URL for the artwork associated with the returned media
                      type, sized to 60×60 pixels.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="artwork_url_100">
                    {' '}
                    <code>artwork_url_100</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td>
                    <p>
                      A URL for the artwork associated with the returned media
                      type, sized to 100×100 pixels.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="artwork_url_512">
                    {' '}
                    <code>artwork_url_512</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td>
                    <p>
                      A URL for the artwork associated with the returned media
                      type, sized to 512×512 pixels.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="artwork_url_600">
                    {' '}
                    <code>artwork_url_600</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td>
                    <p>
                      A URL for the artwork associated with the returned media
                      type, sized to 600×600 pixels.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="collection_id">
                    {' '}
                    <code>collection_id</code> -
                    <Link to="/documentation#definition-Int">
                      <code>Int!</code>
                    </Link>
                  </td>
                  <td>
                    <p>Id of the podcast collection</p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="collection_name">
                    {' '}
                    <code>collection_name</code> -
                    <Link to="/documentation#definition-String">
                      <code>String!</code>
                    </Link>
                  </td>
                  <td>
                    <p>The name of the podcast collection</p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="collection_view_url">
                    {' '}
                    <code>collection_view_url</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td>
                    <p>
                      The url for the content associated with the collection
                    </p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="country">
                    {' '}
                    <code>country</code> -
                    <Link to="/documentation#definition-String">
                      <code>String!</code>
                    </Link>
                  </td>
                  <td>
                    <p>The name of the country. Eg: ‘USA</p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="description">
                    {' '}
                    <code>description</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td data-property-name="episode_count">
                    {' '}
                    <code>episode_count</code> -
                    <Link to="/documentation#definition-Int">
                      <code>Int</code>
                    </Link>
                  </td>
                  <td>
                    <p>The count of the total number of episodes</p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="episodes">
                    {' '}
                    <code>episodes</code> -
                    <Link to="/documentation#definition-Episode">
                      <code>[Episode]!</code>
                    </Link>
                  </td>
                  <td>
                    <p>
                      The list of the episodes (by default 10) sorted by latest
                      date of a specific podcast
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="no-top-border">
                    <div className="field-arguments">
                      <p className="field-arguments-header"> Arguments </p>
                      <div className="field-argument">
                        <p className="field-argument-name">
                          <code>pagination</code> -
                          <Link to="/documentation#definition-PaginationArgs">
                            <code>PaginationArgs</code>
                          </Link>
                        </p>
                        <p>
                          The arguments for limit and offset based pagination
                        </p>
                        <p />
                      </div>
                      <div className="field-argument">
                        <p className="field-argument-name">
                          <code>sort</code> -
                          <Link to="/documentation#definition-EpisodeSortArgs">
                            <code>EpisodeSortArgs</code>
                          </Link>
                        </p>
                        <p>The arguments for sorting the episodes</p>
                        <p />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="feed_url">
                    {' '}
                    <code>feed_url</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td>
                    <p>The url of the RSS feed</p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="id">
                    {' '}
                    <code>id</code> -
                    <Link to="/documentation#definition-Int">
                      <code>Int!</code>
                    </Link>
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td data-property-name="long_description">
                    {' '}
                    <code>long_description</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td data-property-name="release_date">
                    {' '}
                    <code>release_date</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td>
                    <p>The release date of the podcast</p>
                  </td>
                </tr>
                <tr>
                  <td data-property-name="short_description">
                    {' '}
                    <code>short_description</code> -
                    <Link to="/documentation#definition-String">
                      <code>String</code>
                    </Link>
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td data-property-name="track_count">
                    {' '}
                    <code>track_count</code> -
                    <Link to="/documentation#definition-Int">
                      <code>Int!</code>
                    </Link>
                  </td>
                  <td>
                    <p>The count of the total number of tracks</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </section> */}
        </Grid>
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          style={{
            padding: '1rem'
          }}
        >
          <CodeCard header="Example">
            ``` &#123;/n &quot;release_date&quot;:
            &quot;2022-02-10T21:23:00Z&quot;,/n &quot;artist_name&quot;:
            &quot;Hello, World!&quot;,/n &quot;artist_view_url&quot;:
            &quot;https://podcasts.apple.com/us/artist/esradio/331317693?uo=4&quot;,/n
            &quot;artwork_url_30&quot;:
            &quot;https://is1-ssl.mzstatic.com/image/thumb/Podcasts115/v4/8b/64/c6/8b64c627-2726-57b3-de64-9696263345b2/mza_8897366698037682470.jpg/30x30bb.jpg&quot;,/n
            &quot;artwork_url_60&quot;:
            &quot;https://is1-ssl.mzstatic.com/image/thumb/Podcasts115/v4/8b/64/c6/8b64c627-2726-57b3-de64-9696263345b2/mza_8897366698037682470.jpg/60x60bb.jpg&quot;,/n
            &quot;artwork_url_100&quot;:
            &quot;https://is1-ssl.mzstatic.com/image/thumb/Podcasts115/v4/8b/64/c6/8b64c627-2726-57b3-de64-9696263345b2/mza_8897366698037682470.jpg/100x100bb.jpg&quot;,/n
            &quot;artwork_url_512&quot;:
            &quot;https://is1-ssl.mzstatic.com/image/thumb/Podcasts115/v4/8b/64/c6/8b64c627-2726-57b3-de64-9696263345b2/mza_8897366698037682470.jpg/512x512bb.jpg&quot;,/n
            &quot;artwork_url_600&quot;:
            &quot;https://is1-ssl.mzstatic.com/image/thumb/Podcasts115/v4/8b/64/c6/8b64c627-2726-57b3-de64-9696263345b2/mza_8897366698037682470.jpg/600x600bb.jpg&quot;,/n
            &quot;collection_id&quot;: 1361748615,/n
            &quot;collection_name&quot;: &quot;Hello, World! Podcast&quot;,/n
            &quot;collection_view_url&quot;:
            &quot;https://podcasts.apple.com/us/podcast/2-beans-1-bowl/id1490068743?uo=4&quot;,/n
            &quot;country&quot;: &quot;USA&quot;,/n
            &quot;description&quot;:&quot;Life is hard so why not smoke some
            weed? Every Monday, Join Manny and Joel as they interview and joke
            around with guest that they find cool, interesting, and/or
            funny.&quot;,/n &quot;episode_count&quot;: 67,/n
            &quot;episodes&quot;: [Episode],/n &quot;feed_url&quot;:
            &quot;https://pinecast.com/feed/2-beans-1-bowl&quot;,/n
            &quot;id&quot;: 123,/n &quot;long_description&quot;: &quot;Life is
            hard so why not smoke some weed? Every Monday, Join Manny and Joel
            as they interview and joke around with guest that they find cool,
            interesting, and/or funny.&quot;,/n &quot;short_description&quot;:
            &quot;Life is hard so why not smoke some weed? Every Monday, Join
            Manny and Joel as they interview and joke around with guest that
            they find cool, interesting, and/or funny.&quot;,/n
            &quot;track_count&quot;: 123 /n &#125; ```
          </CodeCard>

          {/* <section>
            <h5>Example</h5>
            <pre>
              <code className="hljs language-json">
                {'{'}
                {'\n'}
                {'  '}
                <span className="hljs-attr">&quot;artist_name&quot;</span>:{' '}
                <span className="hljs-string">&quot;xyz789&quot;</span>,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;artist_view_url&quot;
                </span>: <span className="hljs-string">&quot;abc123&quot;</span>
                ,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;artwork_url_30&quot;
                </span>: <span className="hljs-string">&quot;abc123&quot;</span>
                ,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;artwork_url_60&quot;
                </span>: <span className="hljs-string">&quot;xyz789&quot;</span>
                ,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;artwork_url_100&quot;
                </span>: <span className="hljs-string">&quot;xyz789&quot;</span>
                ,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;artwork_url_512&quot;
                </span>: <span className="hljs-string">&quot;abc123&quot;</span>
                ,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;artwork_url_600&quot;
                </span>: <span className="hljs-string">&quot;xyz789&quot;</span>
                ,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;collection_id&quot;
                </span>: <span className="hljs-number">123</span>,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;collection_name&quot;
                </span>: <span className="hljs-string">&quot;abc123&quot;</span>
                ,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;collection_view_url&quot;
                </span>
                : <span className="hljs-string">&quot;abc123&quot;</span>,{'\n'}
                {'  '}
                <span className="hljs-attr">&quot;country&quot;</span>:{' '}
                <span className="hljs-string">&quot;xyz789&quot;</span>,{'\n'}
                {'  '}
                <span className="hljs-attr">&quot;description&quot;</span>:{' '}
                <span className="hljs-string">&quot;xyz789&quot;</span>,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;episode_count&quot;
                </span>: <span className="hljs-number">123</span>,{'\n'}
                {'  '}
                <span className="hljs-attr">&quot;episodes&quot;</span>:
                [Episode],
                {'\n'}
                {'  '}
                <span className="hljs-attr">&quot;feed_url&quot;</span>:{' '}
                <span className="hljs-string">&quot;xyz789&quot;</span>,{'\n'}
                {'  '}
                <span className="hljs-attr">&quot;id&quot;</span>:{' '}
                <span className="hljs-number">123</span>,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;long_description&quot;
                </span>: <span className="hljs-string">&quot;xyz789&quot;</span>
                ,{'\n'}
                {'  '}
                <span className="hljs-attr">
                  &quot;release_date&quot;
                </span>: <span className="hljs-string">&quot;xyz789&quot;</span>
                ,{'\n'}
                {'  '}
                <span className="hljs-attr">&quot;short_description&quot;</span>
                : <span className="hljs-string">&quot;xyz789&quot;</span>,{'\n'}
                {'  '}
                <span className="hljs-attr">&quot;track_count&quot;</span>:{' '}
                <span className="hljs-number">123</span>
                {'\n'}
                {'}'}
                {'\n'}
              </code>
            </pre>
          </section> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default PodcastType;
