import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CodeCard from '../codeCard/codeCard';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  }
}));

function createData(name, calories) {
  return { name, calories };
}

const rows = [
  createData('artist_name -String!', 'The name of the artist'),
  createData(
    'artwork_url_100 -String',
    'A URL for the artwork associated with the returned media type, sized to 100×100 pixels.'
  ),
  createData('collection_id -Int!', 'Id of the podcast collection'),
  createData('collection_name -String!', 'The name of the podcast collection'),

  createData('description -String', 'The description of the episodes'),
  createData('duration -String', 'The time duration of the episode'),
  createData(
    'duration_string -String',
    'The time duration of the episode in string'
  ),
  createData('guid -String', 'Unique identifier of the episodes'),
  createData('id -Int', 'episode identifier'),
  createData('link -String', 'The audio link for playing the episodes'),
  createData('pub_date -String', 'The date when the episode was published'),
  createData('title -String', 'The title of the episode')
];

const Episode = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item container id="episodeType">
        <Grid
          item
          container
          direction="column"
          spacing={2}
          md={7}
          sm={12}
          xs={12}
          style={{ padding: '1rem' }}
        >
          <Grid item>
            <Typography variant="h2" className="doc-title">
              Types
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h3" className="panel-title" component="p">
              Episode
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" gutterBottom>
              The episode of a podcast
            </Typography>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Field Name</TableCell>
                    <TableCell align="left">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.calories}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* <div className="definition-tags">
            <a className="label" href="#tag-types">
              Types
            </a>
          </div>
          <h2 className="panel-title">
            <p>
              <code>Episode</code>
            </p>
          </h2> */}
          {/* <div className="doc-copy">
            <section className="json-schema-description">
              <p>The episode of a podcast</p>
            </section>
            <section className="json-schema-properties-blank">
              <table>
                <tbody>
                  <tr>
                    <th>Field Name</th>
                    <th>Description</th>
                  </tr>
                  <tr>
                    <td data-property-name="description">
                      {' '}
                      <code>description</code> -
                      <Link to="/documentation#definition-String">
                        <code>String</code>
                      </Link>
                    </td>
                    <td>
                      <p>The description of the episode</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="duration">
                      {' '}
                      <code>duration</code> -
                      <Link to="/documentation#definition-String">
                        <code>String</code>
                      </Link>
                    </td>
                    <td>
                      <p>The time duration of the episode</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="guid">
                      {' '}
                      <code>guid</code> -
                      <Link to="/documentation#definition-String">
                        <code>String!</code>
                      </Link>
                    </td>
                    <td>
                      <p>Unique identifier of the episodes</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="id">
                      {' '}
                      <code>id</code> -
                      <Link to="/documentation#definition-Int">
                        <code>Int!</code>
                      </Link>
                    </td>
                    <td>
                      <p>episode identifier</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="link">
                      {' '}
                      <code>link</code> -
                      <Link to="/documentation#definition-String">
                        <code>String!</code>
                      </Link>
                    </td>
                    <td>
                      <p>The audio link for playing the episodes</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="pub_date">
                      {' '}
                      <code>pub_date</code> -
                      <Link to="/documentation#definition-DateTime">
                        <code>DateTime!</code>
                      </Link>
                    </td>
                    <td>
                      <p>The date and time when the episode was published</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="title">
                      {' '}
                      <code>title</code> -
                      <Link to="/documentation#definition-String">
                        <code>String!</code>
                      </Link>
                    </td>
                    <td>
                      <p>The title of the episode</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div> */}
        </Grid>
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          style={{
            padding: '1rem'
          }}
        >
          <CodeCard header="Example">
            ``` &#123;/n &quot;id&quot;: 123,/n &quot;title&quot;: &quot;Hello
            World Thurs&quot;,/n &quot;guid&quot;:
            &quot;d7572f57-fc45-4af5-b231-ae38016061a0&quot;,/n
            &quot;description&quot;: &quot;Hello World! Dr. Greg Patten has
            another episode of News and Comment for you today!&quot;,/n
            &quot;pub_date&quot;: &quot;2022-02-10T21:23:51.000Z&quot;,/n
            &quot;duration&quot;: &quot;23581730&quot;,/n
            &quot;duration_string&quot;: &quot;1471&quot;,/n &quot;link&quot;:
            &quot;https://traffic.omny.fm/d/clips/fc84eef1-ccd0-4705-b300-a7ee0013cfb8/095c05ff-7804-4c45-b656-a8a90141ac88/d7572f57-fc45-4af5-b231-ae38016061a0/audio.mp3?utm_source=Podcast&in_playlist=d07663cd-4a38-4d39-bee5-a8a90142141a&quot;,/n
            &quot;collection_id&quot;: 1361748615,/n
            &quot;collection_name&quot;:&quot;Hello, World! Podcast&quot;, /n
            &quot;artist_name&quot;:&quot;Hello, World!&quot;, /n
            &quot;artwork_url_100&quot;:
            &quot;https://is1-ssl.mzstatic.com/image/thumb/Podcasts115/v4/8b/64/c6/8b64c627-2726-57b3-de64-9696263345b2/mza_8897366698037682470.jpg/100x100bb.jpg&quot;
            /n &#125; ```
          </CodeCard>
          {/* <h5>Example</h5>
          <pre>
            <code className="hljs language-json">
              {'{'}
              {'\n'}
              {'  '}
              <span className="hljs-attr">&quot;description&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;Hey everybody, this is February&apos;s top10, a bit late,
                but to make up for that, I&apos;m joined by the newest
                contributor to the channel, Tim Chuon!&quot;
              </span>
              ,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;duration&quot;</span>:{' '}
              <span className="hljs-number">6791</span>,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;guid&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;724b5f20-1dc2-495e-a0b9-2d1610959fdf&quot;
              </span>
              ,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;id&quot;</span>:{' '}
              <span className="hljs-number">1</span>,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;link&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;https://anchor.fm/rahdo/episodes/Top-10-Combat-Systems-ersuua&quot;
              </span>
              ,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;pub_date&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;2019-12-03T09:54:33Z&quot;
              </span>
              ,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;title&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;Top 10 Combat Systems&quot;
              </span>
              {'\n'}
              {'}'}
              {'\n'}
            </code>
          </pre> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Episode;
