/* eslint-disable */
import { takeLatest, call, all } from 'redux-saga/effects';
import setPlayerCount from 'src/api/setPlayerCount';

import * as actionTypes from './player.types';
// import * as actions from './player.actions';

export function* setPlayerCurrentTrackAsync({ payload }) {
  console.log('episode >>>', payload);
  const { collection_id: collectionId } = payload;
  yield call(setPlayerCount, { collectionId });
}

export function* watchSetPlayerCurrentTrack() {
  yield takeLatest(
    actionTypes.SET_PLAYER_CURRENT_TRACK,
    setPlayerCurrentTrackAsync
  );
}

export function* playerSagas() {
  yield all([call(watchSetPlayerCurrentTrack)]);
}
