/* eslint-disable*/
import * as actionType from './podcast.types';

export const fetchPodcastByIdStart = (
  query,
  page,
  size,
  sortArgs,
  history
) => ({
  type: actionType.FETCH_PODCAST_BY_ID_START,
  payload: { query, page, size, sortArgs, history }
});

export const fetchPodcastByIdSuccess = (data) => ({
  type: actionType.FETCH_PODCAST_BY_ID_SUCCESS,
  payload: data
});

export const fetchPodcastByIdFail = (error) => ({
  type: actionType.FETCH_PODCAST_BY_ID_FAIL,
  payload: { error }
});

export const searchPodcastStart = (page, size, search, filter, sort) => ({
  type: actionType.SEARCH_ALL_PODCASTS_START,
  payload: { page, size, search, filter, sort }
});

export const searchPodcastSuccess = (data) => ({
  type: actionType.SEARCH_ALL_PODCASTS_SUCCESS,
  payload: data
});

export const searchPodcastFail = (error) => ({
  type: actionType.SEARCH_ALL_PODCASTS_FAIL,
  payload: { error }
});

export const searchEpisodeStart = (page, size, search, filter, sort) => ({
  type: actionType.SEARCH_ALL_EPISODES_START,
  payload: { page, size, search, filter, sort }
});

export const searchEpisodeSuccess = (data) => ({
  type: actionType.SEARCH_ALL_EPISODES_SUCCESS,
  payload: data
});

export const searchEpisodeFail = (error) => ({
  type: actionType.SEARCH_ALL_EPISODES_FAIL,
  payload: { error }
});

export const setCurrentPodcast = (data) => ({
  type: actionType.SET_CURRENT_PODCAST,
  payload: data
});

export const setQuery = (data) => ({
  type: actionType.SET_QUERY,
  payload: data
});

export const setQueryForEpisodes = (data) => ({
  type: actionType.SET_QUERY_FOR_EPISODES,
  payload: data
});

export const resetQueryForPodcast = () => ({
  type: actionType.RESET_QUERY_FOR_PODCAST
});

export const resetQueryForEpisode = () => ({
  type: actionType.RESET_QUERY_FOR_EPISODE
});

export const getTrendingPodcastsStart = (limit, offset) => ({
  type: actionType.GET_TRENDING_PODCASTS_START,
  payload: { limit, offset }
});

export const getTrendingPodcastsSuccess = (data) => ({
  type: actionType.GET_TRENDING_PODCASTS_SUCCESS,
  payload: data
});

export const getTrendingPOdcastsFail = (error) => ({
  type: actionType.GET_TRENDING_PODCASTS_FAIL,
  payload: { error }
});
