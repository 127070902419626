import * as actionType from './alert.types';

export const openAlert = (message, severity) => ({
  type: actionType.OPEN_ALERT,
  payload: { message, severity }
});

export const closeAlert = () => ({
  type: actionType.CLOSE_ALERT
});
