/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withRouter } from 'react-router';

import Link from '@material-ui/core/Link';

import { fetchAutocompleteSuggestionsPodcastStart } from 'src/store/search/search.actions';

const qs = require('qs');

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #999999',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    height: '2.813rem'
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1
  },
  iconButton: {
    padding: 5,
    color: '#ffffff',
    borderRadius: '5px',
    backgroundColor: '#0083CC',
    '&:hover': {
      backgroundColor: '#0083CC'
    }
  },
  divider: {
    height: 70
  },
  noBorder: {
    border: 'none'
  }
}));

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    <Typography variant="h6">{params.group}</Typography>
  </ListSubheader>,
  params.children
];

const CustomizedInputBase = (props) => {
  const classes = useStyles();

  const {
    placeholder,
    search: { autoCompleteSuggestions, loading },
    history,
    query,
    onGetPodcastAutocompleteSuggestionStart
  } = props;

  const urlQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true
  }).query;

  console.log('url query>>>', urlQuery);

  let searchQuery = query ? query : '';
  const [inputValue, setInputValue] = React.useState(searchQuery);

  const fetch = React.useMemo(
    () =>
      _.debounce((request) => {
        onGetPodcastAutocompleteSuggestionStart(request.input);
      }, 500),
    []
  );

  React.useEffect(() => {
    console.log('inputChange>>', inputValue);
    if (inputValue.length < 1) {
      return;
    }
    fetch({ input: inputValue });
  }, [inputValue, fetch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    history.push(`/result?query=${inputValue}`);
  };

  return (
    <Paper component="form" className={classes.root} onSubmit={handleSubmit}>
      <Autocomplete
        style={{ width: '100%' }}
        id="asynchronous-demo"
        openOnFocus
        autoComplete
        freeSolo
        disableClearable
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option['type'] === 'podcast') {
            return option['collection_name'];
          }
          return option['genre'];
        }}
        options={autoCompleteSuggestions}
        onChange={(event, newValue) => {
          console.log('onChange>>>', newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        loading={loading}
        groupBy={(option) => option['type']}
        renderGroup={renderGroup}
        defaultValue={searchQuery ? searchQuery : urlQuery}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Enter search here..."
            fullWidth
            margin="dense"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              classes: { notchedOutline: classes.noBorder },
              type: 'search'
            }}
          />
        )}
        renderOption={(option) => (
          <Typography variant="h5" noWrap>
            {option['type'] === 'podcast' ? (
              <Link
                onClick={(event) => {
                  event.preventDefault();
                  history.push(`/details/${option['collection_id']}`);
                }}
              >
                {option['collection_name']}
              </Link>
            ) : (
              <>{option['genre']} </>
            )}
          </Typography>
        )}
      />

      {/* <Divider className={classes.divider} orientation="vertical" /> */}
      <IconButton
        className={classes.iconButton}
        aria-label="directions"
        onClick={handleSubmit}
      >
        <SearchIcon fontSize="large" />
      </IconButton>
    </Paper>
  );
};

CustomizedInputBase.propTypes = {
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

const mapStateToProps = (state) => ({
  search: state.search
});
const mapDispatchToProps = (dispatch) => ({
  onGetPodcastAutocompleteSuggestionStart: (search) =>
    dispatch(fetchAutocompleteSuggestionsPodcastStart(search))
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomizedInputBase)
);
