/* eslint-disable no-case-declarations */
import * as actionTypes from './podcast.types';

const INITIAL_STATE = {
  podcasts: {},
  podcastIDs: [],
  currentPodcast: {},
  items: [],
  query: '',
  episodequery: '',
  loading: false,
  error: {},
  episodes: [],
  totalPodcastSearchResults: null,
  totalEpisodeSearchResults: null,
  autoCompleteSuggestionsForPodcast: []
};

const podcastReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  let updatedState;

  switch (type) {
    case actionTypes.SET_QUERY:
      return {
        ...state,
        query: payload
      };

    case actionTypes.SET_QUERY_FOR_EPISODES:
      return {
        ...state,
        episodequery: payload
      };

    case actionTypes.FETCH_PODCAST_BY_ID_START:
    case actionTypes.SEARCH_ALL_PODCASTS_START:
    case actionTypes.SEARCH_ALL_EPISODES_START:
    case actionTypes.GET_TRENDING_PODCASTS_START:
      return {
        ...state,
        loading: true
      };

    case actionTypes.FETCH_PODCAST_BY_ID_SUCCESS:
      updatedState = {
        ...state,
        loading: false
      };
      updatedState.podcasts[payload.collection_id] = payload;
      return updatedState;

    case actionTypes.SEARCH_ALL_PODCASTS_SUCCESS:
    case actionTypes.GET_TRENDING_PODCASTS_SUCCESS:
      const { results, total } = payload;
      updatedState = {
        ...state,
        loading: false,
        totalPodcastSearchResults: total
      };
      results.forEach((podcast) => {
        updatedState.podcasts[podcast.collection_id] = podcast;
      });
      const podcastIds = results.map((podcast) => podcast.collection_id);
      updatedState.podcastIDs = podcastIds;

      return updatedState;

    case actionTypes.SEARCH_ALL_EPISODES_SUCCESS:
      return {
        ...state,
        episodes: payload.results,
        totalEpisodeSearchResults: payload.total
      };

    case actionTypes.FETCH_PODCAST_BY_ID_FAIL:
    case actionTypes.SEARCH_ALL_PODCASTS_FAIL:
    case actionTypes.SEARCH_ALL_EPISODES_FAIL:
    case actionTypes.GET_TRENDING_PODCASTS_FAIL:
      return {
        ...state,
        loading: false
      };

    case actionTypes.SET_CURRENT_PODCAST:
      return {
        ...state,
        currentPodcast: payload
      };

    case actionTypes.RESET_QUERY_FOR_PODCAST:
      return {
        ...state,
        query: ''
      };

    case actionTypes.RESET_QUERY_FOR_EPISODE:
      return {
        ...state,
        episodequery: ''
      };

    default:
      return state;
  }
};

export default podcastReducer;
