/* eslint-disable */
import { connect } from 'react-redux';

import {
  verifyTokenStart,
  resetPasswordStart
} from 'src/store/auth/auth.actions';

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  onVerifyTokenStart: (email) => dispatch(verifyTokenStart(email)),
  onResetPasswordStart: (data, history) =>
    dispatch(resetPasswordStart(data, history))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
