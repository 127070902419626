/* eslint-disable */

import axios from 'axios';

import { END_POINT } from './constants';

export const validateResetToken = async (token) => {
  console.log('value of token>>>', token);
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        query validateResetToken($input: String!){
          validateResetToken(token: $input){
            valid
            }
        }
            `,
      variables: {
        input: token
      }
    }
  });
  console.log('data is this', result);
  return result;
};
