import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CodeCard from '../codeCard/codeCard';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  }
}));

const Episode = () => {
  const classes = useStyles();

  function createData2(name, calories) {
    return { name, calories };
  }

  const rows2 = [
    createData2('id - Int!', 'the unique identifier of the episode')
  ];

  return (
    <div className={classes.root}>
      <Grid item container id="episode">
        <Grid
          item
          container
          md={7}
          sm={12}
          xs={12}
          style={{ padding: '1rem' }}
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h3" className="doc-title">
              episode
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" gutterBottom>
              Fetch the metadata about the episode
            </Typography>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography
                variant="body1"
                className="operation-name"
                color="textSecondary"
              >
                Response
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className="operation-name">
                Returns an{' '}
                <Link href="#episodeType" color="inherit">
                  Episode!
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom color="textSecondary">
                Arguments
              </Typography>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          md={5}
          sm={12}
          xs={12}
          spacing={2}
          style={{
            padding: '1rem'
          }}
        >
          <Grid item xs={12}>
            <CodeCard header="End Point">
              ``` query episode($id: Int!) &#123;/n episode(id: $id)&#123;/n
              description /n duration /n guid /n id /n link /n pub_date /n title
              /n&#125;/n&#125;```
            </CodeCard>
          </Grid>

          <Grid item xs={12}>
            <CodeCard header="Response">
              ```&#123;/n &quot;data &quot;:&#123;/n &quot;episode &quot;: [/n
              &#123;/n &quot;description &quot;: &quot;Hey everybody.&quot;,/n
              &quot;duration &quot;: 6791/n &quot;guid
              &quot;:724b5f20-1dc2-495e-a0b9-2d1610959fdf,/n &quot;id &quot;:1/n
              &quot;link &quot;:
              &quot;https://anchor.fm/rahdo/episodes/Top-10-Combat-Systems-ersuua
              &quot;/n &quot;pub_date &quot;: &quot;2019-12-03T09:54:33Z
              &quot;,/n &quot;title &quot;: &quot;Top 10 Combat Systems
              &quot;,&#125;/n ]/n &#125;/n &#125;```
            </CodeCard>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Episode;
