import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CodeCard from '../codeCard/codeCard';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  }
}));

const EpisodeSearchResponse = () => {
  const classes = useStyles();

  function createData2(name, calories) {
    return { name, calories };
  }

  const rows2 = [
    createData2(
      <>
        results -
        <Link href="#episodeType" color="inherit">
          [Episode]!{' '}
        </Link>
      </>,
      'The list of episodes'
    ),
    createData2('total -Int!', 'The total number of episodes returned')
  ];

  return (
    <div className={classes.root}>
      <Grid item container id="episodeSearchResponse">
        <Grid
          item
          container
          direction="column"
          spacing={2}
          md={7}
          sm={12}
          xs={12}
          style={{ padding: '1rem' }}
        >
          <Grid item>
            <Typography variant="h3" className="panel-title" component="p">
              EpisodeSearchResponse
            </Typography>
          </Grid>

          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom>
                Returns the list of episodes and total number of episodes
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" gutterBottom color="textSecondary">
                Arguments
              </Typography>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Field Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* <Grid item>
            <Typography variant="body1" gutterBottom>
              The episode of a podcast
            </Typography>
          </Grid> */}
          {/* <div className="definition-tags">
            <a className="label" href="#tag-types">
              Types
            </a>
          </div>
          <h2 className="panel-title">
            <p>
              <code>Episode</code>
            </p>
          </h2> */}
          {/* <div className="doc-copy">
            <section className="json-schema-description">
              <p>The episode of a podcast</p>
            </section>
            <section className="json-schema-properties-blank">
              <table>
                <tbody>
                  <tr>
                    <th>Field Name</th>
                    <th>Description</th>
                  </tr>
                  <tr>
                    <td data-property-name="description">
                      {' '}
                      <code>description</code> -
                      <Link to="/documentation#definition-String">
                        <code>String</code>
                      </Link>
                    </td>
                    <td>
                      <p>The description of the episode</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="duration">
                      {' '}
                      <code>duration</code> -
                      <Link to="/documentation#definition-String">
                        <code>String</code>
                      </Link>
                    </td>
                    <td>
                      <p>The time duration of the episode</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="guid">
                      {' '}
                      <code>guid</code> -
                      <Link to="/documentation#definition-String">
                        <code>String!</code>
                      </Link>
                    </td>
                    <td>
                      <p>Unique identifier of the episodes</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="id">
                      {' '}
                      <code>id</code> -
                      <Link to="/documentation#definition-Int">
                        <code>Int!</code>
                      </Link>
                    </td>
                    <td>
                      <p>episode identifier</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="link">
                      {' '}
                      <code>link</code> -
                      <Link to="/documentation#definition-String">
                        <code>String!</code>
                      </Link>
                    </td>
                    <td>
                      <p>The audio link for playing the episodes</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="pub_date">
                      {' '}
                      <code>pub_date</code> -
                      <Link to="/documentation#definition-DateTime">
                        <code>DateTime!</code>
                      </Link>
                    </td>
                    <td>
                      <p>The date and time when the episode was published</p>
                    </td>
                  </tr>
                  <tr>
                    <td data-property-name="title">
                      {' '}
                      <code>title</code> -
                      <Link to="/documentation#definition-String">
                        <code>String!</code>
                      </Link>
                    </td>
                    <td>
                      <p>The title of the episode</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div> */}
        </Grid>
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          style={{
            padding: '1rem'
          }}
        >
          <CodeCard header="Example">
            ``` &#123;/n results:[Episode]/n &quot;total&quot;: 15 /n&#125; ```
          </CodeCard>

          {/* <h5>Example</h5>
          <pre>
            <code className="hljs language-json">
              {'{'}
              {'\n'}
              {'  '}
              <span className="hljs-attr">&quot;description&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;Hey everybody, this is February&apos;s top10, a bit late,
                but to make up for that, I&apos;m joined by the newest
                contributor to the channel, Tim Chuon!&quot;
              </span>
              ,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;duration&quot;</span>:{' '}
              <span className="hljs-number">6791</span>,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;guid&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;724b5f20-1dc2-495e-a0b9-2d1610959fdf&quot;
              </span>
              ,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;id&quot;</span>:{' '}
              <span className="hljs-number">1</span>,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;link&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;https://anchor.fm/rahdo/episodes/Top-10-Combat-Systems-ersuua&quot;
              </span>
              ,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;pub_date&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;2019-12-03T09:54:33Z&quot;
              </span>
              ,{'\n'}
              {'  '}
              <span className="hljs-attr">&quot;title&quot;</span>:{' '}
              <span className="hljs-string">
                &quot;Top 10 Combat Systems&quot;
              </span>
              {'\n'}
              {'}'}
              {'\n'}
            </code>
          </pre> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default EpisodeSearchResponse;
