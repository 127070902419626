import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const RouteWithLayout = ({
  layout: Layout,
  component: Component,
  audioPlayer,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <Layout>
            <Component audioPlayer={audioPlayer} {...matchProps} />
          </Layout>
        );
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  layout: PropTypes.any,
  component: PropTypes.any,
  children: PropTypes.node,
  audioPlayer: PropTypes.any
};

export default RouteWithLayout;
