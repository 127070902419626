import { connect } from 'react-redux';

import {
  setPlayerCurrentTrack,
  togglePlayPause,
  setPlayerNextTrack,
  setPlayerPreviousTrack,
  clearPlayerState,
  setPlayerState
} from 'src/store/player/player.actions';

const mapStateToProps = (state) => ({
  currentEpisode: state.player.currentTrack,
  currentPodcast: state.podcast.currentPodcast,
  podcast: state.podcast.currentPodcast,
  player: state.player
});

const mapDispatchToProps = (dispatch) => ({
  onTogglePlayPause: (value) => dispatch(togglePlayPause(value)),
  onSetPlayerCurrentTrack: (id) => dispatch(setPlayerCurrentTrack(id)),
  onSetPlayerNextTrack: (id) => dispatch(setPlayerNextTrack(id)),
  onSetPlayerPreviousTrack: (id) => dispatch(setPlayerPreviousTrack(id)),
  onClearPlayerState: () => dispatch(clearPlayerState()),
  onSetPlayerState: (value) => dispatch(setPlayerState(value))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
