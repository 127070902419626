/* eslint-disable */
import { useHistory, Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import ChangePassword from 'src/pages/ChangePassword/ChangePassword';

const useStyles = makeStyles(() => ({
  cardRoot: {
    border: '1px solid #CCCCCC',
    borderRadius: '20px'
  },
  button: {
    borderRadius: '20px',
    textTransform: 'capitalize'
  },
  inputText: {
    borderRadius: '20px'
  }
}));
const Security = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user, onUpdateUserStart, onUploadProfilePicStart } = props;

  return (
    <Grid item container>
      <Card className={classes.cardRoot}>
        <CardHeader
          // subheader="The information can be edited"
          title="Change Your Password"
        />
        <Divider />
        <CardContent>
          <ChangePassword />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Security;
