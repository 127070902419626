import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import PropTypes from 'prop-types';

function CodeBlock({language,children}) {
 console.log("language",language)
    return (
      <SyntaxHighlighter language="graphql" style={prism}>
        {String(children).replaceAll('/n','\n')}
      </SyntaxHighlighter>
    );
  }

  CodeBlock.propTypes = {
    children: PropTypes.string.isRequired,
    language: PropTypes.string
  }

export default CodeBlock
