import 'react-perfect-scrollbar/dist/css/styles.css';
import { useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useInterval } from 'src/hooks';

import GlobalStyles from 'src/components/GlobalStyles';

import { loadUserStart, signOutStart } from 'src/store/auth/auth.actions';

import Player from './modules/views/Player';
import ScrollTop from './modules/components/ScrollTop';

import Routes from './routes';
import theme from './theme';
// import CustomAlert from './modules/components/alert/CustomAlert';

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const audioPlayer = useRef();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    dispatch(loadUserStart());
  }, []);

  useInterval(() => {
    if (isAuthenticated) {
      const expiryDate = localStorage.getItem('expiryDate');
      if (new Date(expiryDate) <= new Date()) {
        dispatch(signOutStart());
      }
    }
  }, 60000);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Routes audioPlayer={audioPlayer} />
      </Router>
      <Player audioPlayer={audioPlayer} />
      <ScrollTop>
        <Fab
          color="secondary"
          size="small"
          aria-label="scroll back to top"
          onClick={scrollToTop}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </ThemeProvider>
  );
}

export default App;
