import { combineReducers } from 'redux';

import podcastReducer from './podcast/podcast.reducer';
import playerReducer from './player/player.reducer';
import authReducer from './auth/auth.reducer';
import alertReducer from './alert/alert.reducer';
import subscriptionReducer from './subscription/subscription.reducer';
import searchReducer from './search/search.reducer';
import checkoutReducer from './checkout/checkout.reducer';

// const persistConfig = {
//   key: 'root',
//   storage: storage,
//   whitelist: ['auth'] // only navigation will be persisted
// };

const rootReducer = combineReducers({
  auth: authReducer,
  podcast: podcastReducer,
  player: playerReducer,
  alert: alertReducer,
  subscription: subscriptionReducer,
  search: searchReducer,
  checkout: checkoutReducer
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export default rootReducer;
