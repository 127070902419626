/* eslint-disable */

import axios from 'axios';

import { END_POINT } from './constants';

export const resetPasswordRequest = async (email) => {
  console.log('value of email in mutation>>>', email);
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        mutation resetPasswordRequest($input: ForgotPasswordInput!){
          resetPasswordRequest(input: $input){
            success
            }
        }
            `,
      variables: {
        input: { email }
      }
    }
  });
  console.log('data is this', result);
  return result;
};
