/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PodcastCard from 'src/modules/views/PodcastCard';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import Typography from '@material-ui/core/Typography';

import container from './Search.container';
import CustomizedInputBase from '../Home/components/SearchBar';
import EpisodeCard from 'src/modules/views/EpisodeCard';
import PopOver from 'src/modules/components/PopOver';
import Paper from '@material-ui/core/Paper';
import CategoriesFilter from './components/CategoriesFilter';

const qs = require('qs');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
    maxWidth: 500
  }
}));

function TabPanel(props) {
  const { children, type, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={type !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {type === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function Search(props) {
  const classes = useStyles();
  const {
    onSearchPodcastStart,
    onSearchEpisodeStart,
    isLoading,
    podcastIDs,
    podcasts,
    onSetCurrentPodcast,
    history,
    location,
    onSetPlayerCurrentTrack,
    episodes,
    totalPodcastSearchResults,
    totalEpisodeSearchResults
  } = props;

  const searchQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true
  }).query;
  const genreQuery = qs
    .parse(location.search, {
      ignoreQueryPrefix: true
    })
    .genre?.split(',');

  // const [query, setQuery] = useState(searchQuery);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [type, setType] = useState(0);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const filterParams = genreQuery ? { categories: genreQuery } : undefined;
    onSearchPodcastStart(page, size, searchQuery, filterParams, undefined);
    onSearchEpisodeStart(page, size, searchQuery, undefined, undefined);
  }, [page, size, searchQuery, `${genreQuery}`]);

  const items = podcastIDs.map((id) => podcasts[id]);

  let emptyContents;
  if (isLoading && items.length === 0) {
    emptyContents = (
      <Grid container justify="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  } else if (!isLoading && items.length === 0) {
    emptyContents = (
      <Alert variant="outlined" severity="error">
        No Result Found
      </Alert>
    );
  }

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   history.push(`/search?query=${query}`);
  // };

  // const handleQueryChange = async (event) => {
  //   event.preventDefault();
  //   setQuery(event.target.value);
  // };

  const handleChange = (event, newValue) => {
    setType(newValue);
  };

  const onSelectedCategoriesChange = (event, values) => {
    setCategories(values.map((value) => value.name));
  };

  const onApplyFiltersClick = () => {
    if (categories.length) {
      history.push(
        `${
          location.pathname
        }?query=${searchQuery}&genre=${categories.toString()}`
      );
    } else {
      history.push(`${location.pathname}?query=${searchQuery}`);
    }
  };
  return (
    <Container>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid container direction="row" justify="center">
          <Grid item xs={6}>
            <CustomizedInputBase
              history={history}
              placeholder="Trending: Rahdo Talks Through"
              query={searchQuery}
            />
          </Grid>
        </Grid>

        <Grid item alignItems="center">
          <Tabs
            value={type}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Podcast" {...a11yProps(0)} />
            <Tab label="Episode" {...a11yProps(1)} />
          </Tabs>
        </Grid>
      </Grid>

      <TabPanel type={type} index={0}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.paper}
        >
          <Grid Item>
            <Typography variant="h6" gutterBottom>
              Page {page} of {Math.ceil(totalPodcastSearchResults / size)}
            </Typography>
          </Grid>

          <Grid Item>
            <PopOver popOverLabel="Filters">
              <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item sm={4} md={3}>
                  <Typography variant="h5" gutterBottom>
                    Categories
                  </Typography>
                </Grid>
                <Grid item sm={12} md={8}>
                  <CategoriesFilter onChange={onSelectedCategoriesChange} />
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    onClick={onApplyFiltersClick}
                    // disabled={}
                  >
                    Apply Filters
                  </Button>
                </Grid>
              </Grid>
            </PopOver>
          </Grid>
        </Grid>
        {items && items.length
          ? items.map((podcast, i) => (
              <PodcastCard
                key={`podcast-${i + 1}-${podcast.id}`}
                data={podcast}
                setCurrentPodcast={onSetCurrentPodcast}
                history={history}
              />
            ))
          : emptyContents}
        <Grid
          container
          direction="row"
          justify="space-between"
          spacing={3}
          className={classes.paper}
        >
          <Grid Item>
            <Button
              variant="contained"
              onClick={() => setPage(page - 1)}
              disabled={page - 1 ? false : true}
            >
              Previous
            </Button>
          </Grid>
          <Grid Item>
            <Button
              variant="contained"
              onClick={() => setPage(page + 1)}
              disabled={
                page === Math.ceil(totalPodcastSearchResults / size)
                  ? true
                  : false
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel type={type} index={1}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.paper}
        >
          <Grid Item>
            <Typography variant="h6" gutterBottom>
              Page {page} of {Math.ceil(totalEpisodeSearchResults / size)}
            </Typography>
          </Grid>

          <Grid Item>
            <Typography variant="h6" gutterBottom>
              Filters
            </Typography>
          </Grid>
        </Grid>
        {episodes && episodes.length
          ? episodes.map((episode, i) => (
              <EpisodeCard
                data={episode}
                key={episode.guid}
                setPlayerEpisode={onSetPlayerCurrentTrack}
              />
            ))
          : emptyContents}
        <Grid
          container
          direction="row"
          justify="space-between"
          spacing={3}
          className={classes.paper}
        >
          <Grid Item>
            <Button
              variant="contained"
              onClick={() => setPage(page - 1)}
              disabled={page - 1 ? false : true}
            >
              Previous
            </Button>
          </Grid>
          <Grid Item>
            <Button
              variant="contained"
              onClick={() => setPage(page + 1)}
              disabled={
                page === Math.ceil(totalEpisodeSearchResults / size)
                  ? true
                  : false
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
    </Container>
  );
}

export default container(Search);
