/* eslint-disable */
import axios from 'axios';

import { END_POINT, MASTER_API_KEY } from './constants';

export const fetchPodcastCategories = async () => {
  const response = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
        query FetchPodcastsCategories($pagination: PaginationArgs){
          allGenres(pagination: $pagination){
            results{
              genres
            }
            total
          }
        }
      `,
      variables: {
        pagination: {
          limit: 50,
          offset: 0
        }
      }
    }
  });
  return response;
};
