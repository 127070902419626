/* eslint-disable no-else-return */
/* eslint-disable react/jsx-one-expression-per-line */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const planName = (planId) => {
  console.log('value of plan id inside planName>>', planId);
  if (planId === 13049) {
    return 'Free Plan';
  } else if (planId === 14219) {
    return 'Basic Plan';
  } else if (planId === 13051) {
    return 'Pro Plan';
  } else {
    return '';
  }
};

const TrafficByDevice = ({ subscriptions, className, ...rest }) => {
  const classes = useStyles();

  // const getProgressValue = (issueDate) => {
  //   const rightNow = new Date();

  //   const timePassed = new Date(issueDate) - rightNow;
  //   const msInADay = 1000 * 60 * 60 * 24;
  //   const toReturn = (timePassed / msInADay) * 100;
  //   return toReturn;
  // };

  // const devices = [
  //   {
  //     title: 'Desktop',
  //     value: 63,
  //     icon: LaptopMacIcon,
  //     color: colors.indigo[500]
  //   },
  //   {
  //     title: 'Tablet',
  //     value: 15,
  //     icon: TabletIcon,
  //     color: colors.red[600]
  //   },
  //   {
  //     title: 'Mobile',
  //     value: 23,
  //     icon: PhoneIcon,
  //     color: colors.orange[600]
  //   }
  // ];

  // function CircularProgressWithLabel(props) {
  //   return (
  //     <Box position="relative" display="inline-flex">
  //       <CircularProgress variant="determinate" {...props} size={120} />
  //       <Box
  //         top={0}
  //         left={0}
  //         bottom={0}
  //         right={0}
  //         position="absolute"
  //         display="flex"
  //         alignItems="center"
  //         justifyContent="center"
  //       >
  //         <Typography variant="caption" component="div" color="textSecondary">
  //           {moment(subscriptions[0]?.nextBillDate).format('MMMM Do, YYYY')}
  //         </Typography>
  //       </Box>
  //     </Box>
  //   );
  // }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Subscription" />
      <Divider />
      <CardContent>
        <Box justifyContent="center" mt={2}>
          <Box p={1} textAlign="center">
            <Typography color="textPrimary" variant="h3">
              Subscription Payment Due
            </Typography>
            {/* <CircularProgressWithLabel
              value={getProgressValue(subscriptions[0]?.nextBillDate)}
            /> */}

            <Typography variant="subtitle2">
              {moment(subscriptions[0]?.nextBillDate).format('MMMM Do, YYYY')}
            </Typography>
          </Box>
          <Box p={1} textAlign="center" mt={7}>
            <Typography color="textPrimary" variant="h3">
              Subscription Plan
            </Typography>
            <Typography variant="subtitle2">
              {planName(subscriptions[0]?.subscriptionPlanId)}
            </Typography>
          </Box>
          <Box p={1} textAlign="center" mt={7}>
            <Typography color="textPrimary" variant="h3">
              Status
            </Typography>
            <Grid item container justify="center" alignItems="center">
              <Grid item>
                <FiberManualRecordIcon
                  style={{
                    color:
                      subscriptions[0]?.status === 'active' ? 'green' : 'red'
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {subscriptions[0]?.status}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

TrafficByDevice.propTypes = {
  className: PropTypes.string,
  subscriptions: PropTypes.any
};

export default TrafficByDevice;
