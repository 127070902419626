import { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Breadcrumbs
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import Page from 'src/components/Page';
import { ApiUsageChart } from './components';

import {
  LastSevenDays,
  MonthToDate,
  RemainingApiUsage,
  TodaysRequestUsage
} from './components/apiComponents';
import Subscription from './components/Subscription';
import container from './Dashboard.container';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E5E5E5',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = ({
  onFetchAPICountByDateRangeStart,
  onFetchTotalAPICountStart,
  auth: {
    remainingAPICount,
    last7DaysAPICount,
    todayAPICount,
    dateRangeAPICount,
    totalAPICount,
    loading,
    user: { subscriptions }
  }
}) => {
  const classes = useStyles();

  useEffect(() => {
    const today = new Date().toJSON().slice(0, 10);
    const oneWeekAgo = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    )
      .toJSON()
      .slice(0, 10);
    console.log('today>>', today);
    console.log('subtractMonth>>', oneWeekAgo);
    onFetchAPICountByDateRangeStart(oneWeekAgo, today);
    onFetchTotalAPICountStart();
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Breadcrumbs aria-label="breadcrumb" style={{ margin: '37px 0 41px' }}>
          <Link style={{ color: '#5B5B5B' }} to="/">
            Home
          </Link>
          <Link style={{ color: '#398bb8' }} aria-current="page">
            Dashboard
          </Link>
        </Breadcrumbs>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TodaysRequestUsage todayAPICount={todayAPICount} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <LastSevenDays last7DaysAPICount={last7DaysAPICount} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <MonthToDate totalAPICount={totalAPICount} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <RemainingApiUsage remainingAPICount={remainingAPICount} />
          </Grid>
          <Grid item lg={subscriptions.length ? 7 : 12} md={12} xl={12} xs={12}>
            {loading ? (
              <CircularProgress />
            ) : (
              <ApiUsageChart dateRangeAPICount={dateRangeAPICount} />
            )}
          </Grid>
          {subscriptions.length ? (
            <Grid item lg={5} md={6} xl={3} xs={12}>
              <Subscription subscriptions={subscriptions} />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Container>
    </Page>
  );
};

Dashboard.propTypes = {
  onFetchAPICountByDateRangeStart: PropTypes.func,
  onFetchTotalAPICountStart: PropTypes.func,
  auth: PropTypes.object
};

export default container(Dashboard);
