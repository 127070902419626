export const SET_PLAYER_CURRENT_TRACK = 'SET_PLAYER_CURRENT_TRACK';
export const SET_PLAYER_NEXT_TRACK = 'SET_PLAYER_NEXT_TRACK';
export const SET_PLAYER_PREVIOUS_TRACK = 'SET_PLAYER_PREVIOUS_TRACK';

export const SET_IS_PLAYING = 'SET_IS_PLAYING';

export const TOGGLE_PLAY_PAUSE = 'TOGGLE_PLAY_PAUSE';
export const SET_PLAYER_STATE = 'SET_PLAYER_STATE';

export const CLEAR_PLAYER_STATE = 'CLEAR_PLAYER_STATE';

export const SET_PLAYER_COUNT = 'SET_PLAYER_COUNT';
