import * as actionType from './subscription.types';

export const genTokenStart = () => ({
  type: actionType.GEN_TOKEN_START
});

export const genTokenSuccess = (data) => ({
  type: actionType.GEN_TOKEN_SUCCESS,
  payload: data
});

export const genTokenFail = (error) => ({
  type: actionType.GEN_TOKEN_FAIL,
  payload: error
});

export const fetchApiTokenStart = () => ({
  type: actionType.FETCH_TOKEN_START
});

export const fetchApiTokenSuccess = (data) => ({
  type: actionType.FETCH_TOKEN_SUCCESS,
  payload: data
});

export const fetchApiTokenFail = (error) => ({
  type: actionType.FETCH_TOKEN_FAIL,
  payload: error
});

export const createSubscriptionStart = (history, handleClose) => ({
  type: actionType.CREATE_SUBSCRIPTION_START,
  payload: { history, handleClose }
});

export const createSubscriptionSuccess = (data) => ({
  type: actionType.CREATE_SUBSCRIPTION_SUCCESS,
  payload: data
});

export const createSubscriptionFail = (error) => ({
  type: actionType.CREATE_SUBSCRIPTION_FAIL,
  payload: error
});

export const pauseSubscriptionStart = (subscriptionId, pause) => ({
  type: actionType.PAUSE_SUBSCRIPTION_START,
  payload: { subscriptionId, pause }
});

export const pauseSubscriptionSuccess = () => ({
  type: actionType.PAUSE_SUBSCRIPTION_SUCCESS
});

export const pauseSubscriptionFail = (error) => ({
  type: actionType.PAUSE_SUBSCRIPTION_FAIL,
  payload: error
});

export const changeSubscriptionPlanStart = (
  subscriptionId,
  subscriptionPlanId
) => ({
  type: actionType.CHANGE_SUBSCRIPTION_PLAN_START,
  payload: { subscriptionId, subscriptionPlanId }
});

export const changeSubscriptionPlanSuccess = () => ({
  type: actionType.CHANGE_SUBSCRIPTION_PLAN_SUCCESS
});

export const changeSubscriptionPlanFail = (error) => ({
  type: actionType.CHANGE_SUBSCRIPTION_PLAN_FAIL,
  payload: error
});
