/* eslint-disable */

import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import axios from 'axios';

import { REST_ENDPOINT } from './constants';

export const uploadProfilePic = async (file) => {
  console.log('file>>>', file);
  let formData = new FormData();
  formData.append('file', file);
  const result = await axios.post(
    `${REST_ENDPOINT}/user/uploadProfilePic`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  console.log('data is this', result);
  return result;
};
