/* eslint-disable */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { Grid, Typography, Paper, Box, ButtonBase } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import getUniqueItemsFromList from 'src/utils/getUniqueItemsFromList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 199,
    height: 201,
    margin: '19px 41px 21px 27px',
    borderRadius: '6px'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  textStyle: {
    marginTop: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    // minWidth: 790,
    width: 820,
    '&:hover': {
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  image: {
    width: 199,
    height: 201,
    margin: '19px 17px 21px 27px',
    borderRadius: '6px'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  tagIcon: {
    paddingTop: '12px',
    color: '#5B5B5B'
  }
}));

const DataCard = (props) => {
  const classes = useStyles();
  const { data, viewDisable, setCurrentPodcast, history } = props;
  const {
    collection_name: name,
    artist_name: author,
    artwork_url_100: artworkUrl100,
    genre,
    collection_id: collectionId,
    release_date,
    description
  } = data;

  const truncate = (str) => {
    return str.length > 150 ? str.substr(0, 150 - 1) + `...[More]` : str;
  };

  const handleClickView = (data) => {
    setCurrentPodcast(data);
    history.push(`/details/${collectionId}`);
  };
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const uniqueGenres = genre && getUniqueItemsFromList(genre);

  return (
    <Box mt={2}>
      <Paper className={classes.paper} onClick={() => handleClickView(data)}>
        <Grid
          container
          justify={matches ? 'center' : undefined}
          alignItems={matches ? 'center' : undefined}
          spacing={2}
        >
          <Grid item>
            <ButtonBase>
              <img
                className={classes.image}
                alt="name"
                src={artworkUrl100 ? artworkUrl100.toString() : null}
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs style={{ marginTop: '1.6rem' }}>
                {/* <Button variant="contained" color="primary">
               New
             </Button> */}
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.textStyle}
                  align={matches ? 'center' : undefined}
                >
                  {name ?? name}
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  color="textSecondary"
                  className={classes.textStyle}
                  align={matches ? 'center' : undefined}
                >
                  {release_date && moment(release_date).format('MMMM DD, YYYY')}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.textStyle}
                  align={matches ? 'center' : undefined}
                >
                  {description && truncate(description)}
                </Typography>
                {uniqueGenres &&
                  uniqueGenres.map((item) => (
                    <>
                      <LocalOfferIcon className={classes.tagIcon} />
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ paddingRight: '27px' }}
                      >
                        {item}
                      </Typography>
                    </>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default DataCard;
