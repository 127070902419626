/* eslint-disable */
export const GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_START =
  'GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_START';
export const GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_SUCCESS =
  'GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_SUCCESS';
export const GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_FAIL =
  'GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_FAIL';

export const GET_PODCAST_CATEGORIES_START = 'GET_PODCAST_CATEGORIES_START';
export const GET_PODCAST_CATEGORIES_SUCCESS = 'GET_PODCAST_CATEGORIES_SUCCESS';
export const GET_PODCAST_CATEGORIES_FAIL = 'GET_PODCAST_CATEGORIES_FAIL';
