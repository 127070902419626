/* eslint-disable object-curly-newline */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import _ from 'lodash';
import { getTrendingPodcasts } from 'src/api/getTrendingPodcasts';
import { fetchPodcastByCollectionId } from 'src/api/fetchPodcastById';
import { searchPodcast } from 'src/api/elasticSearchPodcasts';
import { searchEpisode } from 'src/api/elasticSearchEpisode';
import * as actionTypes from './podcast.types';
import * as actions from './podcast.actions';

export function* searchPodcastAsync({
  payload: { page = 0, size = 15, search, filter, sort }
}) {
  const limit = size;
  const offset = (page - 1) * size;

  const params = { limit, offset, search, filter, sort };
  try {
    const { data } = yield call(searchPodcast, params);
    if (!data.errors) {
      yield put(actions.searchPodcastSuccess(data.data.searchPodcasts));
    } else {
      yield put(actions.searchPodcastFail({ message: 'Podcasts Not Found' }));
    }
  } catch (err) {
    yield put(actions.searchPodcastFail(err.message));
  }
}

export function* getTrendingPodcastAsync({
  payload: { limit = 20, offset = 0 }
}) {
  const params = { limit, offset };
  try {
    const { data } = yield call(getTrendingPodcasts, params);
    if (!data.errors) {
      yield put(actions.searchPodcastSuccess(data.data.getTrendingPodcasts));
    } else {
      yield put(actions.searchPodcastFail({ message: 'Podcasts Not Found' }));
    }
  } catch (err) {
    yield put(actions.searchPodcastFail(err.message));
  }
}

export function* searchEpisodeAsync({
  payload: { page = 0, size = 15, search, filter, sort }
}) {
  const limit = size;
  const offset = (page - 1) * size;

  const params = { limit, offset, search, filter, sort };
  try {
    const { data } = yield call(searchEpisode, params);
    if (!data.errors) {
      yield put(actions.searchEpisodeSuccess(data.data.searchEpisodes));
    } else {
      yield put(actions.searchEpisodeFail({ message: 'Episodes Not Found' }));
    }
  } catch (err) {
    yield put(actions.searchEpisodeFail(err.message));
  }
}

export function* fetchPodcastAsync({
  payload: { query, page, size, sortArgs }
}) {
  const limit = size;
  const offset = (page - 1) * size;

  if (!_.isEmpty(query)) {
    try {
      const params = { query, limit, offset, sortArgs };
      const { data } = yield call(fetchPodcastByCollectionId, params);
      if (!data.errors && data.data.podcast) {
        yield put(actions.fetchPodcastByIdSuccess(data.data.podcast));
        yield put(actions.setCurrentPodcast(data.data.podcast));
        // history.push(`/search/?query=${encodeURI(query)}`);
      } else {
        yield put(actions.fetchPodcastByIdFail(data.errors.message));
      }
    } catch (err) {
      yield put(actions.fetchPodcastByIdFail(err.message));
    }
  }
}

export function* watchFetchPodcastById() {
  yield takeLatest(actionTypes.FETCH_PODCAST_BY_ID_START, fetchPodcastAsync);
}

export function* watchSearchPodcast() {
  yield takeLatest(actionTypes.SEARCH_ALL_PODCASTS_START, searchPodcastAsync);
}

export function* watchGetTrendingPodcasts() {
  yield takeLatest(
    actionTypes.GET_TRENDING_PODCASTS_START,
    getTrendingPodcastAsync
  );
}

export function* watchSearchEpisode() {
  yield takeLatest(actionTypes.SEARCH_ALL_EPISODES_START, searchEpisodeAsync);
}

export function* podcastSagas() {
  yield all([
    call(watchFetchPodcastById),
    call(watchSearchPodcast),
    call(watchGetTrendingPodcasts),
    call(watchSearchEpisode)
  ]);
}
