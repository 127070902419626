import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core/';
// import FastRewindIcon from '@material-ui/icons/FastRewind';
import Replay10Icon from '@material-ui/icons/Replay10';

const useStyles = makeStyles((theme) => ({
  volIcons: {
    fontSize: '1.8em',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em'
    }
  }
}));

const PlayPauseButton = ({ playPrevious }) => {
  const classes = useStyles();
  return (
    <IconButton color="primary" aria-label="Pause" onClick={playPrevious}>
      <Replay10Icon fontSize="large" className={classes.volIcons} />
      {/* <FastRewindIcon fontSize="large" className={classes.volIcons} /> */}
    </IconButton>
  );
};

PlayPauseButton.propTypes = {
  playPrevious: PropTypes.func
};

export default PlayPauseButton;
