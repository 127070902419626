export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const SIGNOUT_START = 'SIGNOUT_START';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAIL = 'SIGNOUT_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPLOAD_PROFILE_PIC_START = 'UPLOAD_PROFILE_PIC_START';
export const UPLOAD_PROFILE_PIC_SUCCESS = 'UPLOAD_PROFILE_PIC_SUCCESS';
export const UPLOAD_PROFILE_PIC_FAIL = 'UPLOAD_PROFILE_PIC_FAIL';

export const LOAD_USER_START = 'LOAD_USER_START';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL';

export const FETCH_TOTAL_API_COUNT_START = 'FETCH_TOTAL_API_COUNT_START';
export const FETCH_TOTAL_API_COUNT_SUCCESS = 'FETCH_TOTAL_API_COUNT_SUCCESS';
export const FETCH_TOTAL_API_COUNT_FAIL = 'FETCH_TOTAL_API_COUNT_FAIL';

export const FETCH_API_COUNT_BY_DATE_RANGE_START =
  'FETCH_API_COUNT_BY_DATE_RANGE_START';
export const FETCH_API_COUNT_BY_DATE_RANGE_SUCCESS =
  'FETCH_API_COUNT_BY_DATE_RANGE_SUCCESS';
export const FETCH_API_COUNT_BY_DATE_RANGE_FAIL =
  'FETCH_API_COUNT_BY_DATE_RANGE_FAIL';

export const REQUEST_PASSWORD_RESET_START = 'REQUEST_PASSWORD_RESET_START';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAIL = 'REQUEST_PASSWORD_RESET_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const VERIFY_TOKEN_START = 'VERIFY_TOKEN_START';
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_FAIL = 'VERIFY_TOKEN_FAIL';

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';
