/* eslint-disable */
import * as React from 'react';
import { useHistory, Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography,
  CircularProgress,
  CardActions
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  cardRoot: {
    border: '1px solid #CCCCCC',
    borderRadius: '20px'
  },
  button: {
    borderRadius: '20px',
    textTransform: 'capitalize'
  },
  inputText: {
    borderRadius: '20px'
  }
}));
const Billing = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [plan, setPlan] = React.useState('');

  const history = useHistory();
  const {
    user: { subscriptions },
    remainingAPICount,
    last7DaysAPICount,
    todayAPICount,
    dateRangeAPICount,
    totalAPICount,
    onPauseSubscriptionStart,
    onChangeSubscriptionPlanStart,
    loading
  } = props;

  const planName = (planId) => {
    console.log('value of plan id inside planName>>', planId);
    if (planId === 13049) {
      return 'Free Plan';
    } else if (planId === 14219) {
      return 'Basic Plan';
    } else if (planId === 13051) {
      return 'Pro Plan';
    } else {
      return '';
    }
  };

  const daysRemaining = () => {
    const date1 = moment();
    const date2 = moment(subscriptions[0]?.nextBillDate);
    const diff = date2.diff(date1, 'days');
    if (diff > 0) {
      return diff;
    } else {
      return 'expired';
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlanChange = (event) => {
    setPlan(event.target.value);
  };

  const handleSubscriptionStateChange = (e) => {
    const pause = subscriptions[0]?.paused ? false : true;
    const subscriptionId = subscriptions[0].subscriptionId;
    onPauseSubscriptionStart(subscriptionId, pause);
  };

  const handleSubscriptionPlanChange = (e) => {
    const subscriptionId = subscriptions[0].subscriptionId;
    onChangeSubscriptionPlanStart(subscriptionId, plan);
    setOpen(false);
  };

  const planIdList = [
    { planId: 13049, planName: 'FREE PLAN' },
    { planId: 14219, planName: 'BASIC PLAN' },
    { planId: 13051, planName: 'PRO PLAN' }
  ];
  return (
    <Grid item container>
      <Card className={classes.cardRoot}>
        <CardHeader
          // subheader="The information can be edited"
          title="Manage Your Plan"
        />
        <Divider />
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography align="center" color="textSecondary">
                  {planName(subscriptions[0]?.subscriptionPlanId)}
                </Typography>
                <Typography align="center" variant="h3">
                  ${subscriptions[0]?.price ? subscriptions[0]?.price : 0}/
                  <span style={{ fontSize: '12px' }}>mo</span>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption">
                  {remainingAPICount} Remaining Api Usage
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption">
                  {totalAPICount} Total Api Usage
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption">
                  {todayAPICount} Today Api Usage
                </Typography>
              </Grid>
              {subscriptions[0]?.subscriptionPlanId && (
                <Grid item xs={4}>
                  <Typography variant="caption">
                    Plan Type: {planName(subscriptions[0]?.subscriptionPlanId)}
                  </Typography>
                </Grid>
              )}

              {subscriptions[0]?.status && (
                <Grid item xs={4}>
                  <Typography variant="caption">
                    Status: {subscriptions[0]?.status}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={4}>
                <Typography variant="caption">
                  Remaining Days: {daysRemaining()}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <Stack
          sx={{ borderRadius: 2, margin: 2 }}
          direction="row"
          justifyContent="space-around"
        >
          <Button
            variant="contained"
            disabled={subscriptions[0]?.status === 'deleted' ? true : false}
            onClick={handleClickOpen}
          >
            Change Plan
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Change Plan</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Move user to another subscription
              </DialogContentText>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Plan</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Plan"
                  onChange={handlePlanChange}
                >
                  {planIdList
                    .filter(
                      (item) =>
                        item.planId !== subscriptions[0]?.subscriptionPlanId
                    )
                    .map((item) => {
                      console.log('planId>>>', item.planId);
                      return (
                        <MenuItem value={item.planId}>{item.planName}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubscriptionPlanChange}>
                Change Plan
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            variant="contained"
            onClick={handleSubscriptionStateChange}
            disabled={subscriptions[0]?.status === 'deleted' ? true : false}
          >
            {`${
              subscriptions[0]?.paused
                ? 'Restart Subscription'
                : 'Pause Subscription'
            }`}
          </Button>
          <Button
            variant="contained"
            disabled={subscriptions[0]?.status === 'deleted' ? true : false}
          >
            <a href={`${subscriptions[0]?.cancelUrl}`}>Cancel Subscription</a>
          </Button>
          <Button
            variant="contained"
            disabled={subscriptions[0]?.status === 'deleted' ? true : false}
          >
            <a href={`${subscriptions[0]?.updateUrl}`}>Update Payment Method</a>
          </Button>
        </Stack>
      </Card>
    </Grid>
  );
};

export default Billing;
