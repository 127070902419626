/* eslint-disable */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import {
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  useMediaQuery
} from '@material-ui/core';
import itunes from 'src/assets/img/icons/podcast.svg';
import spotify from 'src/assets/img/icons/spotify.svg';
import rss from 'src/assets/img/icons/rss.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 199,
    height: 201,
    margin: '19px 41px 21px 27px',
    borderRadius: '6px'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  textStyle: {
    marginTop: '11px'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',

    width: 820,
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  image: {
    width: 350,
    height: 379,
    margin: '19px 41px 21px 27px',
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      width: 307,
      height: 379
    }
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

const DetailsCard = (props) => {
  const classes = useStyles();

  const { data, key } = props;
  const {
    collection_name: name,
    artist_name: author,
    artwork_url_600: artworkUrl600,
    genres = [],
    collection_id: collectionId,
    release_date,
    description
  } = data;

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const truncate = (str) => {
    return str.length > 300 ? str.substr(0, 300 - 1) + `...` : str;
  };

  return (
    <Box mt={2}>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          justify={matches ? 'center' : undefined}
          alignItems={matches ? 'center' : undefined}
        >
          <Grid item>
            <img
              className={classes.image}
              alt="name"
              src={artworkUrl600 ? artworkUrl600.toString() : null}
            />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs style={{ marginTop: '3.6rem' }}>
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.textStyle}
                  align={matches ? 'center' : undefined}
                >
                  {name}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.textStyle}
                  align={matches ? 'center' : undefined}
                >
                  {moment(release_date).format('MMMM DD, YYYY')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.textStyle}
                  align={matches ? 'center' : undefined}
                >
                  {description && truncate(description)}
                </Typography>
              </Grid>
              {/* <Grid item container spacing={2}>
                <Grid item>
                  <Button variant="contained" startIcon={<img src={itunes} />}>
                    ITUNES
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" startIcon={<img src={spotify} />}>
                    Spotify
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" startIcon={<img src={rss} />}>
                    RSS
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default DetailsCard;
