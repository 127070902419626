import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CodeCard from '../codeCard/codeCard';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  },
  tableCell: {
    backgroundColor: 'transparent'
  }
}));

const AllGenres = () => {
  const classes = useStyles();

  function createData2(name, calories) {
    return { name, calories };
  }

  const rows2 = [
    createData2(
      <>
        pagination -
        <Link href="#paginationArgs" color="inherit">
          PaginationArgs{' '}
        </Link>
      </>,
      'The arguments for limit and offset based pagination'
    )
  ];

  return (
    <div className={classes.root}>
      <Grid item container id="allGenres">
        <Grid
          item
          container
          md={7}
          sm={12}
          xs={12}
          style={{ padding: '1rem' }}
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h3" className="doc-title">
              Queries
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h3" className="doc-title">
              allGenres
            </Typography>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom>
                Fetch the metadata about the genres
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                className="operation-name"
                color="textSecondary"
              >
                Response
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" className="operation-name">
                Returns a{' '}
                <Link href="#listGenreResponse" color="inherit">
                  ListGenreResponse!
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" gutterBottom color="textSecondary">
                Arguments
              </Typography>
            </Grid>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <br />
        </Grid>
        <Grid
          item
          container
          md={5}
          sm={12}
          xs={12}
          spacing={2}
          style={{
            padding: '1rem'
          }}
        >
          <Grid item xs={12}>
            <CodeCard header="End Point">
              ``` query allGenres($pagination: PaginationArgs) &#123;/n
              allGenres(pagination: $pagination) &#123;/n results &#123;/n
              ...GenreTypeFragment /n&#125;/n&#125;/n&#125; ```
            </CodeCard>
          </Grid>

          <Grid item xs={12}>
            <CodeCard header="Response">
              ``` &#123; &quot;data&quot;:&#123;/n
              &quot;allGenres&quot;:&#123;/n &quot;results&quot;:[GenreType]/n
              &#125;/n &#125;/n &#125; ```
            </CodeCard>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AllGenres;
