import { all, call } from 'redux-saga/effects';

import { authSagas } from './auth/auth.sagas';
import { podcastSagas } from './podcast/podcast.sagas';
import { subscriptionSagas } from './subscription/subscription.sagas';
import { searchSagas } from './search/search.sagas';
import { playerSagas } from './player/player.sagas';

export default function* rootSaga() {
  yield all([
    call(podcastSagas),
    call(authSagas),
    call(subscriptionSagas),
    call(searchSagas),
    call(playerSagas)
  ]);
}
