/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  IconButton,
  Box,
  Chip
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import TabContent from './components/TabContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EpisodeDataCard from 'src/modules/views/EpisodeDataCard';
import PodcastDataCard from 'src/modules/views/DataCard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CategoriesFilter from './components/CategoriesFilter';
import PopOver from 'src/modules/components/PopOver';
import RelevancePopOver from 'src/modules/components/RelevancePopOver';
import ShowMore from 'src/modules/components/ShowMore';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Alert from '@material-ui/lab/Alert';

import container from './SearchResult.container';

const qs = require('qs');

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    }
  },
  root: {
    minHeight: 375
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  },
  breadcrumb: {
    marginTop: theme.spacing(5)
  },
  pageFilter: {
    color: '#525252'
  }
}));

function TabPanel(props) {
  const { children, type, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={type !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {type === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function SearchResult(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    onSearchPodcastStart,
    onSearchEpisodeStart,
    isLoading,
    podcastIDs,
    podcasts,
    onSetCurrentPodcast,
    history,
    location,
    onSetPlayerCurrentTrack,
    episodes,
    totalPodcastSearchResults,
    totalEpisodeSearchResults,
    onSetPlayerState,
    player: { currentTrack, miscState },
    ...rest
  } = props;

  const searchQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true
  }).query;
  const genreQuery = qs
    .parse(location.search, {
      ignoreQueryPrefix: true
    })
    .genre?.split(',');

  const [page, setPage] = useState(1);
  const [sizePodcast, setSizePodcast] = useState(10);
  const [sizeEpisode, setSizeEpisode] = useState(10);
  const [type, setType] = React.useState(0);
  const [categories, setCategories] = useState([]);
  const [sortPodcast, setSortPodcast] = useState(0);
  const [sortEpisode, setSortEpisode] = useState(0);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const podcastResultsPagesCount = Math.ceil(
    totalPodcastSearchResults / sizePodcast
  );
  const episodeResultsPagesCount = Math.ceil(
    totalEpisodeSearchResults / sizeEpisode
  );

  console.log('value of genre queries>>>', genreQuery);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      console.log('event target>>>', event.target);
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    const filterParams = genreQuery ? { categories: genreQuery } : undefined;
    const sortPod = sortPodcast
      ? {
          release_date: 'DESC'
        }
      : undefined;
    const sortEpis = sortEpisode
      ? {
          pub_date: 'DESC'
        }
      : undefined;
    onSearchPodcastStart(page, sizePodcast, searchQuery, filterParams, sortPod);
    onSearchEpisodeStart(page, sizeEpisode, searchQuery, undefined, sortEpis);
  }, [
    page,
    sizePodcast,
    sizeEpisode,
    searchQuery,
    `${genreQuery}`,
    sortPodcast,
    sortEpisode
  ]);

  const seeMoreHandlerPodcast = () => {
    setSizePodcast(sizePodcast + 10);
  };

  const seeMoreHandlerEpisode = () => {
    setSizeEpisode(sizeEpisode + 10);
  };

  const onApplyFiltersClick = () => {
    console.log('on apply filter clicked');
    if (categories.length) {
      history.push(
        `${
          location.pathname
        }?query=${searchQuery}&genre=${categories.toString()}`
      );
    } else {
      history.push(`${location.pathname}?query=${searchQuery}`);
    }
  };

  const items = podcastIDs.map((id) => podcasts[id]);

  let emptyContents;
  if (isLoading && items.length === 0) {
    emptyContents = (
      <Grid container justify="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  } else if (!isLoading && items.length === 0) {
    emptyContents = (
      <Alert variant="outlined" severity="error">
        No Result Found
      </Alert>
    );
  }
  const onSelectedCategoriesChange = (event, values) => {
    console.log('values on selected catagories changes', values);
    setCategories(values.map((value) => value.genres));
  };

  const handleChange = (event, newValue) => {
    setType(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <TabContent
        type={type}
        handleChange={handleChange}
        a11yProps={a11yProps}
      />
      <Container maxWidth="lg" component="main" className={classes.breadcrumb}>
        <Grid container direction="column">
          <Grid container direction="row">
            <Grid item md={8} xs={12}>
              <TabPanel type={type} index={0}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  className={classes.paper}
                >
                  <Grid Item xs={3}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.pageFilter}
                    >
                      Page {page} of{' '}
                      {Math.ceil(totalPodcastSearchResults / sizePodcast)}
                    </Typography>
                  </Grid>

                  <Grid Item xs={9} container justify="flex-end" spacing={3}>
                    <Grid item>
                      <RelevancePopOver popOverLabel="Filters">
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={() => setSortPodcast(1)}>
                                Newest
                              </MenuItem>
                              <MenuItem onClick={() => setSortPodcast(0)}>
                                Relevance
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </RelevancePopOver>
                    </Grid>
                    <Grid item>
                      <PopOver popOverLabel="Filters">
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          justify="center"
                          alignItems="flex-end"
                        >
                          <Grid item sm={4} md={3} xs={3}>
                            <Typography variant="h5" gutterBottom>
                              Categories
                            </Typography>
                          </Grid>
                          <Grid item sm={12} md={8} xs={8}>
                            <CategoriesFilter
                              onChange={onSelectedCategoriesChange}
                            />
                          </Grid>

                          <Grid item container justify="center" xs={12}>
                            <Button
                              variant="contained"
                              onClick={onApplyFiltersClick}
                              style={{ margin: '1rem' }}
                              color="primary"
                              // disabled={}
                            >
                              Apply Filters
                            </Button>
                          </Grid>
                        </Grid>
                      </PopOver>
                    </Grid>
                  </Grid>
                  <Grid item container>
                    {genreQuery &&
                      genreQuery.map((cat) => (
                        <Chip label={cat} style={{ marginRight: '10px' }} />
                      ))}
                  </Grid>
                </Grid>
                {items && items.length
                  ? items.map((podcast, i) => (
                      <PodcastDataCard
                        key={`podcast-${i + 1}-${podcast.id}`}
                        data={podcast}
                        setCurrentPodcast={onSetCurrentPodcast}
                        history={history}
                      />
                    ))
                  : emptyContents}
                <Grid
                  container
                  justify="center"
                  style={{ marginTop: theme.spacing(2.5) }}
                >
                  <Pagination
                    count={podcastResultsPagesCount}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                    variant="outlined"
                  />
                </Grid>
              </TabPanel>
              <TabPanel type={type} index={1}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  className={classes.paper}
                >
                  <Grid Item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.pageFilter}
                    >
                      Page {page} of{' '}
                      {Math.ceil(totalEpisodeSearchResults / sizeEpisode)}
                    </Typography>
                  </Grid>

                  <Grid Item xs={9} container justify="flex-end" spacing={3}>
                    <Grid item>
                      <RelevancePopOver popOverLabel="Filters">
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={() => setSortEpisode(1)}>
                                Newest
                              </MenuItem>
                              <MenuItem onClick={() => setSortEpisode(0)}>
                                Relevance
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </RelevancePopOver>
                    </Grid>
                  </Grid>
                  <Grid item container>
                    {genreQuery &&
                      genreQuery.map((cat) => (
                        <Chip label={cat} style={{ marginRight: '10px' }} />
                      ))}
                  </Grid>
                </Grid>
                {episodes && episodes.length
                  ? episodes.map((episode, i) => (
                      <EpisodeDataCard
                        data={episode}
                        key={episode.guid}
                        setPlayerEpisode={onSetPlayerCurrentTrack}
                        onSetPlayerState={onSetPlayerState}
                        episodePlaying={Boolean(episode.id === currentTrack.id)}
                        miscState={miscState}
                        {...rest}
                      />
                    ))
                  : emptyContents}

                <Grid
                  container
                  justify="center"
                  style={{ marginTop: theme.spacing(2.5) }}
                >
                  <Pagination
                    count={episodeResultsPagesCount}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                    variant="outlined"
                  />
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default container(SearchResult);
