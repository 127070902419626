/* eslint-disable */
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  Divider,
  Paper,
  CircularProgress
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import container from './ChangePassword.container';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    borderRadius: '20px',
    textTransform: 'capitalize'
  },
  inputText: {
    borderRadius: '20px'
  }
}));

const ChangePassword = ({ onUpdatePasswordStart, auth: { loading } }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = (values) => {
    console.log('values', values);
    onUpdatePasswordStart(
      { oldPassword: values.oldPassword, newPassword: values.newPassword },
      history
    );
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="left"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              newPassword: '',
              oldPassword: '',
              password2: ''
            }}
            validationSchema={Yup.object().shape({
              oldPassword: Yup.string()
                .required('old password is required')
                .max(32, 'must not exceed 32 character'),

              newPassword: Yup.string()
                .required('password is required')
                .max(32, 'must not exceed 32 character')
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  'Invalid format!. Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
                ),
              password2: Yup.string()
                .required('re-confirm password is required')
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  'Invalid format!. Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
                )
                .oneOf(
                  [Yup.ref('newPassword')],
                  'confirm password must be same as new password'
                )
            })}
            onSubmit={handleSubmit}
          >
            {({ values, touched, handleBlur, handleChange, errors }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.oldPassword && errors.oldPassword)}
                      fullWidth
                      helperText={touched.oldPassword && errors.oldPassword}
                      label="Old Password"
                      margin="normal"
                      name="oldPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          root: classes.inputText
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      fullWidth
                      helperText={touched.newPassword && errors.newPassword}
                      label="New password"
                      margin="normal"
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          root: classes.inputText
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.password2 && errors.password2)}
                      fullWidth
                      helperText={touched.password2 && errors.password2}
                      label="Re-enter Password"
                      margin="normal"
                      name="password2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          root: classes.inputText
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    disabled={loading}
                    size="large"
                    type="submit"
                    variant="contained"
                    className={classes.button}
                  >
                    {loading ? <CircularProgress /> : 'Submit'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default container(ChangePassword);
