/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';

import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Tabs from '@material-ui/core/Tabs';
import Avatar from '@material-ui/core/Avatar';

import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchBar from './SearchBar';
import getInitials from 'src/utils/getInitials';
import PodcastmoreLogo from 'src/assets/img/podcastmoreLogo.png';

import _ from 'lodash';

import AppBar from 'src/modules/components/AppBar';
import Toolbar, {
  styles as toolbarStyles
} from 'src/modules/components/Toolbar';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24
  },
  grow: {
    flexGrow: 1
  },
  placeholder: toolbarStyles(theme).root,
  // toolbar: {
  //   justifyContent: 'space-between'
  // },
  appBar: {
    backgroundColor: '#ffffff'
  },
  left: {
    flex: 1
  },
  leftLinkActive: {
    color: theme.palette.common.white
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3)
  },
  linkSecondary: {
    color: theme.palette.secondary.main
  },
  tab: {
    color: '#333333',
    minWidth: 150
  },
  loginButton: {
    backgroundColor: '#0083CC',
    color: '#ffffff',
    borderRadius: '6px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '40%',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      width: '55%'
    }
  },
  drawerIcon: {
    height: '40px',
    width: '40px'
  },
  drawerContainer: {
    paddingTop: '1rem',
    minWidth: '45vw',
    maxWidth: '70vw',
    backgroundColor: '#f4f6f8'
  }
}));

function AppAppBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    auth: { isAuthenticated, user },
    onSignOutStart
  } = props;

  const theme = useTheme();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const routes = [
    {
      name: 'home',
      link: '/',
      activeIndex: 0
    },
    {
      name: 'pricing',
      link: '/pricing',
      activeIndex: 1
    },
    {
      name: 'documentation',
      link: '/documentation',
      activeIndex: 2
    }
  ];

  useEffect(() => {
    routes.forEach((route) => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (value !== route.activeIndex) {
            setValue(route.activeIndex);
          }
          break;
        default:
          return false;
      }
    });
  }, [value, routes]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const drawer = (
    <>
      <SwipeableDrawer
        open={openDrawer}
        onOpen={() => setOpenDrawer(true)}
        onClose={() => setOpenDrawer(false)}
        classes={{
          paper: classes.drawerContainer
        }}
      >
        <List>
          {routes.map((route, index) => (
            <ListItem
              button
              component={Link}
              to={route.link}
              key={`list-${index + 1}`}
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <ListItemText
                primary={route.name}
                style={{ textTransform: 'capitalize' }}
              />
            </ListItem>
          ))}
          {isAuthenticated && (
            <ListItem
              button
              component={Link}
              to={'/app/account'}
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <ListItemText
                primary={'account'}
                style={{ textTransform: 'capitalize' }}
              />
            </ListItem>
          )}
        </List>
      </SwipeableDrawer>
    </>
  );

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Hidden smUp>
            {showMenu && (
              <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}
                disableRipple
                className={classes.drawerIconContainer}
              >
                {openDrawer ? (
                  <MenuOpenIcon className={classes.drawerIcon} />
                ) : (
                  <MenuIcon className={classes.drawerIcon} />
                )}
              </IconButton>
            )}

            {!showMenu && (
              <IconButton
                edge="start"
                color="secondary"
                aria-label="account of current user"
                aria-haspopup="true"
                style={{ color: '#525252', paddingLeft: '2rem' }}
                onClick={() => setShowMenu(true)}
              >
                <ArrowBackIcon style={{ fontSize: '30px' }} />
              </IconButton>
            )}
            {showMenu && (
              <IconButton
                edge="start"
                color="secondary"
                aria-label="account of current user"
                aria-haspopup="true"
                style={{ color: '#525252', paddingLeft: '2rem' }}
                onClick={() => setShowMenu(false)}
              >
                <SearchIcon style={{ fontSize: '30px' }} />
              </IconButton>
            )}
            {!showMenu && (
              <div className={classes.search}>
                <SearchBar />
              </div>
            )}

            <div className={classes.grow} />
          </Hidden>
          {matches ? (
            drawer
          ) : (
            <>
              <Link to="/" style={{ color: '#333333' }}>
                <img src={PodcastmoreLogo} alt="PodcastmoreLogo" />
              </Link>

              <div className={classes.search}>
                <SearchBar />
              </div>
              <div className={classes.grow} />

              <Tabs value={value} onChange={handleChange}>
                {routes.map((route, index) => (
                  <Tab
                    key={`tab-${index + 1}`}
                    className={classes.tab}
                    label={route.name}
                    component={Link}
                    to={route.link}
                  />
                ))}

                {isAuthenticated && (
                  <Tab
                    className={classes.tab}
                    label="Account"
                    component={Link}
                    to="/app/account"
                  />
                )}
              </Tabs>
            </>
          )}

          {isAuthenticated ? (
            <>
              {user?.firstName ? (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="primary"
                >
                  <Avatar className={classes.avatar}>
                    {getInitials(user?.firstName)}
                  </Avatar>
                </IconButton>
              ) : (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="primary"
                >
                  <AccountCircle />
                </IconButton>
              )}
              <RenderMenu
                anchorEl={anchorEl}
                handleMenuClose={handleMenuClose}
                user={user}
                onSignOutStart={onSignOutStart}
              />
            </>
          ) : (
            <Button
              className={classes.loginButton}
              variant="contained"
              color="primary"
              onClick={() => history.push('/login')}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

const RenderMenu = ({ anchorEl, handleMenuClose, user, onSignOutStart }) => {
  const isMenuOpen = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user && <MenuItem disabled>{user.username}</MenuItem>}
      <MenuItem
        onClick={() => {
          handleMenuClose();
          onSignOutStart();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
};

const RenderMobileMenu = ({
  anchorEl,
  handleMenuClose,
  user,
  onSignOutStart
}) => {
  const isMobileMenuOpen = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled>{user.username}</MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          onSignOutStart();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
};

AppAppBar.propTypes = {
  classes: PropTypes.object
};

export default AppAppBar;
