/*eslint-disable*/
export const SEARCH_ALL_PODCASTS_START = 'SEARCH_ALL_PODCASTS_START';
export const SEARCH_ALL_PODCASTS_SUCCESS = 'SEARCH_ALL_PODCASTS_SUCCESS';
export const SEARCH_ALL_PODCASTS_FAIL = 'SEARCH_ALL_PODCASTS_FAIL';

export const SEARCH_ALL_EPISODES_START = 'SEARCH_ALL_EPISODES_START';
export const SEARCH_ALL_EPISODES_SUCCESS = 'SEARCH_ALL_EPISODES_SUCCESS';
export const SEARCH_ALL_EPISODES_FAIL = 'SEARCH_ALL_EPISODES_FAIL';

export const FETCH_PODCAST_BY_ID_START = 'FETCH_PODCAST_BY_ID_START';
export const FETCH_PODCAST_BY_ID_SUCCESS = 'FETCH_PODCAST_BY_ID_SUCCESS';
export const FETCH_PODCAST_BY_ID_FAIL = 'FETCH_PODCAST_BY_ID_FAIL';

export const SET_CURRENT_PODCAST = 'SET_CURRENT_PODCAST';

export const SET_QUERY = 'SET_QUERY';
export const SET_QUERY_FOR_EPISODES = 'SET_QUERY_FOR_EPISODES';

export const RESET_QUERY_FOR_PODCAST = 'RESET_QUERY_FOR_PODCAST';
export const RESET_QUERY_FOR_EPISODE = 'RESET_QUERY_FOR_EPISODE';

export const GET_TRENDING_PODCASTS_START = 'GET_TRENDING_PODCASTS_START';
export const GET_TRENDING_PODCASTS_SUCCESS = 'GET_TRENDING_PODCASTS_SUCCESS';
export const GET_TRENDING_PODCASTS_FAIL = 'GET_TRENDING_PODCASTS_FAIL';
