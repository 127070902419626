/* eslint-disable */

import axios from 'axios';

import { END_POINT } from './constants';

export const signUp = async (input) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        mutation signUp($input: RegisterCredentialsInput!){
            signUp(input: $input){
            id
            firstName
            lastName
            username
            email
            }
        }
            `,
      variables: {
        input
      }
    }
  });
  console.log("data is this",result)
  return result;
};