/* eslint-disable */
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const CustomizedInputBase = (props) => {
  const classes = useStyles();

  const { query, onChange, onSubmit, placeholder } = props;

  return (
    <Paper component="form" className={classes.root} onSubmit={onSubmit}>
      <InputBase
        className={classes.input}
        placeholder={`${placeholder}`}
        inputProps={{ 'aria-label': 'search' }}
        value={query}
        onChange={onChange}
      />
      <IconButton
        type="submits"
        className={classes.iconButton}
        aria-label="search"
        onClick={onSubmit}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

CustomizedInputBase.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default CustomizedInputBase;
