/* eslint-disable */
import { connect } from 'react-redux';
import { signInStart } from 'src/store/auth/auth.actions';

const mapStateToProps = (state) => ({
  error: state.auth.errors
});

const mapDispatchToProps = (dispatch) => ({
  onSignInStart: (values, history) => dispatch(signInStart(values, history))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
