/* eslint-disable */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { closeAlert } from 'src/store/alert/alert.actions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomAlert = ({ severity, message, openAlert, onCloseAlert }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={openAlert}
      autoHideDuration={5000}
      onClose={onCloseAlert}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

CustomAlert.propTypes = {
  severity: PropTypes.string,
  message: PropTypes.string,
  openAlert: PropTypes.bool,
  onCloseAlert: PropTypes.func
};

const mapStateToProps = (state) => ({
  openAlert: state.alert.openAlert,
  severity: state.alert.severity,
  message: state.alert.message
});

const mapDispatchToProps = (dispatch) => ({
  onCloseAlert: () => dispatch(closeAlert())
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomAlert);
