/* eslint-disable */
import { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Paper,
  Tabs,
  Box,
  Typography,
  Tab,
  Button
} from '@material-ui/core';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import Page from 'src/components/Page';

import General from './components/General';
import Billing from './components/Billing';
import Security from './components/Security';
import container from './Account.container';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const Account = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    user,
    onUploadProfilePicStart,
    onUpdateUserStart,
    onFetchTotalAPICountStart,
    onPauseSubscriptionStart,
    onChangeSubscriptionPlanStart,
    auth: {
      remainingAPICount,
      last7DaysAPICount,
      todayAPICount,
      dateRangeAPICount,
      totalAPICount,
      loading
    }
  } = props;

  useEffect(() => {
    onFetchTotalAPICountStart();
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb" style={{ margin: '37px 0 41px' }}>
          <Link style={{ color: '#5B5B5B' }} to="/">
            Home
          </Link>
          <Link style={{ color: '#398bb8' }} aria-current="page">
            Account
          </Link>
        </Breadcrumbs>
        <Box
          mb={5}
          borderBottom={1}
          style={{ borderBottom: ' 1px solid #B6B3B3' }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="General" {...a11yProps(0)} />
            <Tab label="Billing" {...a11yProps(1)} />
            <Tab label="Security" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <Grid container spacing={3}>
          <TabPanel value={value} index={0}>
            <General
              user={user}
              onUpdateUserStart={onUpdateUserStart}
              onUploadProfilePicStart={onUploadProfilePicStart}
              history={history}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!_.isEmpty(user?.subscriptions) ? (
              <Billing
                user={user}
                onUploadProfilePicStart={onUploadProfilePicStart}
                onPauseSubscriptionStart={onPauseSubscriptionStart}
                onChangeSubscriptionPlanStart={onChangeSubscriptionPlanStart}
                remainingAPICount={remainingAPICount}
                last7DaysAPICount={last7DaysAPICount}
                todayAPICount={todayAPICount}
                dateRangeAPICount={dateRangeAPICount}
                totalAPICount={totalAPICount}
                loading={loading}
                history={history}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Link to="/pricing"> Please subscribe first.</Link>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Security />
          </TabPanel>
        </Grid>
      </Container>
    </Page>
  );
};

export default container(Account);
