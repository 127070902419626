import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Toolbar, makeStyles } from '@material-ui/core';
import PodcastmoreLogo from 'src/assets/img/podcastmoreLogo.png';

const useStyles = makeStyles({
  root: {},
  toolbar: {
    height: 64
  },
  title: {
    fontSize: 24
  }
});

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/" style={{ color: '#333333' }}>
          <img src={PodcastmoreLogo} alt="PodcastmoreLogo" />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
