/* eslint-disable object-curly-newline */
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { AppBar, Container } from '@material-ui/core';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px 24px'
  },
  threeDots: {
    flexGrow: 1
  },
  title: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  moreIcon: {
    border: '1px solid'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    paddingRight: '11px'
  },
  appbar: {
    top: '65px',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: '#ffffff'
    // minHeight: '4em',
  }
}));

const TabContent = (props) => {
  const classes = useStyles();
  const { type, handleChange } = props;

  return (
    <AppBar position="static" className={classes.appbar}>
      <Container style={{ paddingLeft: '4rem' }}>
        <Tabs
          value={type}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="PODCAST" />
          <Tab label="EPISODE" />
        </Tabs>
      </Container>
    </AppBar>
  );
};

TabContent.propTypes = {
  type: PropTypes.any,
  handleChange: PropTypes.any
};

export default TabContent;
