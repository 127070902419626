import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardActions
} from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  },
  textStyle: {
    color: '#555555'
  }
}));

const Welcome = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid item container id="welcome">
      <Grid item md={6} sm={12} xs={12} style={{ padding: '1rem' }}>
        <Typography variant="h3" className="doc-title">
          Podcast API Documentation
        </Typography>

        <Typography
          variant="subtitle2"
          component="p"
          className={classes.textStyle}
        >
          Welcome to the API reference of the podcasts api engine
        </Typography>
        <Box pt={2}>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.textStyle}
          >
            Simple & no-nonsense podcast search & directory API. Search the meta
            data of 1080051 podcasts and their episodes. It&apos;s the same API
            that powers this website.
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        style={{
          padding: '1rem'
        }}
      >
        <Box m={2}>
          <Grid container spacing={2} direction="row" justify="center">
            <Grid item xs={11}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ padding: '16px 0 16px 0', borderRadius: '10px' }}
                onClick={() => history.push('/pricing')}
              >
                Subscribe to get an API key
              </Button>
            </Grid>
            <Grid container item xs={11} spacing={2} direction="column">
              <Grid item>
                <Card style={{ borderRadius: '10px' }}>
                  <CardContent
                    style={{
                      backgroundColor: '#2B2B2B'
                    }}
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="subtitle1"
                        style={{ color: '#ffffff' }}
                      >
                        API Key
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions
                    style={{
                      backgroundColor: '#525252'
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="subtitle1"
                      style={{
                        color: 'transparent',
                        textShadow: ' 0 0 5px rgba(0,0,0,0.9)',
                        userSelect: 'none'
                      }}
                    >
                      d567b5ed-835f-1234-8bd1-1a37c529b9ab
                    </Typography>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  Please keep your API key secretly. You must pass this as a
                  header in each request for fetching podcasts and episodes.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Welcome;
