import { Box, Card, CardContent, Typography,CardActions, makeStyles } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import CodeBlock from 'src/components/codeblock'
import PropTypes from 'prop-types';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sideLink: {
    marginLeft: '1rem'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1
  },
  tableCell: {
    backgroundColor: 'transparent'
  }
}));

function CodeCard({header,children}) {

    const classes = useStyles();

    return (
        <Card style={{ borderRadius: '10px' }}>
        <CardContent
          style={{
            backgroundColor: '#E3E1E1'
          }}
        >
          <Box display="flex" flexDirection="column">
            <Typography
              color="textPrimary"
              gutterBottom
              variant="subtitle1"
              className={classes.textStyle}
            >
              {header}
            </Typography>
          </Box>
        </CardContent>
        <CardActions
          style={{
            backgroundColor: '#F3F3F3',
            overflow: 'auto'
          }}
        >
          <ReactMarkdown components={{ code: CodeBlock }}>
           {children}
          </ReactMarkdown>
        </CardActions>
      </Card>
    )

   
}
CodeCard.propTypes = {
    header: PropTypes.string.isRequired,
    children:PropTypes.isRequired
}

export default CodeCard
