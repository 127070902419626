/* eslint-disable */
import axios from 'axios';

import { END_POINT, MASTER_API_KEY } from './constants';

export const autocompletePodcast = async ({ limit, offset, search }) => {
  const searchResponse = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
        query searchPodcasts($pagination: PaginationArgs!, $search:String){
          searchPodcasts(
            pagination: $pagination,
            search:$search
          ){
            results{
              collection_id
              collection_name
              genre
            }
          }
        }
      `,
      variables: {
        pagination: {
          limit,
          offset
        },
        search
      }
    }
  });
  console.log('genre', searchResponse.data.data.searchPodcasts.results);
  return searchResponse;
};
