/* eslint-disable */
import axios from 'axios';
import _ from 'lodash';

import { END_POINT, MASTER_API_KEY } from './constants';

export const getTrendingPodcasts = async ({ limit, offset }) => {
  const response = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
      query getTrendingPodcasts($pagination: PaginationArgs!){
        getTrendingPodcasts(pagination:$pagination){
          results{
            id
            artist_name
            artwork_url_100
            collection_id
            collection_name
            description
            genre
            release_date
            episode_count
          }
          total
        }  
      }
      `,
      variables: {
        pagination: {
          limit,
          offset
        }
      }
    }
  });
  return response;
};
