import * as actionType from './search.types';

export const fetchAutocompleteSuggestionsPodcastStart = (search) => ({
  type: actionType.GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_START,
  payload: { search }
});

export const fetchAutocompleteSuggestionsPodcastSuccess = (data) => ({
  type: actionType.GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_SUCCESS,
  payload: data
});

export const fetchAutocompleteSuggestionsPodcastFail = (error) => ({
  type: actionType.GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_FAIL,
  payload: { error }
});

export const fetchPodcastCategoriesStart = () => ({
  type: actionType.GET_PODCAST_CATEGORIES_START
});

export const fetchPodcastCategoriesSuccess = (data) => ({
  type: actionType.GET_PODCAST_CATEGORIES_SUCCESS,
  payload: data
});

export const fetchPodcastCategoriesFail = (error) => ({
  type: actionType.GET_PODCAST_CATEGORIES_FAIL,
  payload: { error }
});
