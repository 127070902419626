/* eslint-disable consistent-return */
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import { IconButton, CircularProgress } from '@material-ui/core/';
import {
  PauseCircleFilled,
  PlayCircleFilled,
  Pause,
  PlayArrow
} from '@material-ui/icons/';

const useStyles = makeStyles((theme) => ({
  volIcons: {
    fontSize: '4em',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3em'
    }
  }
}));

const PlayPauseButton = ({ player, audioState, minimized }) => {
  const classes = useStyles();

  const togglePlayPause = (e) => {
    if (audioState === 'playing') {
      player.pause();
    } else if (audioState === 'paused') {
      player.play();
    }
    e.stopPropagation();
  };

  const showPlayPause = () => {
    if (audioState === 'playing') {
      // if the state will be minimized then we will return the nromal pause button
      if (minimized) {
        return (
          <Pause style={{ fontSize: '3em', opacity: '.8', color: '#ffffff' }} />
        );
      }
      return <PauseCircleFilled className={classes.volIcons} />;
    }
    if (audioState === 'paused' || audioState === 'loaded') {
      if (minimized) {
        return (
          <PlayArrow
            style={{ fontSize: '3em', opacity: '.8', color: '#ffffff' }}
          />
        );
      }
      return <PlayCircleFilled className={classes.volIcons} />;
    }
    if (audioState === 'loading') {
      return <CircularProgress style={{ color: '#ffffff' }} />;
    }
  };

  return (
    <IconButton
      size="small"
      color={minimized ? 'secondary' : 'primary'}
      aria-label="Pause"
      onClick={togglePlayPause}
      disableFocusRipple
      disableRipple
    >
      {showPlayPause()}
    </IconButton>
  );
};

PlayPauseButton.propTypes = {
  audioState: PropTypes.string,
  player: PropTypes.object,
  minimized: PropTypes.bool
};

export default PlayPauseButton;
