/* eslint-disable object-curly-newline */
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { AppBar, Container } from '@material-ui/core';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px 24px'
  },
  threeDots: {
    flexGrow: 1
  },
  title: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  moreIcon: {
    border: '1px solid'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    paddingRight: '11px'
  },
  appbar: {
    top: '65px',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: '#ffffff'
    // minHeight: '4em',
  }
}));

const TabContent = () => {
  const classes = useStyles();

  const [value, setValue] = useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppBar position="static" className={classes.appbar}>
      <Container style={{ paddingLeft: '4rem' }}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="PODCAST" />
          <Tab label="EPISODE" />
        </Tabs>
      </Container>
    </AppBar>
  );
};
export default TabContent;
