import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core/';
// import FastForwardIcon from '@material-ui/icons/FastForward';
// import Replay10Icon from '@material-ui/icons/Replay10';
import Forward30Icon from '@material-ui/icons/Forward30';

const useStyles = makeStyles((theme) => ({
  volIcons: {
    fontSize: '1.8em',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em'
    }
  }
}));

const PlayPauseButton = ({ onPlayNext }) => {
  const classes = useStyles();

  return (
    <IconButton color="primary" aria-label="Next" onClick={onPlayNext}>
      <Forward30Icon fontSize="large" className={classes.volIcons} />
      {/* <FastForwardIcon fontSize="large" className={classes.volIcons} /> */}
    </IconButton>
  );
};

PlayPauseButton.propTypes = {
  onPlayNext: PropTypes.func
};

export default PlayPauseButton;
