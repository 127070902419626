import axios from 'axios';
import { END_POINT, MASTER_API_KEY } from './constants';

export const getAuthenticatedUser = async () => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        query getAuthenticatedUser{
            getAuthenticatedUser{
            email
            username
            tenant{
              key
            }
            subscriptions{
                id
                isEnabled
            }
          }
        }
        `
    }
  });

  return result;
};

export const fetchPodcast = async ({ limit, offset, sort }) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
        query allPodcasts($pagination: PaginationArgs!, $sort: PodcastSortArgs ){
          allPodcasts(
            pagination: $pagination,
            sort:$sort
          ){
            id
            artist_name
            artwork_url_100
            collection_id
            collection_name
          }
        }
      `,
      variables: {
        pagination: {
          limit,
          offset
        },
        sort: {
          release_date: sort
        }
      }
    }
  });

  return result;
};

export const searchPodcastByCollectionId = async ({ query, sortArgs }) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-KEY': MASTER_API_KEY
    },
    data: {
      query: `
        query podcastByCollectionId($id: Int!, $sort: EpisodeSortArgs){
          podcast(id: $id){
            id
            artist_name
            artwork_url_100
            collection_id
            collection_name
            description
            release_date
          
            episodes(sort: $sort){
              id  
              guid
              title
              description
              pub_date
              duration
              link
            }
          }
        }
            `,
      variables: {
        id: Number(query),
        sort: {
          pub_date: sortArgs
        }
      }
    }
  });

  return result;
};

export const signIn = async (input) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        mutation signIn($input: LoginCredentialsInput!){
            signIn(input:$input){
            accessToken
            }
        }
            `,
      variables: {
        input
      }
    }
  });

  return result;
};

export const signUp = async (input) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        mutation signUp($input: RegisterCredentialsInput!){
            signUp(input: $input){
            id
            firstName
            lastName
            username
            email
            }
        }
      `,
      variables: {
        input
      }
    }
  });
  return result;
};

export const generateToken = async () => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        mutation createTenantKey {
          createTenantKey {
            key
          }
        }
      `
    }
  });
  console.log('generateToken>>>', result);
  return result;
};

export const fetchApiToken = async () => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        query getTenantKey {
          getTenantKey {
            key
          }
        }
      `
    }
  });
  console.log('fetchApiToken>>>', result);
  return result;
};

export const createSubscription = async () => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
        mutation createSubscription {
          createSubscription {
            id
          }
        }
      `
    }
  });
  console.log('generateToken>>>', result);
  return result;
};

export const pauseSubscription = async ({ subscriptionId, pause }) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
      mutation pauseSubscription($subscriptionId: Float!, $pause: Boolean!){
        pauseSubscription(subscriptionId: $subscriptionId, pause: $pause){
          success
        }
      }
      `,
      variables: {
        subscriptionId: Number(subscriptionId),
        pause
      }
    }
  });
  console.log('result>>', result);
  return result;
};

export const changeSubscriptionPlan = async ({
  subscriptionId,
  subscriptionPlanId
}) => {
  const result = await axios({
    url: END_POINT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: `
      mutation updateSubscription($subscriptionId: Float!, $subscriptionPlanId: Float!){
        updateSubscription(subscriptionId: $subscriptionId, subscriptionPlanId: $subscriptionPlanId){
          success
        }
      }
      `,
      variables: {
        subscriptionId: Number(subscriptionId),
        subscriptionPlanId: Number(subscriptionPlanId)
      }
    }
  });
  return result;
};
