/* eslint-disable */
import { connect } from 'react-redux';
import {
  updateUserStart,
  uploadProfilePicStart,
  fetchTotalAPICountStart
} from 'src/store/auth/auth.actions';
import {
  changeSubscriptionPlanStart,
  pauseSubscriptionStart
} from 'src/store/subscription/subscription.actions';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateUserStart: (formData, history) =>
    dispatch(updateUserStart(formData, history)),
  onUploadProfilePicStart: (file, history) =>
    dispatch(uploadProfilePicStart(file, history)),
  onFetchTotalAPICountStart: () => dispatch(fetchTotalAPICountStart()),
  onPauseSubscriptionStart: (subscriptionId, pause) =>
    dispatch(pauseSubscriptionStart(subscriptionId, pause)),
  onChangeSubscriptionPlanStart: (subscriptionId, subscriptionPlanId) =>
    dispatch(changeSubscriptionPlanStart(subscriptionId, subscriptionPlanId))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
