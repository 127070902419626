/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import _ from 'lodash';

import AppBar from 'src/modules/components/AppBar';
import Toolbar, {
  styles as toolbarStyles
} from 'src/modules/components/Toolbar';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24
  },
  grow: {
    flexGrow: 1
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between'
  },
  left: {
    flex: 1
  },
  leftLinkActive: {
    color: theme.palette.common.white
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3)
  },
  linkSecondary: {
    color: theme.palette.secondary.main
  }
}));

function AppAppBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    auth: { isAuthenticated, user },
    onSignOutStart
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h6"
            underline="none"
            color="inherit"
            className={classes.title}
          >
            <Link to="/" style={{ color: 'inherit' }}>
              Podcast
            </Link>
          </Typography>
          <div className={classes.grow} />

          <Tabs value={value} onChange={handleChange} centered>
            <Tab
              className={classes.tab}
              label="Pricing"
              component={Link}
              to="/pricing"
            />
            <Tab
              className={classes.tab}
              label="Documentation"
              component={Link}
              to="/documentation"
            />
            {isAuthenticated && (
              <Tab
                className={classes.tab}
                label="Account"
                component={Link}
                to="/app/account"
              />
            )}
          </Tabs>

          {isAuthenticated ? (
            <>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <RenderMenu
                anchorEl={anchorEl}
                handleMenuClose={handleMenuClose}
                user={user}
                onSignOutStart={onSignOutStart}
              />
            </>
          ) : (
            <Button color="inherit" onClick={() => history.push('/login')}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <div className={classes.placeholder} />
    </div>
  );
}

const RenderMenu = ({ anchorEl, handleMenuClose, user, onSignOutStart }) => {
  const isMenuOpen = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user && <MenuItem disabled>{user.username}</MenuItem>}
      <MenuItem
        onClick={() => {
          handleMenuClose();
          onSignOutStart();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
};

const RenderMobileMenu = ({
  anchorEl,
  handleMenuClose,
  user,
  onSignOutStart
}) => {
  const isMobileMenuOpen = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled>{user.username}</MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          onSignOutStart();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
};

AppAppBar.propTypes = {
  classes: PropTypes.object
};

export default AppAppBar;
