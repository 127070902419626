/* eslint-disable */
import * as actionTypes from './auth.types';

const INITIAL_STATE = {
  isAuthenticated: null,
  user: null,
  loading: false,
  isFetching: false,
  accessToken: '',
  errors: {},
  loggedIn: false,
  totalAPICount: 0,
  remainingAPICount: 0,
  todayAPICount: 0,
  last7DaysAPICount: 0,
  dateRangeAPICount: []
};

const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.REQUEST_PASSWORD_RESET_START:
    case actionTypes.UPDATE_PASSWORD_START:
    case actionTypes.RESET_PASSWORD_START:
    case actionTypes.VERIFY_TOKEN_START:
    case actionTypes.LOAD_USER_START:
    case actionTypes.SIGNUP_START:
    case actionTypes.SIGNIN_START:
    case actionTypes.UPDATE_USER_START:
    case actionTypes.FETCH_TOTAL_API_COUNT_START:
    case actionTypes.FETCH_API_COUNT_BY_DATE_RANGE_START:
    case actionTypes.UPLOAD_PROFILE_PIC_START:
      return {
        ...state,
        loading: true
      };

    case actionTypes.LOAD_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      };

    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload
      };

    case actionTypes.SIGNIN_SUCCESS:
      localStorage.setItem('token', payload.accessToken);
      const remainingMilliseconds = 60 * 60 * 1000;
      const expiryDate = new Date(new Date().getTime() + remainingMilliseconds);
      localStorage.setItem('expiryDate', expiryDate.toISOString());

      return {
        ...state,
        isAuthenticated: true,
        accessToken: payload.accessToken,
        expiresIn: remainingMilliseconds
      };

    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case actionTypes.FETCH_API_COUNT_BY_DATE_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dateRangeAPICount: payload
      };

    case actionTypes.SIGNOUT_SUCCESS:
      localStorage.removeItem('token');
      localStorage.removeItem('expiryDate');
      return INITIAL_STATE;

    case actionTypes.FETCH_TOTAL_API_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        totalAPICount: payload.totalAPIUsage,
        remainingAPICount: payload.remainingAPIUsage,
        todayAPICount: payload.todaysUsage,
        last7DaysAPICount: payload.last7DaysUsage
      };

    case actionTypes.REQUEST_PASSWORD_RESET_SUCCESS:
    case actionTypes.UPLOAD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case actionTypes.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case actionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case actionTypes.REQUEST_PASSWORD_RESET_FAIL:
    case actionTypes.UPDATE_PASSWORD_FAIL:
    case actionTypes.RESET_PASSWORD_FAIL:
    case actionTypes.VERIFY_TOKEN_FAIL:
    case actionTypes.SIGNIN_FAIL:
    case actionTypes.LOAD_USER_FAIL:
    case actionTypes.SIGNUP_FAIL:
    case actionTypes.UPDATE_USER_FAIL:
    case actionTypes.FETCH_TOTAL_API_COUNT_FAIL:
    case actionTypes.FETCH_API_COUNT_BY_DATE_RANGE_FAIL:
      return {
        ...state,
        loading: false,
        errors: payload
      };

    default:
      return state;
  }
};

export default authReducer;
