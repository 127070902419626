/* eslint-disable */
import { connect } from 'react-redux';
import { setPlayerCurrentTrack } from 'src/store/player/player.actions';

import {
  searchPodcastStart,
  setCurrentPodcast,
  setQuery,
  resetQueryForPodcast,
  searchEpisodeStart
} from 'src/store/podcast/podcast.actions';

const mapStateToProps = (state) => ({
  items: state.podcast.items,
  isLoading: state.podcast.loading,
  podcastIDs: state.podcast.podcastIDs,
  podcasts: state.podcast.podcasts,
  searchQuery: state.podcast.query,
  totalPodcastSearchResults: state.podcast.totalPodcastSearchResults,
  totalEpisodeSearchResults: state.podcast.totalEpisodeSearchResults,
  episodes: state.podcast.episodes
});

const mapDispatchToProps = (dispatch) => ({
  onSearchPodcastStart: (page, size, search, filter, sort) =>
    dispatch(searchPodcastStart(page, size, search, filter, sort)),
  onSearchEpisodeStart: (page, size, search, filter, sort) =>
    dispatch(searchEpisodeStart(page, size, search, filter, sort)),
  onSetCurrentPodcast: (page, size) => dispatch(setCurrentPodcast(page, size)),
  onSetQuery: (data) => dispatch(setQuery(data)),
  onResetQuery: () => dispatch(resetQueryForPodcast()),
  onSetPlayerCurrentTrack: (episode) => dispatch(setPlayerCurrentTrack(episode))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
