/* eslint-disable no-case-declarations */
/*eslint-disable*/
import getUniqueItemsFromList from 'src/utils/getUniqueItemsFromList';
import * as actionTypes from './search.types';

const INITIAL_STATE = {
  query: '',
  episodequery: '',
  loading: false,
  autoCompleteSuggestions: [],
  podcastCategories: [],
  error: {}
};

const searchReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_START:
    case actionTypes.GET_PODCAST_CATEGORIES_START:
      return {
        ...state,
        loading: true
      };

    case actionTypes.GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_SUCCESS:
      return {
        ...state,
        loading: false,
        autoCompleteSuggestions: payload
      };

    case actionTypes.GET_PODCAST_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        podcastCategories: payload
      };

    case actionTypes.GET_AUTOCOMPLETE_SUGGESTIONS_PODCAST_FAIL:
    case actionTypes.GET_PODCAST_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default searchReducer;
