import * as actionTypes from './subscription.types';

const INITIAL_STATE = {
  subscription: null,
  apiToken: null,
  errors: {},
  loading: false,
  isCreating: false,
  isFetching: false
};

const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.FETCH_TOKEN_START:
      return {
        ...state,
        apiToken: null,
        isFetching: true
      };

    case actionTypes.GEN_TOKEN_START:
      return {
        ...state,
        isCreating: true
      };

    case actionTypes.CHANGE_SUBSCRIPTION_PLAN_START:
    case actionTypes.CREATE_SUBSCRIPTION_START:
    case actionTypes.PAUSE_SUBSCRIPTION_START:
      return {
        ...state,
        loading: true
      };

    case actionTypes.GEN_TOKEN_SUCCESS:
    case actionTypes.FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        isCreating: false,
        isFetching: false,
        apiToken: payload
      };

    case actionTypes.CREATE_SUBSCRIPTION_SUCCESS:
    case actionTypes.PAUSE_SUBSCRIPTION_SUCCESS:
    case actionTypes.CHANGE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case actionTypes.GEN_TOKEN_FAIL:
    case actionTypes.FETCH_TOKEN_FAIL:
    case actionTypes.CREATE_SUBSCRIPTION_FAIL:
    case actionTypes.PAUSE_SUBSCRIPTION_FAIL:
    case actionTypes.CHANGE_SUBSCRIPTION_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        isCreating: false,
        isFetching: false,
        errors: payload
      };

    default:
      return state;
  }
};

export default authReducer;
